import { Component, Renderer2, Inject, Input } from '@angular/core'
import { DOCUMENT } from '@angular/common'
import { ImageConstant } from '../../common/utilities/constants'
import { CookieBackendService } from '../../services/cookie-backend.service'
@Component({
  selector: 'app-ticker-tape',
  templateUrl: './ticker-tape.component.html',
  styleUrls: ['./ticker-tape.component.css']
})
export class TickerTapeComponent {
  @Input() tickerTypeData
  @Input() tickerTapeGaId
  isTickerVisible: boolean = true
  assetPath = ImageConstant + '/IELTS_Website_Rebrand'
  isSmartbanner

  constructor (private readonly renderer: Renderer2, private cookieServiceService: CookieBackendService, @Inject(DOCUMENT) private readonly document: any) {
    this.renderer.addClass(this.document.body, 'ttmrgnTopcls')
  }

  closeTickerTape () {
    this.renderer.removeClass(this.document.body, 'ttmrgnTopcls')
    this.isTickerVisible = false
    this.cookieServiceService.set('ticker_cookie', 'Yes')
  }
}
