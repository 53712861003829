import { RelatedPromotionalComponents } from './related-promotional-components'

export class ParagraphInfo {
  public title: string
  public content: string
  public titleId: string
  public paraImage: any
  public video: any
  constructor (
    title: string,
    titleId: string,
    content: string,
    paraImage: any,
    video: any
  ) {
    this.title = title || ''
    this.titleId = titleId
    this.content = content
    this.paraImage = paraImage
    this.video = video
  }
}
export class PrepParagraphInfo {
  public sysId: string
  public title: string
  public image: Object
  public content: string
  public titleId: string
  public videoDetails: Object
  public dynamicComponents: RelatedPromotionalComponents[]
  constructor (
    sysId: string,
    title: string,
    titleId: string,
    image: Object,
    content: string,
    videoDetails: Object = {},
    dynamicComponents: RelatedPromotionalComponents[] = []
  ) {
    this.sysId = sysId
    this.title = title
    this.titleId = titleId
    this.image = image
    this.content = content
    this.videoDetails = videoDetails
    this.dynamicComponents = dynamicComponents
  }
}
