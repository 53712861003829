import { NgModule, APP_INITIALIZER } from '@angular/core'
import { Routes, RouterModule, UrlSegment } from '@angular/router'
import { RoutingInitService } from './services/routing-init.service'
import { NotFoundComponent } from './components/not-found/not-found.component'
import { UserLocationGuard } from './guards/user-location-guard'
import { indiaDomain } from './common/utilities/constants'

let availableCountry
let websiteInfo = []
let language = false
let currentUrl = ''
let hostUrl = ''
export function getParameterByName (name, url) {
  name = name.replace(/[\[\]]/g, '\\$&')
  const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)')
  const results = regex.exec(url)
  if (results === null) return null
  if (results[2].length === 0) return ''
  return decodeURIComponent(results[2].replace(/\+/g, ' '))
}

const routers: Routes = [
  { path: '', canActivate: [UserLocationGuard], loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule) },
  { path: 'share/:id/:sharing-to', pathMatch: 'full', canActivate: [UserLocationGuard], loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule), data: { title: 'home' } },
  { path: 'social-sharing/:testType/:imagefile/:sharing-to', pathMatch: 'full', canActivate: [UserLocationGuard], loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule), data: { title: 'home' } },
  { path: 'referfriend', pathMatch: 'full', canActivate: [UserLocationGuard], loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule), data: { title: 'home' } },
  {
    path: 'results/check-your-result', loadChildren: () => import('./modules/results/results.module').then(m => m.ResultsModule), data: { isResultPage: true }
  },
  {
    path: 'test-centres/:testCenterId/offline-payment-instructions', loadChildren: () => import('./modules/bxsearch-instruction/bxsearch-instruction.module').then(m => m.BxsearchInstructionModule), data: { isBxSearchPage: true }
  },
  {
    path: 'results/table', loadChildren: () => import('./modules/results-listing/results-listing.module').then(m => m.ResultsListingModule), data: { isResultPage: true }
  },
  {
    path: 'results/score', loadChildren: () => import('./modules/results-detail/results-detail.module').then(m => m.ResultsDetailModule), data: { isResultPage: true }
  },
  { path: 'ielts-prep-hub', loadChildren: () => import('./prepare/modules/prep-hub-landing/prep-hub-landing.module').then(m => m.PrepHubLandingModule), data: { isNewPrepPage: true } },
  {
    path: 'diagnostic-tool-preparation', loadChildren: () => import('./modules/diagnostic/diagnostic.module').then(m => m.DiagnosticModule), data: { diagnostic_tool: true }
  },
  {
    path: 'diagnostic-tool-result', loadChildren: () => import('./modules/diagnostic-results/diagnostic-results.module').then(m => m.DiagnosticResultsModule), data: { diagnostic_results: true }
  },
  {
    matcher: searchResultPageMatcher, loadChildren: () => import('./modules/search-results/search-results.module').then(m => m.SearchResultModule), data: { isSearchPage: true }
  },
  // {
  //   path: 'prepare/ielts-test-preparation-video-material', loadChildren: () => import('./prep/pages/prep-detail-video/prep-detail-video.module').then(m => m.PrepDetailVideoModule), data: { isPrepPage: true }
  // },
  // {
  //  path: 'prepare/ielts-test-preparation-practice-tests-material', loadChildren: () => import('./prep/pages/prep-detail-practice-test/prep-detail-practice-test.module').then(m => m.PrepDetailPracticeTestModule), data: { isPrepPage: true }
  // },
  // {
  //   path: 'prepare/ielts-test-preparation-article-material', loadChildren: () => import('./prep/pages/prep-detail-article/prep-detail-article.module').then(m => m.PrepDetailArticleModule), data: { isPrepPage: true }
  // },
  { matcher: homePageMatcher, loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule) },
  {
    matcher: mlpPageMatcher, loadChildren: () => import('./modules/mlp/mlp.module').then(m => m.MlpModule), data: { marketingpage: true }
  },
  { matcher: testcenterlandingMatcher, loadChildren: () => import('./modules/test-center-landing/test-center-landing.module').then(m => m.TestCenterLandingModule) },
  {
    matcher: testCenterDetailMatcher, loadChildren: () => import('./modules/test-center-detail/test-center-detail.module').then(m => m.TestCenterDetailModule), data: { sessionPage: true }
  },
  {
    matcher: cityLandingMatcher, loadChildren: () => import('./modules/city-landing/city-landing.module').then(m => m.CityLandingModule), data: { sessionPage: true }
  },
  { matcher: articleLandingMatcher, loadChildren: () => import('./modules/article-landing/article-landing.module').then(m => m.ArticleLandingModule) },
  { matcher: articlePageMatcherWithAttached, loadChildren: () => import('./modules/article/article.module').then(m => m.ArticleModule) },
  { matcher: articlePageMatcherWithoutAttached, loadChildren: () => import('./modules/article/article.module').then(m => m.ArticleModule) },
  { matcher: articlePageMatcherWithNews, loadChildren: () => import('./modules/article/article.module').then(m => m.ArticleModule) },
  { matcher: eventMatcher, loadChildren: () => import('./modules/event-landing/event-landing.module').then(m => m.EventLandingModule) },
  // {
  //   matcher: prepSearchResultPageMatcher, loadChildren: () => import('./prep/pages/prep-hub/prep-hub.module').then(m => m.PreparationModule), data: { isPrepPage: true }
  // },
  {
    matcher: prepLibraryPageMatcher, loadChildren: () => import('./prepare/modules/prep-library/prep-library.module').then(m => m.PrepLibraryModule), data: { isNewPrepPage: true }
  },
  { matcher: roListingMatcher, loadChildren: () => import('./modules/ro-landing/ro-landing.module').then(m => m.RoLandingModule) },
  { matcher: hubTopicPageMatcher, loadChildren: () => import('./modules/hub-topic/hub-topic-routing.module').then(m => m.HubTopicRoutingModule) },
  { path: ':hub_param', loadChildren: () => import('./modules/hub-topic/hub-topic-routing.module').then(m => m.HubTopicRoutingModule) },
  { path: '**', component: NotFoundComponent }
]

export function isLanguageCode (data) {
  const segments = []
  data.forEach(datas => {
    segments.push(datas?.path)
  })
  if (segments.length > 0) {
    let countryDetails = websiteInfo.length > 0 ? websiteInfo['global'].LanguageList : []
    if (availableCountry.indexOf(segments[0]) !== -1) {
      countryDetails = (websiteInfo.length === 0 || websiteInfo.length > 0) ? websiteInfo[segments[0]].LanguageList : []
    }
    const segmentsLength = segments.length
    const countryCodeArray = countryDetails.map((code) => { return code.urlCode })
    const codeIndex = countryCodeArray.indexOf(segments[segmentsLength - 1])
    if (codeIndex !== -1 && (countryDetails[codeIndex].enableLanguage === 'Yes' || getParameterByName('preview', currentUrl) === 'true')) {
      return true
    } else {
      return false
    }
  } else {
    return false
  }
}
export function homePageMatcher (segments) {
  language = isLanguageCode(segments)
  if ((segments.length === 1 || (segments.length === 2 && language)) && availableCountry.indexOf(segments[0].path) !== -1) {
    return {
      consumed: segments,
      posParams: {
        country_name: new UrlSegment(segments[0].path, {})
      }
    }
  }
  return null
}

export function articlePageMatcherWithAttached (segments) {
  language = isLanguageCode(segments)
  if (segments.length > 0) {
    const articleParamsArray = segments.map((path) => { return path.path })
    let countryName = new UrlSegment(null, {})
    if (availableCountry.indexOf(segments[0].path) !== -1) {
      articleParamsArray.shift()
      countryName = new UrlSegment(segments[0].path, {})
    }

    if (language) {
      articleParamsArray.splice(articleParamsArray.length - 1, 1)
    }
    const articleParam = articleParamsArray.join('/')

    if (articleParamsArray.length > 0) {
      const lastUrl = articleParamsArray[articleParamsArray.length - 1]
      if (lastUrl.indexOf('content') === 0 || (lastUrl.indexOf('article') === 0 && lastUrl.indexOf(',') < 0 && lastUrl != 'article') || (segments.length === 2 && segments[0].path === 'blog')) {
        return {
          consumed: segments,
          posParams: {
            country_name: countryName,
            article_param: new UrlSegment(articleParam, {})
          }
        }
      }
    }
  }
  return null
}
export function articlePageMatcherWithNews (segments) {
  language = isLanguageCode(segments)
  if (segments.length > 0) {
    const articleParamsArray = segments.map((path) => { return path.path })
    let countryName = new UrlSegment(null, {})
    if (availableCountry.indexOf(segments[0].path) !== -1) {
      articleParamsArray.shift()
      countryName = new UrlSegment(segments[0].path, {})
    }

    if (language) {
      articleParamsArray.splice(articleParamsArray.length - 1, 1)
    }
    const articleParam = articleParamsArray.join('/')

    if (articleParamsArray.length > 1) {
      const firstUrl = articleParamsArray[0]
      if (firstUrl === 'news') {
        return {
          consumed: segments,
          posParams: {
            country_name: countryName,
            article_param: new UrlSegment(articleParam, {})
          }
        }
      }
    }
  }
  return null
}
export function articleLandingMatcher (segments) {
  language = isLanguageCode(segments)
  if (((segments.length === 2 || (segments.length === 3 && language)) && availableCountry.indexOf(segments[0].path) === -1 && segments[0].path === 'about' && segments[1].path === 'news-and-articles') || (segments.length === 1 && segments[0].path === 'blog')) {
    const articleParamsArray = segments.map((path) => { return path.path })
    if (language) {
      articleParamsArray.splice(articleParamsArray.length - 1, 1)
    }
    const articleLandingParam = articleParamsArray.join('/')
    return {
      consumed: segments,
      posParams: {
        article_landing_param: new UrlSegment(articleLandingParam, {})
      }
    }
  } else if ((segments.length === 3 || (segments.length === 4 && language)) && availableCountry.indexOf(segments[0].path) !== -1 && segments[1].path === 'about' && segments[2].path === 'news-and-articles') {
    const articleParamsArray = segments.map((path) => { return path.path })
    articleParamsArray.shift()
    if (language) {
      articleParamsArray.splice(articleParamsArray.length - 1, 1)
    }
    const articleLandingParam = articleParamsArray.join('/')

    return {
      consumed: segments,
      posParams: {
        country_name: new UrlSegment(segments[0].path, {}),
        article_landing_param: new UrlSegment(articleLandingParam, {})
      }
    }
  }
  return null
}
export function cityLandingMatcher (segments) {
  language = isLanguageCode(segments)
  if ((segments.length === 2 || (segments.length === 3 && language)) && availableCountry.indexOf(segments[0].path) === -1 && segments[0].path === 'test-dates') {
    const articleParamsArray = segments.map((path) => { return path.path })
    if (language) {
      articleParamsArray.splice(articleParamsArray.length - 1, 1)
    }
    const cityLandingParam = articleParamsArray.join('/')
    return {
      consumed: segments,
      posParams: {
        city_landing_param: new UrlSegment(cityLandingParam, {})
      }
    }
  } else if ((segments.length === 3 || (segments.length === 4 && language)) && availableCountry.indexOf(segments[0].path) !== -1 && segments[1].path === 'test-dates') {
    const articleParamsArray = segments.map((path) => { return path.path })
    articleParamsArray.shift()
    if (language) {
      articleParamsArray.splice(articleParamsArray.length - 1, 1)
    }
    const cityLandingParam = articleParamsArray.join('/')

    return {
      consumed: segments,
      posParams: {
        country_name: new UrlSegment(segments[0].path, {}),
        city_landing_param: new UrlSegment(cityLandingParam, {})
      }
    }
  }
  return null
}
export function articlePageMatcherWithoutAttached (segments) {
  language = isLanguageCode(segments)
  if (segments.length > 0) {
    const articleParamsArray = segments.map((path) => { return path.path })
    let countryName = new UrlSegment(null, {})
    if (availableCountry.indexOf(segments[0].path) !== -1) {
      articleParamsArray.shift()
      countryName = new UrlSegment(segments[0].path, {})
    }

    if (language) {
      articleParamsArray.splice(articleParamsArray.length - 1, 1)
    }
    const articleParam = articleParamsArray.join('/')

    const hubpage = ['results', 'about', 'prepare', 'news', 'contact-us', 'information']
    if (articleParamsArray.length === 1) {
      const lastUrl = articleParamsArray[0]
      if (!hubpage?.includes(lastUrl)) {
        return {
          consumed: segments,
          posParams: {
            country_name: countryName,
            article_param: new UrlSegment(articleParam, {})
          }
        }
      }
    }
  }
  return null
}
export function eventMatcher (segments) {
  language = isLanguageCode(segments)
  if ((segments.length === 2 || (segments.length === 3 && language)) && availableCountry.indexOf(segments[0].path) === -1 && segments[0].path === 'prepare' && segments[1].path === 'events') {
    const articleParamsArray = segments.map((path) => { return path.path })
    if (language) {
      articleParamsArray.splice(articleParamsArray.length - 1, 1)
    }
    const eventParam = articleParamsArray.join('/')
    return {
      consumed: segments,
      posParams: {
        event_param: new UrlSegment(eventParam, {})
      }
    }
  } else if ((segments.length === 3 || (segments.length === 4 && language)) && availableCountry.indexOf(segments[0].path) !== -1 && segments[1].path === 'prepare' && segments[2].path === 'events') {
    const articleParamsArray = segments.map((path) => { return path.path })
    articleParamsArray.shift()
    if (language) {
      articleParamsArray.splice(articleParamsArray.length - 1, 1)
    }
    const eventParam = articleParamsArray.join('/')
    return {
      consumed: segments,
      posParams: {
        country_name: new UrlSegment(segments[0].path, {}),
        event_param: new UrlSegment(eventParam, {})
      }
    }
  }
  return null
}
export function roListingMatcher (segments) {
  language = isLanguageCode(segments)
  if ((segments.length === 3 || (segments.length === 4 && language)) && availableCountry.indexOf(segments[0].path) === -1 && (segments[0].path === 'about' || segments[0].path === 'ielts') && segments[1].path === 'who-accepts-ielts' && segments[2].path === 'organisations-that-accept-ielts') {
    const roListingParamsArray = segments.map((path) => { return path.path })
    if (language) {
      roListingParamsArray.splice(roListingParamsArray.length - 1, 1)
    }
    const roListingParam = roListingParamsArray.join('/')
    return {
      consumed: segments,
      posParams: {
        ro_listing_param: new UrlSegment(roListingParam, {})
      }
    }
  } else if ((segments.length === 4 || (segments.length === 5 && language)) && availableCountry.indexOf(segments[0].path) !== -1 && segments[1].path === 'about' && segments[2].path === 'who-accepts-ielts' && segments[3].path === 'organisations-that-accept-ielts') {
    const roListingParamsArray = segments.map((path) => { return path.path })
    roListingParamsArray.shift()
    if (language) {
      roListingParamsArray.splice(roListingParamsArray.length - 1, 1)
    }
    const roListingParam = roListingParamsArray.join('/')

    return {
      consumed: segments,
      posParams: {
        country_name: new UrlSegment(segments[0].path, {}),
        ro_listing_param: new UrlSegment(roListingParam, {})
      }
    }
  }
  return null
}
export function mlpPageMatcher (segments) {
  language = isLanguageCode(segments)
  if ((segments.length === 2 || (segments.length === 3 && language)) && availableCountry.indexOf(segments[0].path) === -1 && segments[0].path === 'lp') {
    const mlpParamArray = segments.map((path) => { return path.path })
    if (language) {
      mlpParamArray.splice(mlpParamArray.length - 1, 1)
    }
    const mlpParam = mlpParamArray.join('/')
    return {
      consumed: segments,
      posParams: {
        mlp_param: new UrlSegment(mlpParam, {})
      }
    }
  } else if ((segments.length === 3 || (segments.length === 4 && language)) && availableCountry.indexOf(segments[0].path) !== -1 && segments[1].path === 'lp') {
    const mlpParamArray = segments.map((path) => { return path.path })
    mlpParamArray.shift()
    if (language) {
      mlpParamArray.splice(mlpParamArray.length - 1, 1)
    }
    const mlpParam = mlpParamArray.join('/')

    return {
      consumed: segments,
      posParams: {
        country_name: new UrlSegment(segments[0].path, {}),
        mlp_param: new UrlSegment(mlpParam, {})
      }
    }
  }
  return null
}

export function testcenterlandingMatcher (segments) {
  language = isLanguageCode(segments)
  if ((segments.length === 1 || (segments.length === 2 && language)) && availableCountry.indexOf(segments[0].path) === -1 && segments[0].path === 'test-dates') {
    const testcenterlandingParamsArray = segments.map((path) => { return path.path })
    if (language) {
      testcenterlandingParamsArray.splice(testcenterlandingParamsArray.length - 1, 1)
    }
    const testCenterParam = testcenterlandingParamsArray.join('/')
    return {
      consumed: segments,
      posParams: {
        test_center_param: new UrlSegment(testCenterParam, {})
      }
    }
  } else if ((segments.length === 2 || (segments.length === 3 && language)) && availableCountry.indexOf(segments[0].path) !== -1 && segments[1].path === 'test-dates') {
    const testcenterlandingParamsArray = segments.map((path) => { return path.path })
    testcenterlandingParamsArray.shift()
    if (language) {
      testcenterlandingParamsArray.splice(testcenterlandingParamsArray.length - 1, 1)
    }
    const testCenterParam = testcenterlandingParamsArray.join('/')
    return {
      consumed: segments,
      posParams: {
        country_name: new UrlSegment(segments[0].path, {}),
        test_center_param: new UrlSegment(testCenterParam, {})

      }
    }
  }
  return null
}
export function testCenterDetailMatcher (segments) {
  language = isLanguageCode(segments)
  if ((segments.length === 2 || (segments.length === 3 && language)) && availableCountry.indexOf(segments[0].path) === -1 && segments[0].path === 'test-centre') {
    const tcdParamArray = segments.map((path) => { return path.path })
    if (language) {
      tcdParamArray.splice(tcdParamArray.length - 1, 1)
    }
    const testCenterParam = tcdParamArray.join('/')
    return {
      consumed: segments,
      posParams: {
        test_center_param: new UrlSegment(testCenterParam, {})
      }
    }
  } else if ((segments.length === 3 || (segments.length === 4 && language)) && availableCountry.indexOf(segments[0].path) !== -1 && segments[1].path === 'test-centre') {
    const tcdParamArray = segments.map((path) => { return path.path })
    tcdParamArray.shift()
    if (language) {
      tcdParamArray.splice(tcdParamArray.length - 1, 1)
    }
    const testCenterParam = tcdParamArray.join('/')

    return {
      consumed: segments,
      posParams: {
        country_name: new UrlSegment(segments[0].path, {}),
        test_center_param: new UrlSegment(testCenterParam, {})
      }
    }
  }
  return null
}
export function hubTopicPageMatcher (segments) {
  language = isLanguageCode(segments)
  if ((segments.length === 2 && availableCountry.indexOf(segments[0].path) !== -1) || (segments.length === 3 && availableCountry.indexOf(segments[0].path) !== -1 && language)) {
    return {
      consumed: segments,
      posParams: {
        country_name: new UrlSegment(segments[0].path, {}),
        hub_param: new UrlSegment(segments[1].path, {})
      }
    }
  } else if (segments.length === 2 && availableCountry.indexOf(segments[0].path) === -1 && language) {
    return {
      consumed: segments,
      posParams: {
        hub_param: new UrlSegment(segments[0].path, {})
      }
    }
  } else if ((segments.length >= 2 && segments.length <= 4) && ((segments.length === 2 && availableCountry.indexOf(segments[0].path) === -1 && segments[0].path === 'page') ||
    (segments.length === 3 && availableCountry.indexOf(segments[0].path) !== -1 && segments[1].path === 'page') ||
    (segments.length === 4 && availableCountry.indexOf(segments[0].path) !== -1 && segments[1].path === 'page' && language))) {
      const topicMlpParamsArray = segments.map((path) => { return path.path })
      let countryName = new UrlSegment(null, {})
      if (availableCountry.indexOf(segments[0].path) !== -1) {
        topicMlpParamsArray.shift()
        countryName = new UrlSegment(segments[0].path, {})
      }
      if (language) {
        topicMlpParamsArray.splice(topicMlpParamsArray.length - 1, 1)
      }
      return {
        consumed: segments,
        posParams: {
          country_name: countryName,
          topic_mlp_param: new UrlSegment(topicMlpParamsArray.join('/'), {})
        }
      }
  } else if ((segments.length >= 2 && segments.length <= 5) ||
    ((segments.length === 2 || (segments.length === 3 && language)) && availableCountry.indexOf(segments[0].path) === -1 && segments[0].path === 'forms') ||
    ((segments.length === 3 || (segments.length === 4 && language)) && availableCountry.indexOf(segments[0].path) !== -1 && segments[1].path === 'forms')) {
    const topicParamsArray = segments.map((path) => { return path.path })
    let countryName = new UrlSegment(null, {})
    if (availableCountry.indexOf(segments[0].path) !== -1) {
      topicParamsArray.shift()
      countryName = new UrlSegment(segments[0].path, {})
    }
    if (language) {
      topicParamsArray.splice(topicParamsArray.length - 1, 1)
    }
    return {
      consumed: segments,
      posParams: {
        country_name: countryName,
        topic_param: new UrlSegment(topicParamsArray.join('/'), {})
      }
    }
  }
  return null
}
export function searchResultPageMatcher (segments) {
  language = isLanguageCode(segments)
  let countryDetails = []
  if (segments.length > 0) {
    countryDetails = (Object.keys(websiteInfo).length > 0) ? websiteInfo['global'] : []
    if (availableCountry.indexOf(segments[0].path) != -1) {
      countryDetails = ((Object.keys(websiteInfo).length > 0) || (Object.keys(websiteInfo).length === 0)) ? websiteInfo[segments[0].path] : []
    }
  }
  if (countryDetails['searchEnabled'] == 'Yes') {
    if (segments.length === 1 && availableCountry.indexOf(segments[0].path) == -1 && segments[0].path == 'search-results') {
      return {
        consumed: segments,
        posParams: {
          country_name: countryDetails['website']
        }
      }
    } else if (segments.length === 2 && availableCountry.indexOf(segments[0].path) != -1 && segments[1].path == 'search-results') {
      return {
        consumed: segments,
        posParams: {
          country_name: new UrlSegment(segments[0].path, {})
        }
      }
    }
  }
  return null
}
export function prepSearchResultPageMatcher (segments) {
  if (hostUrl?.replace(/^www\./, '') !== indiaDomain) {
    if ((segments.length === 1) && availableCountry.indexOf(segments[0].path) === -1 && segments[0].path === 'prepare') {
      const PrepOnboardingParamsArray = segments.map((path) => { return path.path })
      if (language) {
        PrepOnboardingParamsArray.splice(PrepOnboardingParamsArray.length - 1, 1)
      }
      const prepOnboardingParam = PrepOnboardingParamsArray.join('/')
      return {
        consumed: segments,
        posParams: { prepOnboardingParam }
      }
    } else if ((segments.length >= 2) && availableCountry.indexOf(segments[0].path) === -1 && segments[0].path === 'prepare') {
      const PrepSearchParamsArray = segments.map((path) => { return path.path })
      if (language) {
        PrepSearchParamsArray.splice(PrepSearchParamsArray.length - 1, 1)
      }
      const prepSearchResultParam = PrepSearchParamsArray.join('/')
      return {
        consumed: segments,
        posParams: { prepSearchResultParam }
      }
    }
  }
  return null
}
export function prepLibraryPageMatcher (segments) {
  if (!hostUrl.includes(indiaDomain)) {
    if ((segments.length === 1) && availableCountry.indexOf(segments[0].path) === -1 && segments[0].path === 'prepare') {
      const PrepParamsArray = segments.map((path: any) => { return path.path })
      if (language) {
        PrepParamsArray.splice(PrepParamsArray.length - 1, 1)
      }
      const prepParams = PrepParamsArray.join('/')
      return {
        consumed: segments,
        posParams: { prepParams }
      }
    } else if ((segments.length >= 2) && availableCountry.indexOf(segments[0].path) === -1 && segments[0].path === 'prepare') {
      const PrepParamsArray = segments.map((path) => { return path.path })
      if (language) {
        PrepParamsArray.splice(PrepParamsArray.length - 1, 1)
      }
      const prepParams = PrepParamsArray.join('/')
      return {
        consumed: segments,
        posParams: { prepParams }
      }
    }
  }
  return null
}

export function initializeApp (routingInitService: RoutingInitService) {
  return async (): Promise<any> => {
    const routingArray = routingInitService.Init().then(countryArray => {
      if (!countryArray?.length) {
        availableCountry = []
        websiteInfo = []
      } else {
        availableCountry = countryArray
        websiteInfo = routingInitService.getWebsiteInfo()
        currentUrl = routingInitService.getCurrentUrl()
        hostUrl = routingInitService.getHostURL()
      }
    })
    return await routingArray
  }
}
@NgModule({
  imports: [RouterModule.forRoot(routers, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [RoutingInitService],
      multi: true
    },
    RoutingInitService
  ]
})
export class AppRoutingModule { }
