import { CTAction } from './call-to-action'
import { ImageInfo } from './image-info'
export class RelatedArticle {
  public articleTitle: string
  public shortDescription: string
  public image: ImageInfo
  public articleCta: CTAction
  public gaId?: string
  public duration?: string
  public topicTag?: string
  public translatedList?: string
  public metaTags?: string
  public articleType?: string
  public modifiedDate?: string

  public constructor (
    articleTitle: string,
    shortDescription: string,
    image: ImageInfo,
    articleCta: CTAction,
    gaId?: string,
    duration?: string,
    topicTag?: string,
    translatedList?: string,
    metaTags?: string,
    articleType?: string,
    modifiedDate?: string

  ) {
    this.articleTitle = articleTitle
    this.shortDescription = shortDescription
    this.image = image
    this.articleCta = articleCta
    this.gaId = gaId
    this.duration = duration
    this.topicTag = topicTag
    this.translatedList = translatedList
    this.metaTags = metaTags
    this.articleType = articleType
    this.modifiedDate = modifiedDate
  }
}
