import { DOCUMENT, isPlatformBrowser, Location } from '@angular/common'
import { Inject, Injectable, Injector, PLATFORM_ID } from '@angular/core'
import { REQUEST } from '@nguniversal/express-engine/tokens'
import { HttpServiceService } from './http-service.service'
import { tap } from 'rxjs/internal/operators/tap'
import { CookieBackendService } from './cookie-backend.service'
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject'

@Injectable({
    providedIn: 'root'
})
export class RoutingInitService {
    public availableCountry
    public websiteInfo = []
    public websiteUrl = []
    public currentUrl = ''
    public websiteStatus = new BehaviorSubject(false)
    public bookNowUrl = ''
    public enableSmartBannerLocation = false
    public websiteCountryCode = []
    constructor (private readonly injector: Injector,
        @Inject(PLATFORM_ID) private readonly platformId: Object,
        private readonly http: HttpServiceService,
        public readonly location: Location,
        private cookieBackendService: CookieBackendService,
        @Inject(DOCUMENT) private readonly document: any) { }
    getcountry () {
        return this.availableCountry
    }
    getWebsiteInfo () {
        return this.websiteInfo
    }
    getWebsiteUrl () {
        return this.websiteUrl
    }
    getCurrentUrl () {
        return this.currentUrl
    }
    getLanguageDetails (defaultLanguage) {
        return {
            name: defaultLanguage?.name || '',
            title: defaultLanguage?.title || '',
            code: defaultLanguage?.code || '',
            urlCode: defaultLanguage?.urlCode || '',
            isoCode: defaultLanguage?.isoCode || '',
            enableLanguage: defaultLanguage?.enableLanguage || 'No',
            dropdownCode: defaultLanguage?.dropdownCode || '',
            displayTitle: defaultLanguage?.displayTitle || '',
            enableChat: defaultLanguage?.enableChat || 'No',
            htmlTag: defaultLanguage?.htmlTag || '',
            gaID: defaultLanguage?.gaID || ''
        }
    }
    isLanguageCode (segments) {
        if (segments?.length) {
            let countryDetails = this.websiteInfo ? this.websiteInfo['global']?.LanguageList : []
            if (this.availableCountry?.indexOf(segments[0]) !== -1) {
                countryDetails = this.websiteInfo ? this.websiteInfo[segments[0]]?.LanguageList : []
            }
            const countryCodeArray = countryDetails?.length ? countryDetails.map((code) => { return code.urlCode }) : []
            const codeIndex = countryCodeArray.indexOf(segments[segments.length - 1])
            if (codeIndex !== -1) {
                return codeIndex
            } else {
                return -1
            }
        } else {
            return -1
        }
    }
    public getParameterByName (name, url) {
        name = name.replace(/[\[\]]/g, '\\$&')
        const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)')
        const results = regex.exec(url)
        if (!results) return null
        if (!results[2]) return ''
        return decodeURIComponent(results[2].replace(/\+/g, ' '))
    }

    public getHostURL (): string {
        if (!isPlatformBrowser(this.platformId)) {
            const request = this.injector.get(REQUEST)
            return request?.get('origin')
        } else {
            return this.document.location.hostname
          }
    }

    Init () {
        return new Promise<any>((resolve, reject) => {
            this.http.get('AWS_ENDPOINT_URL', '?content_type=website&preview=false').pipe(
                tap(data => {
                    const websiteCountryCode = []
                    const websiteDetails = []
                    if (data?.items?.length) {
                        this.availableCountry = data.items.map(item => {
                            const languageDetails: any = {}
                            const countryCodeArray = {}
                            if (item?.website) {
                                if (item?.live === 'Yes' && this.cookieBackendService.getClientCountryCode() === item?.cloudfrontCountryCode) {
                                    this.bookNowUrl = '/' + item?.website + '/' + 'test-dates'
                                }
                                if (item?.appLive === 'Yes' && this.cookieBackendService.getClientCountryCode() === item?.cloudfrontCountryCode) {
                                    this.enableSmartBannerLocation = true
                                }
                                countryCodeArray['website'] = item.website || ''
                                countryCodeArray['enableOrganicGeoRedirection'] = item.enableOrganicGeoRedirection || ''
                                countryCodeArray['enableCloudfrontGeoRedirection'] = item.enableCloudfrontGeoRedirection || ''
                                countryCodeArray['live'] = item.live || ''
                                languageDetails['website'] = item.website || ''
                                languageDetails['websiteName'] = item.websiteName || ''
                                languageDetails['live'] = item.live || ''
                                languageDetails['appLive'] = item.appLive || ''
                                languageDetails['enableSecondaryLanguages'] = item.enableSecondaryLanguages || 'No'
                                languageDetails['prepEnabled'] = item.prepEnabled || 'No'
                                languageDetails['enableCsr'] = item.enableCsr || 'No'
                                languageDetails['defaultLanguage'] = {}
                                languageDetails['LanguageList'] = []
                                languageDetails['searchEnabled'] = item.searchEnabled || 'No'
                                languageDetails['enableManageCookies'] = item.enableManageCookies || 'No'
                                languageDetails['countryIso3Code'] = item.countryIso3Code || ''
                                languageDetails['enableSessionSearchV2'] = item.enableSessionSearchV2 || ''
                                if (item?.defaultLanguage) {
                                    languageDetails.defaultLanguage = this.getLanguageDetails(item?.defaultLanguage)
                                }
                                if (item?.languages?.length) {
                                    languageDetails['LanguageList'] = item.languages.map(language => {
                                        return this.getLanguageDetails(language)
                                    })
                                    if (Object.keys(languageDetails?.defaultLanguage)?.length > 0) {
                                        languageDetails['LanguageList'].unshift(languageDetails['defaultLanguage'])
                                    }
                                }
                                if (item?.cloudfrontCountryCode) {
                                    websiteCountryCode[item.cloudfrontCountryCode] = countryCodeArray
                                }
                                websiteDetails[item.website] = languageDetails
                            }
                            return item.website
                        })
                        this.websiteCountryCode = websiteCountryCode
                        this.websiteInfo = websiteDetails
                        let countryName = 'global'
                        let languageCode = 'en-GB'
                        const locationPath = this.location.path().split('?')[0].split('/').filter((val) => val !== '')
                        this.websiteUrl = locationPath
                        this.currentUrl = this.location.path()
                        if (locationPath?.length) {
                            let countryDefaultLanguage: any
                            if (this.availableCountry.indexOf(locationPath[0]) !== -1) {
                                countryName = locationPath[0]
                                countryDefaultLanguage = this.websiteInfo[countryName]?.defaultLanguage
                            }
                            if (countryDefaultLanguage && Object.keys(countryDefaultLanguage)?.length) {
                                languageCode = countryDefaultLanguage.code
                            }
                        }
                        const langCode = this.isLanguageCode(this.websiteUrl)
                        const currentLang = this.cookieBackendService.get('currentLang')
                        if (langCode !== -1) {
                            const enableLanguage = this.websiteInfo[countryName]?.LanguageList[langCode]?.enableLanguage
                            if (enableLanguage === 'Yes' || this.getParameterByName('preview', this.location.path())) {
                                languageCode = this.websiteInfo[countryName]?.LanguageList[langCode]?.code
                            }
                            this.cookieBackendService.set('currentLang', languageCode)
                        } else {
                            languageCode = currentLang || languageCode || ''
                        }
                        if (locationPath[0] && locationPath[0] !== this.cookieBackendService.get('country')) {
                             if (langCode === -1) {
                                languageCode = this.websiteInfo[countryName]?.defaultLanguage?.code
                            }
                            if (currentLang && languageCode === this.websiteInfo[countryName]?.defaultLanguage?.code) {
                                this.cookieBackendService.delete('currentLang', '/')
                            } else if (languageCode !== this.websiteInfo[countryName].defaultLanguage.code) {
                                this.cookieBackendService.set('currentLang', languageCode)
                            }
                            const currentlanguage = this.cookieBackendService.get('currentLang')
                            const countrySelectedlang = this.cookieBackendService.get(countryName + '_SelectedLang')
                            if ((countrySelectedlang !== currentlanguage) && countrySelectedlang !== undefined) {
                              this.cookieBackendService.set('currentLang', this.cookieBackendService.get(countryName + '_SelectedLang'))
                            } else {
                              this.cookieBackendService.delete('currentLang', '/')
                            }
                            this.cookieBackendService.set('country', countryName)
                        } else if (!locationPath[0]) {
                            if (currentLang) {
                                this.cookieBackendService.delete('currentLang', '/')
                            } else if (languageCode !== this.websiteInfo[countryName].defaultLanguage.code) {
                                this.cookieBackendService.set('currentLang', languageCode)
                            }
                            this.cookieBackendService.set('country', countryName)
                        } else {
                             if (languageCode === this.websiteInfo[countryName]?.defaultLanguage?.code) {
                                this.cookieBackendService.delete('currentLang', '/')
                            } else {
                                this.cookieBackendService.set('currentLang', languageCode)
                            }
                            if (!this.cookieBackendService.get(countryName + '_SelectedLang')) {
                                this.cookieBackendService.delete('currentLang', '/')
                            } else {
                                if (this.websiteInfo[countryName]?.defaultLanguage?.code !== 'en-GB') {
                                    languageCode !== this.websiteInfo[countryName]?.defaultLanguage?.code ? this.cookieBackendService.set('currentLang', this.cookieBackendService.get(countryName + '_SelectedLang')) : this.cookieBackendService.delete('currentLang', '/')
                                } else {
                                    this.cookieBackendService.set('currentLang', this.cookieBackendService.get(countryName + '_SelectedLang'))
                                }
                            }
                            this.cookieBackendService.set('country', countryName)
                        }
                        if (!this.cookieBackendService.get('country')) {
                            this.cookieBackendService.set('country', countryName)
                        }
                    }
                    resolve(this.availableCountry)
                }, () => {
                    resolve(this.availableCountry)
                }
            )).subscribe()
        })
    }
}
