import { Component, Renderer2, Inject, EventEmitter, Input, Output, PLATFORM_ID, ElementRef } from '@angular/core'
import { DOCUMENT, isPlatformBrowser } from '@angular/common'
import { HttpServiceService } from '../../services/http-service.service'
import { ActivatedRoute, Router } from '@angular/router'
import { ContentfulService } from '../../services/contentful.service'
import { ImageConstant } from '../../common/utilities/constants'

@Component({
  selector: 'app-search-popup',
  templateUrl: './search-popup.component.html',
  styleUrls: ['./search-popup.component.css']
})
export class SearchPopupComponent {
  isLoading: boolean
  searchKey: any
  @Output() closePopup = new EventEmitter()
  searchText: any
  keyWordData: any
  trendingSearch: boolean = false
  searchResults: boolean = false
  isSearchButtonEnable: boolean = false
  isSplCharEntered: boolean
  searchRecommendations: any[]
  @Input() otherDetails
  arrowkeyLocation: number = 0
  isTextClear: boolean
  trendingData: any
  assetPath = ImageConstant + '/IELTS_Website_Rebrand'
  defaultImage = ImageConstant + '/assets/img_px.gif'
  clrText: boolean
  constructor (private readonly renderer: Renderer2, private readonly http: HttpServiceService,
    @Inject(DOCUMENT) public document: Document,
    private readonly route: ActivatedRoute,
    @Inject(PLATFORM_ID) private readonly platformId: any,
    private readonly contentfulservice: ContentfulService,
    private readonly el: ElementRef,
    private readonly router: Router
  ) {

   }

  ngOnInit () {
    this.searchText = this.searchKey
    const trendingData = sessionStorage.getItem(this.otherDetails?.countryName + '_trending_data')
    if (trendingData) {
      this.trendingData = JSON.parse(trendingData)
      this.trendingSearch = true
    } else {
      this.getTrendingSearchData()
    }
    this.otherDetails.pageName = this.contentfulservice.customDimensionValue['pageName']
    if (isPlatformBrowser(this.platformId)) {
      if (sessionStorage.getItem('search_data')) {
        this.keyWordData = JSON.parse(sessionStorage.getItem('search_data'))
      } else {
        this.getSearchData('keyword', '')
      }
    }
  }

  stop (e) {
    e.stopPropagation()
  }
  clsGlbSrch () {
    this.closePopup.emit(true)
  }
  getSearchData (val, searchKeyVal) {
    searchKeyVal = searchKeyVal.replace(/<[^>]*>?/gm, '')
    sessionStorage.setItem('searchValue', searchKeyVal)
    const siteLanguage = this.contentfulservice.siteLanguage
    const keyword = searchKeyVal
    const requestType = val
    const pageNo = 1
    const searchURL = '?website=' + this.otherDetails?.countryName + '&locale=' + siteLanguage + '&keyword=' + keyword + '&requestType=' + requestType + '&page=' + pageNo
    this.http.get('search_get_Url', searchURL).subscribe(
      data => {
        try {
          this.keyWordData = data
          if (requestType === 'keyword') {
            sessionStorage.setItem('search_data', JSON.stringify(data))
          }
          this.isLoading = false
          sessionStorage.setItem('searchValue', searchKeyVal)
          this.contentfulservice.searchKey = searchKeyVal
        } catch {
          this.isLoading = false
        }
      })
  }

  getTrendingSearchData () {
    const siteLanguage = this.contentfulservice.siteLanguage
    const requestType = 'trendingSearch'
    const pageNo = 1
    const searchURL = '?website=' + this.otherDetails?.countryName + '&locale=' + siteLanguage + '&requestType=' + requestType + '&page=' + pageNo
    this.http.get('search_get_Url', searchURL).subscribe(
      data => {
        try {
          this.trendingData = data.trendingSearch
          sessionStorage.setItem(this.otherDetails?.countryName + '_trending_data', JSON.stringify(this.trendingData))
          sessionStorage.setItem(this.otherDetails?.countryName + '_meta_data', JSON.stringify(data.seoFields))
          this.trendingSearch = true
        } catch {

        }
      })
  }
  onKeyUpEvent (event): any {
    this.searchKey = event.target.value
    this.searchKey?.length > 0 ? this.clrText = true : this.clrText = false
    if (this.searchKey?.length > 2) {
      this.trendingSearch = false
    } else {
      this.searchResults = false
      this.trendingSearch = true
    }
    let matchingKeywordRecommendation = []
    let matchingKeywordObject = []
    const midValueSearchRecommendation = []
    const trimmedSearchKey = this.searchKey?.trim().toLowerCase()
    if (this.searchKey?.length > 2) {
      this.isSearchButtonEnable = true
      if (this.keyWordData?.status_code == 200) {
        this.keyWordData.recommendations.length > 0 ? this.searchResults = true : this.searchResults = false
        this.keyWordData.recommendations.map(value => {
          if (value.toLowerCase()?.includes(trimmedSearchKey)) {
            matchingKeywordRecommendation.push(value)
          }
          this.searchRecommendations = matchingKeywordRecommendation
          matchingKeywordObject = matchingKeywordRecommendation.map((data, i) => {
            let indexValue
            if (trimmedSearchKey?.includes(' ')) {
              indexValue = data.toLowerCase().indexOf(trimmedSearchKey)
            } else {
              data.split(' ').map(value => {
                if (value.toLowerCase().indexOf(trimmedSearchKey) === 0) {
                  indexValue = data.toLowerCase().indexOf(value.toLowerCase())
                }
              })
            }
            if (indexValue === undefined) {
              midValueSearchRecommendation.push(data)
            }
            return { index: i, value: indexValue }
          })
        })
        matchingKeywordObject.sort((a, b) => {
          if (a.value === undefined) {
            a.value = b.value + 1
          }
          if (b.value === undefined) {
            b.value = a.value + 1
          }
          return +(a.value > b.value) || +(a.value === b.value) - 1
        })
        matchingKeywordRecommendation = matchingKeywordObject.map(value => {
          return matchingKeywordRecommendation[value.index]
        })
        matchingKeywordRecommendation = matchingKeywordRecommendation.filter(data => {
          return !midValueSearchRecommendation?.includes(data)
        })
        matchingKeywordRecommendation = matchingKeywordRecommendation.concat(midValueSearchRecommendation)

        this.searchRecommendations = matchingKeywordRecommendation.filter((data, index) => {
          return matchingKeywordRecommendation.indexOf(data) === index
        })

        this.searchRecommendations = this.searchRecommendations.slice(0, 10)
        this.searchRecommendations = this.searchRecommendations.map(data => {
          return data.replace(new RegExp(trimmedSearchKey, 'gi'), match => {
            return '<span class="match-word">' + match + '</span>'
          })
        })

        switch (event.keyCode) {
          case 38: // this is the ascii of arrow up

            if (this.arrowkeyLocation < 0) {
              this.arrowkeyLocation = 0
            } else {
              this.arrowkeyLocation--
            }
            this.searchText = this.searchRecommendations[this.arrowkeyLocation - 2]

            break
          case 40: // this is the ascii of arrow down
            if (this.arrowkeyLocation >= this.searchRecommendations.length) {
              this.arrowkeyLocation = 0
            } else {
              this.arrowkeyLocation++
            }
            this.searchText = this.searchRecommendations[this.arrowkeyLocation - 1]
            break
          case 13: // this is the ascii of enter button

            this.arrowkeyLocation > 0 ? this.searchResult(this.searchText) : this.searchResult(this.searchKey)
            this.arrowkeyLocation = 0
        }
        const sugEl = this.document.getElementById('srchli-' + this.arrowkeyLocation)
        const srchstrt = this.document.getElementById('srchli-1')
       if (this.arrowkeyLocation > 4) {
          sugEl.scrollIntoView(false)
        } else if (this.arrowkeyLocation === 0 || ((this.arrowkeyLocation) >= this.searchRecommendations.length)) {
          if (srchstrt != null) {
            srchstrt.scrollIntoView(false)
          }
        }
      }
    } else {
      this.isSearchButtonEnable = false
    }
  }
  clearText () {
    this.searchKey = ''
    this.clrText = false
    this.searchResults = false
    this.isSearchButtonEnable = false
  }
  focusFn () {
    if (!this.searchResults) {
     this.trendingSearch = true
     }
    }
  checkSpecialChar (value): any {
    const pattern = new RegExp(/[^a-zA-Z0-9\s-]+$/)
    value.split('').map(char => {
      if (pattern.test(char)) {
        this.isSearchButtonEnable = false
        this.contentfulservice.specialChar = true
        this.router.navigate([this.otherDetails?.countryUrl + '/search-results'])
      }
    })
  }
  searchResult (value) {
    if (this.isSearchButtonEnable) {
      value = value.replace(/<[^>]*>?/gm, '')
      this.searchKey = value
      sessionStorage.setItem('searchValue', value)
      this.contentfulservice.searchKeyValue?.next(value)
      this.contentfulservice.pageTrack('search_icon', 'na', 'na', this.otherDetails?.countryName, this.otherDetails?.pageName, this.contentfulservice.siteLanguage, 'header', this.searchKey.toLowerCase())
      this.router.navigate([this.otherDetails?.countryUrl + '/search-results'])

      this.clsGlbSrch()
    }
  }
  trendSearchResult (value) {
    value = value.replace(/<[^>]*>?/gm, '')
    this.searchKey = value
    sessionStorage.setItem('searchValue', value)
    this.contentfulservice.searchKeyValue?.next(value)
    this.contentfulservice.pageTrack('trending_search', value, 'na', this.otherDetails?.countryName, this.otherDetails?.pageName, this.contentfulservice.siteLanguage, 'header', 'na')
    this.router.navigate([this.otherDetails?.countryUrl + '/search-results'])

    this.clsGlbSrch()
  }
}
