export class HrefLang {
    public website: string
    public isLive: string
    public langcode: string
    public isoCode: string
    public robots: string
    public domain: string
    public secondaryLangauges: object[]
    constructor (
      website: string,
      isLive: string,
      langcode: string,
      isoCode: string,
      robots: string,
      domain: string,
      secondaryLangauges: object[] = []
    ) {
      this.website = website
      this.isLive = isLive
      this.langcode = langcode
      this.isoCode = isoCode
      this.robots = robots
      this.domain = domain
      this.secondaryLangauges = secondaryLangauges
    }
  }
