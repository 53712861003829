<div class="search-popup">
    <div class="search-popup-overlay position-absolute" (click)="clsGlbSrch()"></div>
        <div class="search-popup-wrapper overflow-hidden position-relative">
            <div class="close-popup d-flex justify-content-end hide-900" >
                <img width="32" height="32" [defaultImage]="defaultImage" lazyLoad="{{assetPath}}/assets/home/icons/close_black.svg" alt="close button" (click)="clsGlbSrch()">
            </div>
            <div class="search-popup-inner d-flex flex-column gap24"  (click)="stop($event)" >
                <h2 class="heading2 clr-black">Search on IELTS website</h2>
                <div class="search-bar position-relative">
                    <input class="search-input custom-option-select" id="glsrch" type="text" (focus)="focusFn()"
                        (keyup)="onKeyUpEvent($event)"(input)="onKeyUpEvent($event)"
                        placeholder="Generic search..." />
                    <div class="search-icons d-flex align-items-center position-absolute">
                        <div class="search-clear" *ngIf="clrText" (click)="clearText()">
                            <img width="14" height="14" [defaultImage]="defaultImage" lazyLoad="{{assetPath}}/assets/home/icons/close_grey.svg" alt="close button">
                        </div>
                        <div class="search-btn" [ngClass]="!isSearchButtonEnable ? 'search-disabled' : ''" (click)="searchResult(searchKey)">
                            <img width="26" height="26" [defaultImage]="defaultImage" lazyLoad="{{assetPath}}/assets/home/icons/search_white.svg" alt="search button">
                        </div>
                    </div>
                </div>
                <div class="search-suggession-wrapper hide-900"  *ngIf="trendingSearch">
                    <div class="search-suggession-box d-flex flex-column gap32">
                        <div class="trending-search d-flex flex-column gap12">
                            <h5 class="heading5 clr-black d-flex gap8">
                                <img width="20" height="20" [defaultImage]="defaultImage" lazyLoad="{{assetPath}}/assets/home/icons/trending.svg" alt="trending search">
                                <span>Trending search</span></h5>
                            <ul class="trend-list d-flex flex-column gap12 custom-scroll">
                                <li *ngFor="let trendData of trendingData; let idx = index" class="trend-list-item d-flex align-items-center justify-content-between gap24 font-medium" (click)="trendSearchResult(trendData)">
                                    <a [innerHTML]="trendData"></a>
                                    <span ><i class="triangle-arrow right"></i></span>
                                </li>
                               
                            </ul>
                        </div>
                        
                    </div>
                    
                </div>
                <div class="search-suggession-wrapper hide-900" *ngIf="searchResults">
                    <div class="search-suggession-box d-flex flex-column gap32">


                        <div class="trending-search-result d-flex flex-column gap12">
                            <h5 class="heading5 clr-black d-flex gap8">
                                <img width="20" height="20" [defaultImage]="defaultImage" lazyLoad="{{assetPath}}/assets/home/icons/search_red.svg" alt="search result">
                                <span>Search '{{searchKey}}’</span>
                            </h5>
                            <div  id="srch">
                            <ul class="trend-list d-flex flex-column gap12 custom-scroll">
                                <li id="srchli-{{idx+1}}" [class.active]="idx+1 == arrowkeyLocation"
                                    *ngFor="let searchValue of searchRecommendations; let idx = index"
                                    class="trend-list-item" (click)="searchResult(searchValue)">
                                    <a  [innerHTML]="searchValue"></a>
                                </li>

                                
                            </ul>
                        </div>

                        </div>

                    </div>

                </div>
            </div>
        </div>
</div>