<ng-container *ngIf="otherHeaderDetails && otherHeaderDetails['menucount'] == 'first'">
  <div class="d-flex flex-column flex-reverse-1024 justify-content-between h-100">
    <div *ngIf="childMenu && childMenu?.childNavigation && childMenu?.childNavigation[0]?.componentType != 'Navigation card'"  class="menu-item-wrapper px-48 d-flex gap48 flex-column-1024 px-48-901-1024">
      <div *ngFor="let childData of childMenu?.childNavigation" class="menu-items d-flex flex-column">
        <!-- menu type without icon -->
        <a *ngIf="childData?.navigationUrl" appDynamicUrl="{{childData?.navigationUrl}}"
          [routerLink]="childData?.navigationUrl?.includes('://') ? null : countryUrl + childData?.navigationUrl"
          (click)="gaTrackDetails(otherHeaderDetails['parentTitle']?.toLowerCase().split(' ').join('_') + '_header', childMenu?.gaID, childData?.gaID, otherHeaderDetails['parentIndex'])"
          [target]="childData?.target == 'Open in same tab' ? '_self' : '_blank'" title="{{childData?.navigationLabel}}"
          class="menu-title">{{childData?.navigationLabel}}
        </a>
        <a *ngIf="!childData?.navigationUrl" title="{{childData?.navigationLabel}}"
          class="menu-title">{{childData?.navigationLabel}}
        </a>
        <a *ngFor="let subChild of childData.childNavigation" appDynamicUrl="{{subChild?.navigationUrl}}"
          [routerLink]="subChild?.navigationUrl?.includes('://') ? null : countryUrl + subChild?.navigationUrl"
          title="{{subChild?.navigationLabel}}"
          (click)="gaTrackDetails(otherHeaderDetails['parentTitle']?.toLowerCase().split(' ').join('_') + '_header', childMenu?.gaID, subChild?.gaID, otherHeaderDetails['parentIndex'])"
          class="menu-item-link d-flex align-items-center gap12 position-relative"
          [target]="subChild?.target == 'Open in same tab' ? '_self' : '_blank'"
          [ngClass]="subChild?.navigationImage?.file?.url ? 'menu-type-icon' : 'menu-type-list'">
          <span *ngIf="subChild?.navigationImage?.file?.url" class="menu-item-icon bg-tonedwhite"></span>
          <img *ngIf="subChild?.navigationImage?.file?.url" class="menu-item-icon position-absolute" width="24"
            height="24" [defaultImage]="defaultImage" lazyLoad="{{subChild?.navigationImage?.file?.url}}"
            alt="{{subChild?.navigationImage?.title}}">
          {{subChild?.navigationLabel}}
        </a>
      </div>
    </div>
    <div *ngIf = "childMenu?.childNavigation[0]?.componentType === 'Navigation card'" class="menu-item-wrapper px-48 d-flex gap48 flex-column-1024 px-48-901-1024">
      <div class="menu-items d-flex flex-column other-menu" *ngFor = "let menu of childMenu?.childNavigation">
        <a *ngIf="menu?.navigationUrl; else noHyperlink" class="menu-title mb-20" appDynamicUrl="{{menu?.navigationUrl}}"   [target]="menu?.target == 'Open in same tab' ? '_self' : '_blank'" title="{{menu?.navigationLabel}}" [routerLink]="menu?.navigationUrl?.includes('://') ? null : countryUrl + menu?.navigationUrl" >{{menu?.navigationLabel}}</a>
        <ng-template #noHyperlink>
          <a  class="menu-title mb-20" title="{{menu?.navigationLabel}}" >{{menu?.navigationLabel}}</a>
        </ng-template>
          <div class="grid-auto-col gap24" *ngIf="menu?.childNavigation?.length">
            <a *ngFor="let childMenu of menu.childNavigation" appDynamicUrl="{{childMenu?.navigationUrl}}" class="justify-content-between test-card d-flex flex-column p-24 gap48 gap24-1024 p-20-1024" (click)="gaTrackDetails(otherHeaderDetails['parentTitle']?.toLowerCase().split(' ').join('_') + '_header', childMenu?.gaID, childMenu?.gaID, otherHeaderDetails['parentIndex'])"
            [routerLink]="childMenu?.navigationUrl?.includes('://') ? null : countryUrl + childMenu?.navigationUrl" [target]="childMenu?.target == 'Open in same tab' ? '_self' : '_blank'" title="{{childMenu?.navigationLabel}}">
            <div class="d-flex flex-column gap12">
              <h5 class="heading4 clr-black fs-18 lh-20">{{childMenu?.navigationLabel}}</h5>
              <p class="font-opensans fs-14 lh-24">{{childMenu?.shortDescription}}</p>  
            </div>
           <div class="arrow-cta p-8 rounded d-flex align-items-center justify-content-center"><img width="20" height="20" src="{{assetPath}}/assets/icons/arrow.svg" alt="arrow"></div>     
          </a>
          </div>
      </div>
    </div>

    <ng-container *ngIf="childMenu?.childNavigation[0]?.componentType != 'Navigation card'">
      <div class="test-pod menu-item-wrapper px-48 px-48-901-1024" *ngIf="childMenu?.promoCard">
        <div
          class="test-container d-flex gap24 align-items-center justify-content-between p-24 align-items-start-900 flex-column-900 rounded-8">
    
          <div class="d-flex gap16 flex-column-1024">
            <img width="48" height="48" alt="icon" [defaultImage]="defaultImage"
              lazyLoad="{{childMenu?.promoCard[0]?.image?.file?.url}}" alt="{{childMenu?.promoCard[0]?.title}}">
            <div class="clr-black">
              <h5 class="tool-head font-semi-bold">{{childMenu?.promoCard[0]?.name}}</h5>
              <p class="tool-para font-opensans">{{childMenu?.promoCard[0]?.shortDescription}}</p>
            </div>
          </div>
          <div class="d-flex flex-column gap12 w-100-900">
            <a class="button rounded-full button-primary btn-override primary-hover h-40"
              title="{{childMenu?.promoCard[0]?.callToAction?.primaryCtaLabel}}"
              [target]="childMenu?.promoCard[0]?.callToAction?.primaryCtaTarget == 'Open in same tab' ? '_self' : '_blank'"
              appDynamicUrl="{{childMenu?.promoCard[0]?.callToAction?.primaryCtaUrl}}"
              [routerLink]="childMenu?.promoCard[0]?.callToAction?.primaryCtaUrl?.includes('://') ? null : countryUrl + childMenu?.promoCard[0]?.callToAction?.primaryCtaUrl"
              (click)="gaTrackDetails(childMenu?.promoCard[0]?.callToAction?.gaId?.toLowerCase().split(' ').join('_'), childMenu?.gaID, childMenu?.gaID, otherHeaderDetails['parentIndex'], childMenu?.promoCard[0]?.callToAction?.primaryCtaUrl)">{{childMenu?.promoCard[0]?.callToAction?.primaryCtaLabel}}</a>
            <a class="button rounded-full button-outline-grey btn-override button-grey-hover h-40"
              title="{{childMenu?.promoCard[0]?.callToAction?.secondaryCtaLabel}}"
              [target]="childMenu?.promoCard[0]?.callToAction?.secondaryCtaTarget == 'Open in same tab' ? '_self' : '_blank'"
              appDynamicUrl="{{childMenu?.promoCard[0]?.callToAction?.secondaryCtaUrl}}"
              [routerLink]="childMenu?.promoCard[0]?.callToAction?.secondaryCtaUrl?.includes('://') ? null : countryUrl + childMenu?.promoCard[0]?.callToAction?.secondaryCtaUrl"
              (click)="gaTrackDetails(childMenu?.promoCard[0]?.callToAction?.secondaryGaId?.toLowerCase().split(' ').join('_'), childMenu?.gaID, childMenu?.gaID, otherHeaderDetails['parentIndex'], childMenu?.promoCard[0]?.callToAction?.secondaryCtaUrl)">{{childMenu?.promoCard[0]?.callToAction?.secondaryCtaLabel}}</a>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="childMenu?.childNavigation[0]?.componentType === 'Navigation card'">
      <a class="test-pod menu-item-wrapper px-48 custom-label px-48-901-1024"
        [target]="childMenu?.promoCard[0]?.callToAction?.primaryCtaTarget"
        appDynamicUrl="{{childMenu?.promoCard[0]?.callToAction?.primaryCtaUrl}}" *ngIf="childMenu?.promoCard"
        [routerLink]="childMenu?.promoCard[0]?.callToAction?.primaryCtaUrl?.includes('://') ? null : countryUrl + childMenu?.promoCard[0]?.callToAction?.primaryCtaUrl">
        <div
          class="test-container d-flex gap24 align-items-center justify-content-between p-24 align-items-start-900 flex-column-900 rounded-8">
          <div class="d-flex gap16 flex-column-1024">
            <img *ngIf="childMenu?.promoCard[0]?.image?.file?.url" width="48" height="48" alt="icon" [defaultImage]="defaultImage"
              lazyLoad="{{childMenu?.promoCard[0]?.image?.file?.url}}" alt="{{childMenu?.promoCard[0]?.title}}">
            <div class="clr-black">
              <h5 class="tool-head font-semi-bold">{{childMenu?.promoCard[0]?.name}}</h5>
              <p class="tool-para font-opensans">{{childMenu?.promoCard[0]?.shortDescription}}</p>
            </div>
          </div>
          <span class="chevron-arrow bg-charcoal-grey right hide-1024"></span>
          <div class="button rounded-full button-primary btn-override primary-hover h-40 mw-100 hide-1025"
            title="{{childMenu?.promoCard[0]?.callToAction?.primaryCtaLabel}}">
            {{childMenu?.promoCard[0]?.callToAction?.primaryCtaLabel}}</div>
        </div>
      </a>
    </ng-container>

  </div>
</ng-container>

<ng-container *ngIf="otherHeaderDetails && otherHeaderDetails['menucount'] == 'second'">
  <div class="d-flex flex-column flex-reverse-1024">
    <div class="menu-item-wrapper px-48 d-flex-1024 flex-column-1024 px-48-901-1024 masonary-container">
      <!-- masonary container for prepare -->
      <ng-container *ngFor="let childData2 of childMenu; let i = index">
        <div id="masonary-item{{otherHeaderDetails['parentIndex']}}{{i + 1}}">
          <div class="menu-items d-flex flex-column"> <!-- menu-type-list ---- menu type without icon -->
            <a *ngIf="childData2?.navigationUrl" appDynamicUrl="{{childData2?.navigationUrl}}"
              [routerLink]="childData2?.navigationUrl?.includes('://') ? null : countryUrl + childData2?.navigationUrl"
              (click)="gaTrackDetails(otherHeaderDetails['parentTitle']?.toLowerCase().split(' ').join('_') + '_header', childData2?.gaID, childData2?.gaID, otherHeaderDetails['parentIndex'])"
              [target]="childData2?.target == 'Open in same tab' ? '_self' : '_blank'"
              title="{{childData2?.navigationLabel}}" class="menu-title">{{childData2?.navigationLabel}}
            </a>
            <a *ngIf="!childData2?.navigationUrl" title="{{childData2?.navigationLabel}}"
              class="menu-title">{{childData2?.navigationLabel}}
            </a>
            <a *ngFor="let subChild of childData2?.childNavigation" appDynamicUrl="{{subChild?.navigationUrl}}"
              [routerLink]="subChild?.navigationUrl?.includes('://') ? null : countryUrl + subChild?.navigationUrl"
              title="{{subChild?.navigationLabel}}"
              (click)="gaTrackDetails(otherHeaderDetails['parentTitle']?.toLowerCase().split(' ').join('_') + '_header', childData2?.gaID, subChild?.gaID, otherHeaderDetails['parentIndex'])"
              class="menu-item-link d-flex align-items-center gap12 position-relative"
              [target]="subChild?.target == 'Open in same tab' ? '_self' : '_blank'"
              [ngClass]="subChild?.navigationImage?.file?.url ? 'menu-type-icon' : 'menu-type-list'">
              <span *ngIf="subChild?.navigationImage?.file?.url" class="menu-item-icon bg-tonedwhite"></span>
              <img *ngIf="subChild?.navigationImage?.file?.url" class="menu-item-icon position-absolute" width="24"
                height="24" [defaultImage]="defaultImage" lazyLoad="{{subChild?.navigationImage?.file?.url}}"
                alt="{{subChild?.navigationImage?.title}}">
              {{subChild?.navigationLabel}}
            </a>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="diagnostic-tool p-24-1024" *ngIf="otherHeaderDetails['promoCard']?.length" data-testid="autom_preparationTools_header">
    <div></div> <!-- don't remove  -->
    <a [target]="otherHeaderDetails['promoCard'][0]?.callToAction?.primaryCtaTarget == 'Open in same tab' ? '_self' : '_blank'"
    appDynamicUrl="{{otherHeaderDetails['promoCard'][0]?.callToAction?.primaryCtaUrl}}" (click)="preparationToolStarted(otherHeaderDetails['promoCard'])" rel="opener"
    [routerLink]="otherHeaderDetails['promoCard'][0]?.callToAction?.primaryCtaUrl?.includes('://') ? null : otherHeaderDetails['promoCard'][0]?.callToAction?.primaryCtaUrl"
      title="diagnostic-tool" class="diagnostic d-flex gap24 align-items-center justify-content-between p-24 align-items-start-1024 flex-column-1024 rounded-8">
      <div class="d-flex gap16 flex-column-1024">
        <img *ngIf="otherHeaderDetails['promoCard'][0]?.image?.file?.url" width="48" height="48"
        [defaultImage]="defaultImage" lazyLoad="{{otherHeaderDetails['promoCard'][0]?.image?.file?.url}}"
        alt="{{otherHeaderDetails['promoCard'][0]?.image?.title}}">
        <div class="clr-blue-700">
          <h5 class="tool-head font-semi-bold ">{{otherHeaderDetails['promoCard'][0]?.title}}</h5>
          <p class="tool-para font-opensans">{{otherHeaderDetails['promoCard'][0]?.shortDescription}}</p>
        </div>
      </div>
      <span class="button rounded-full btn-override clr-blue-700 border-clr-blue m-full-width-1024">{{otherHeaderDetails['promoCard'][0]?.callToAction?.primaryCtaLabel}}</span>
    </a>
  </div>
  </div>
</ng-container>

<ng-container *ngIf="otherHeaderDetails && otherHeaderDetails['menucount'] == 'third'">
  <div class="menu-item-wrapper px-48 d-flex gap48 flex-column-1024 px-48-901-1024"
    [ngClass]="otherHeaderDetails['hubpageType'] === 'Results' ? 'result-container' : '' ">
    <div *ngIf="otherHeaderDetails['hubpageType'] === 'Results' || otherHeaderDetails['hubpageType'] === 'IELTS community'" class="menu-items menu-type-result">
      <a [routerLink]="null" title="{{otherHeaderDetails['hubpageType'] === 'Results' ? headerConstText?.primaryGetYourResults : headerConstText?.communitySectionTitle}}" class="menu-title">{{otherHeaderDetails['hubpageType'] === 'Results' ? headerConstText?.primaryGetYourResults : headerConstText?.communitySectionTitle}}</a>
      <div class="result-pod p-20 position-relative overflow-hidden">
        <img class="menu-item-icon" width="34" height="34" [defaultImage]="defaultImage"
          lazyLoad="{{assetPath}}/assets/home/icons/{{otherHeaderDetails['hubpageType'] === 'Results' ? 'result' : 'community_icon'}}.svg" alt="Result icon">
        <h4 class="heading4 mt-16">{{otherHeaderDetails['hubpageType'] === 'Results' ? headerConstText?.secondaryGetYourResults : headerConstText?.communityTitle}}</h4>
        <p class="result-para font-opensans">{{otherHeaderDetails['hubpageType'] === 'Results' ? headerConstText?.shortDescription : headerConstText?.communityShortDesc}}</p>
        <a *ngIf="otherHeaderDetails['hubpageType'] === 'Results'" href="{{resultsUrl}}" rel="noopener"
          (click)="gaTrackDetails('get_your_results', 'na', 'na', otherHeaderDetails['parentIndex'])" target="_blank"
          title="{{headerConstText?.getResultsNowCTA}}" class="button button-primary rounded-full mt-16">{{headerConstText?.getResultsNowCTA}}</a>
        <a *ngIf="otherHeaderDetails['hubpageType'] === 'IELTS community'" href="https://ielts.idp.com/about/ielts-community" rel="noopener"
          (click)="gaTrackDetails(otherHeaderDetails['parentTitle']?.toLowerCase().split(' ').join('_') + '_header', 'join_ielts_community', 'access_ielts_community', otherHeaderDetails['parentIndex'])" target="_blank"
          title="{{headerConstText?.communityLabel}}" class="button button-primary rounded-full mt-16">{{headerConstText?.communityLabel}}</a>
      </div>
    </div>
    <div class="menu-two-column d-flex flex-column gap48 gap16-1024">
      <div *ngFor="let childData3 of childMenu" class="menu-items d-flex flex-column" [ngClass]="otherHeaderDetails['hubpageType'] === 'Results' ? 'menu-two-column' : '' ">
        <a *ngIf="childData3?.navigationUrl" appDynamicUrl="{{childData3?.navigationUrl}}"
          [routerLink]="childData3?.navigationUrl?.includes('://') ? null : countryUrl + childData3?.navigationUrl"
          (click)="gaTrackDetails(otherHeaderDetails['parentTitle']?.toLowerCase().split(' ').join('_') + '_header', childData3?.gaID, childData3.gaID, otherHeaderDetails['parentIndex'])"
          [target]="childData3?.target == 'Open in same tab' ? '_self' : '_blank'" title="{{childData3?.navigationLabel}}"
          class="menu-title">{{childData3?.navigationLabel}}
        </a>
        <a *ngIf="!childData3?.navigationUrl" title="{{childData3?.navigationLabel}}"
          class="menu-title">{{childData3?.navigationLabel}}
        </a>
        <a *ngFor="let subChild of childData3?.childNavigation" appDynamicUrl="{{subChild?.navigationUrl}}"
          [routerLink]="subChild?.navigationUrl?.includes('://') ? null : countryUrl + subChild?.navigationUrl"
          title="{{subChild?.navigationLabel}}"
          (click)="gaTrackDetails(otherHeaderDetails['parentTitle']?.toLowerCase().split(' ').join('_') + '_header', childData3?.gaID, subChild?.gaID, otherHeaderDetails['parentIndex'])"
          class="menu-item-link d-flex align-items-center gap12 position-relative"
          [target]="subChild?.target == 'Open in same tab' ? '_self' : '_blank'"
          [ngClass]="subChild?.navigationImage?.file?.url ? 'menu-type-icon' : 'menu-type-list'">
          <span *ngIf="subChild?.navigationImage?.file?.url" class="menu-item-icon bg-tonedwhite"></span>
          <img *ngIf="subChild?.navigationImage?.file?.url" class="menu-item-icon position-absolute"
            [defaultImage]="defaultImage" width="24" height="24" lazyLoad="{{subChild?.navigationImage?.file?.url}}"
            alt="{{subChild?.navigationImage?.title}}">
          {{subChild?.navigationLabel}}
        </a>
      </div>
      <div class="pt-32-1025" *ngIf="otherHeaderDetails['hubpageType'] === 'IELTS community' && otherHeaderDetails['headerSocialNav'] && otherHeaderDetails['headerSocialNav'].length > 0">
        <a *ngIf="otherHeaderDetails['socialShareHeading']" title="{{otherHeaderDetails['socialShareHeading']}}"
          class="menu-title">{{otherHeaderDetails['socialShareHeading']}}</a>   
        <div class="social-icon-wrapper d-flex gap24 flex-wrap pt-11">
          <ng-container *ngFor="let socialIcon of otherHeaderDetails['headerSocialNav'][0].childNavigation">
            <a *ngIf="socialIcon?.navigationUrl"
              class="social-icon rounded overflow-hidden d-flex align-items-center justify-content-center"
              href="{{socialIcon?.navigationUrl}}" target="_blank" rel="noopener"
              (click)="gaTrackDetails(otherHeaderDetails['parentTitle']?.toLowerCase().split(' ').join('_') + '_header', otherHeaderDetails['socialShareHeading'],socialIcon?.socialId?.toLowerCase(), otherHeaderDetails['parentIndex'])">
              <img width="28" height="28" [defaultImage]="defaultImage"
                lazyLoad="{{assetPath}}/assets/home/icons/{{socialIcon?.socialId?.toLowerCase()}}.svg"
                alt="{{socialIcon?.socialId?.toLowerCase()}}">
            </a>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="otherHeaderDetails && otherHeaderDetails['menucount'] == 'fourth'">
  <div class="menu-item-wrapper px-48 d-flex gap48 flex-column-1024 px-48-901-1024">
    <div *ngFor="let childData4 of childMenu; let indexpos = index" class="menu-items d-flex flex-column"
      [ngClass]="indexpos < (childMenu.length - 1) ? 'menu-type-lists' : indexpos == (childMenu.length - 1) ? 'menu-two-column' : '' ">
      <!-- menu type without icon -->
      <a *ngIf="childData4?.navigationUrl" appDynamicUrl="{{childData4?.navigationUrl}}"
        [routerLink]="childData4?.navigationUrl?.includes('://') ? null : countryUrl + childData4?.navigationUrl"
        (click)="gaTrackDetails(otherHeaderDetails['parentTitle']?.toLowerCase().split(' ').join('_') + '_header', childData4?.gaID, childData4?.gaID, otherHeaderDetails['parentIndex'])"
        [target]="childData4?.target == 'Open in same tab' ? '_self' : '_blank'" title="{{childData4?.navigationLabel}}"
        class="menu-title">{{childData4?.navigationLabel}}
      </a>
      <a *ngIf="!childData4?.navigationUrl" title="{{childData4?.navigationLabel}}"
        class="menu-title">{{childData4?.navigationLabel}}
      </a>
      <a *ngFor="let subChild of childData4?.childNavigation" appDynamicUrl="{{subChild?.navigationUrl}}"
        [routerLink]="subChild?.navigationUrl?.includes('://') ? null : countryUrl + subChild?.navigationUrl"
        title="{{subChild?.navigationLabel}}" (click)="gaTrackDetails(otherHeaderDetails['parentTitle']?.toLowerCase().split(' ').join('_') + '_header', childData4?.gaID, subChild?.gaID, otherHeaderDetails['parentIndex'])"
        class="menu-item-link d-flex align-items-center gap12 position-relative" [target]="subChild?.target == 'Open in same tab' ? '_self' : '_blank'"
        [ngClass]="subChild?.navigationImage?.file?.url ? 'menu-type-icon' : 'menu-type-list'">
        <span *ngIf="subChild?.navigationImage?.file?.url" class="menu-item-icon bg-tonedwhite"></span>
        <img *ngIf="subChild?.navigationImage?.file?.url" class="menu-item-icon position-absolute"
          [defaultImage]="defaultImage" width="24" height="24" lazyLoad="{{subChild?.navigationImage?.file?.url}}"
          alt="{{subChild?.navigationImage?.title}}">
        {{subChild?.navigationLabel}}
      </a>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="otherHeaderDetails && otherHeaderDetails['menucount'] == 'greybox'">
  <div *ngIf="childMenu?.childNavigation?.length && otherHeaderDetails['hubpageType'] === 'IELTS community'" class="testimonial-wrapper px-48 menu-grey-box px-48-901-1024 d-flex flex-column gap20 py-24-900 px-20-900">
    <a [routerLink]="null" title="{{childMenu?.navigationLabel}}" class="menu-title">{{childMenu?.navigationLabel}}</a>
    <div class="testimonial-pod rounded spacer py-24 px-16 bg-white-force">
      <div class="d-flex flex-column gap12 pb-24">
        <img lazyLoad="{{assetPath}}/assets/home/icons/quote.svg" [defaultImage]="defaultImage" alt="testimonial" width="40" height="28">
        <div id="testimonial" class="result-para clr-primary font-opensans line-clamp" [title]="testmonialTooltip" [innerHtml]="childMenu?.childNavigation[0]?.testimonial"></div>
      </div>
      <div class="testimonial-detail-pod d-flex align-items-start gap16">
        <img width="50" height="50" [defaultImage]="defaultImage" lazyLoad="{{assetPath}}/assets/home/icons/avatar.svg" alt="avatar">
        <div class="d-flex flex-column gap4">
          <h5 class="heading5 clr-black">{{childMenu?.childNavigation[0]?.author?.firstName}} {{childMenu?.childNavigation[0]?.author?.lastName}}</h5>
          <span class="testimonial-date clr-black font-opensans">{{childMenu?.childNavigation[0]?.createdOn | date: 'dd/MM/yyyy'}}</span>
        </div>
      </div>
    </div>
    <a *ngIf="childMenu?.navigationUrl" appDynamicUrl="{{childMenu?.navigationUrl}}" [routerLink]="childMenu?.navigationUrl?.includes('://') ? null : countryUrl + childMenu?.navigationUrl"
      (click)="gaTrackDetails(otherHeaderDetails['parentTitle']?.toLowerCase().split(' ').join('_') + '_header', childMenu?.gaID, 'view_more_testimonials', otherHeaderDetails['parentIndex'])"
      class="testimonial-btn button button-outline-grey font-semi-bold clr-primary w-100 rounded-full d-flex align-items-center justify-content-center py-8 px-72 text-center">{{headerConstText?.viewMoreTestimonial}}</a>
  </div>

  <div *ngIf="childMenu && otherHeaderDetails['hubpageType'] !== 'IELTS community' && otherHeaderDetails['hubpageType'] !== 'About'" class="menu-item-wrapper px-48 menu-grey-box px-48-901-1024"> 
    <div class="menu-items w-100 d-flex flex-column">
      <a *ngIf="childMenu?.navigationUrl" appDynamicUrl="{{childMenu?.navigationUrl}}"
        [routerLink]="childMenu?.navigationUrl?.includes('://') ? null : countryUrl + childMenu?.navigationUrl"
        (click)="gaTrackDetails(otherHeaderDetails['parentTitle']?.toLowerCase().split(' ').join('_') + '_header', childMenu.gaID, childMenu.gaID, otherHeaderDetails['parentIndex'])"
        [target]="childMenu?.target == 'Open in same tab' ? '_self' : '_blank'" title="{{childMenu?.navigationLabel}}"
        class="menu-title">{{childMenu?.navigationLabel}}
      </a>
      <a *ngIf="!childMenu?.navigationUrl" title="{{childMenu?.navigationLabel}}"
        class="menu-title">{{childMenu?.navigationLabel}}
      </a>
      <a *ngFor="let subChild of childMenu.childNavigation" appDynamicUrl="{{subChild?.navigationUrl}}"
        [routerLink]="subChild?.navigationUrl?.includes('://') ? null : countryUrl + subChild?.navigationUrl"
        title="{{subChild?.navigationLabel}}" [target]="subChild?.target == 'Open in same tab' ? '_self' : '_blank'"
        (click)="gaTrackDetails(otherHeaderDetails['parentTitle']?.toLowerCase().split(' ').join('_') + '_header', childMenu?.gaID, subChild?.gaID, otherHeaderDetails['parentIndex'])"
         class="menu-item-link d-flex align-items-center gap12 position-relative">
        <img *ngIf="subChild?.navigationImage?.file?.url" class="menu-item-icons"
        [defaultImage]="defaultImage" width="24" height="24" lazyLoad="{{subChild?.navigationImage?.file?.url}}"
        alt="{{subChild?.navigationImage?.title}}">
        {{subChild?.navigationLabel}}
      </a>
    </div>
  </div>
  <!-- about  -->
  <div class="px-48 about-news-wrapper menu-grey-box d-flex flex-column gap16 px-20-900 py-24-900" *ngIf="childMenu && otherHeaderDetails['hubpageType'] === 'About'">
    <a [routerLink]="null" class="menu-title mb-4 clr-black" title="{{childMenu?.navigationLabel}}">{{childMenu?.navigationLabel}}</a>
    <div class="about-news d-flex flex-column gap16">
      <a class="about-news-pod align-items-center d-flex gap16 spacer bg-white-force rounded p-12 justify-content-between" *ngFor="let subChild of childMenu?.childNavigation"
      appDynamicUrl="{{subChild?.navigationUrl}}" [routerLink]="subChild?.navigationUrl?.includes('://') ? null : countryUrl + subChild?.navigationUrl"
      (click)="gaTrackDetails(otherHeaderDetails['parentTitle']?.toLowerCase().split(' ').join('_') + '_header', childMenu?.gaID, subChild?.gaID, otherHeaderDetails['parentIndex'])">
        <div class="d-flex gap16 align-items-center">
          <img class="rounded" width="48" height="48" alt="People" src="{{subChild?.navigationImage?.file?.url}}">
          <div class="d-flex flex-column gap2">
            <h5 class="heading5 clr-black font-semi-bold">{{subChild?.navigationLabel}}</h5>
            <p class="about-news-para clr-grey font-opensans line-clamp clamp-1">{{subChild?.shortDescription}}</p>
          </div>
        </div>
        <span class="mt-5-1024 chevron-arrow arrow-md right"></span>
      </a>
    </div>
    <a *ngIf="childMenu?.navigationUrl" class="item-link" appDynamicUrl="{{childMenu?.navigationUrl}}" [routerLink]="childMenu?.navigationUrl?.includes('://') ? null : countryUrl + childMenu?.navigationUrl"
      (click)="gaTrackDetails(otherHeaderDetails['parentTitle']?.toLowerCase().split(' ').join('_') + '_header', childMenu?.gaID, 'read_more', otherHeaderDetails['parentIndex'])">
      <span>{{headerConstText?.readMoreNews}}</span>
      <span class="chevron-arrow arrow-sm bg-blue-force right"></span>
    </a>
  </div>
  <!-- about  ends-->
</ng-container>
<ng-container *ngIf="otherHeaderDetails && otherHeaderDetails['menucount'] == 'languageList'">
  <div class="language-wrapper">
    <div class="lang-items w-100 d-flex flex-column gap32">
      <a *ngFor="let lang of childMenu" title="{{lang.displayTitle}}" (click)="switchlang(lang)"
        class="menu-item-link d-flex gap8 justify-content-between" [ngClass]="{'active':lang.code==languageSelector}">
        <span>{{lang.displayTitle}}</span>
        <img class="hide" width="16" height="16" src="{{assetPath}}/assets/home/icons/tick.svg" alt="tick">
      </a>
    </div>
  </div>
</ng-container>