export class CTAction {
    public primaryCtaLabel: string
    public primaryCtaUrl: string
    public primaryCtaTarget: string
    public secondaryCtaLabel: string
    public secondaryCtaUrl: string
    public secondaryCtaTarget: string
    primaryGaId?: String
    secondaryGaId?: string
    constructor (
        primaryCtaLabel: string,
        primaryCtaUrl: string,
        primaryCtaTarget: string,
        secondaryCtaLabel: string,
        secondaryCtaUrl: string,
        secondaryCtaTarget: string,
        primaryGaId?: string,
        secondaryGaId?: string

    ) {
        this.primaryCtaLabel = primaryCtaLabel
        this.primaryCtaUrl = primaryCtaUrl
        this.primaryCtaTarget = primaryCtaTarget
        this.secondaryCtaLabel = secondaryCtaLabel
        this.secondaryCtaUrl = secondaryCtaUrl
        this.secondaryCtaTarget = secondaryCtaTarget
        this.primaryGaId = primaryGaId
        this.secondaryGaId = secondaryGaId
    }
}
