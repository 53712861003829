import { Directive, ElementRef, Input, Renderer2 } from '@angular/core'
import { Router } from '@angular/router'

@Directive({
  standalone: true,
  selector: '[appDynamicUrl]'
})
export class DynamicUrlDirective {
  @Input('appDynamicUrl') url: string

  constructor (private readonly elementRef: ElementRef, private readonly renderer: Renderer2, private router: Router) {
    this.router.events.subscribe(() => {
      this.hrefAddition()
    })
   }

  ngAfterViewInit (): void {
    this.hrefAddition()
  }

  hrefAddition (): void {
    const element = this.elementRef?.nativeElement
    if (element && typeof element?.hasAttribute === 'function') {
      if (this.url?.includes('://')) {
        this.renderer.setAttribute(element, 'href', this.url)
      }
    }
  }
}
