import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { UTILITIES } from '../common/utilities/ielts-util'
import { Observable } from 'rxjs/internal/Observable'
@Injectable({
  providedIn: 'root'
})
export class HttpServiceService {
  constructor (private readonly http: HttpClient,
              private readonly UTILITIES: UTILITIES) { }
  get (pageName, queryparams): Observable<any> {
    const headers = new HttpHeaders({
      'x-api-key': this.UTILITIES.xApiKey
    })
    const url = queryparams?.includes('preview=true') ? this.UTILITIES[pageName] + '/preview' : this.UTILITIES[pageName]
    return this.http.get(url + queryparams, { headers })
  }

  post (endPoint, query): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-api-key': this.UTILITIES.xApiKey
    })
    return this.http.post<any>(this.UTILITIES[endPoint], query, { headers })
  }
}
