import { CommonModule } from '@angular/common'
import { Component, Input } from '@angular/core'
import { LoaderService } from '../../services/loader.service'
import { Subject } from 'rxjs/internal/Subject'

@Component({
  standalone: true,
  imports: [CommonModule],
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.css']
})
export class LoaderComponent {
  @Input() isContentLoading
  @Input() isdropdownLoading
  @Input() loaderType
  isLoading: Subject<boolean> = this.loaderService.isLoading
  constructor (private readonly loaderService: LoaderService) { }
  ngOnInit (): void { }
}
