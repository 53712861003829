import { Injectable, Inject, PLATFORM_ID, StateKey, makeStateKey, TransferState } from '@angular/core'
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http'
import { tap } from 'rxjs/operators'
import { isPlatformServer } from '@angular/common'
import { Observable } from 'rxjs/internal/Observable'
import { of } from 'rxjs/internal/observable/of'

@Injectable({
  providedIn: 'root'
})
export class TransferHttpInterceptorService implements HttpInterceptor {
  enableCsr = 'No'
  constructor (
    private readonly transferState: TransferState,
    @Inject(PLATFORM_ID) private readonly platformId: any
    ) {
  }

  public intercept (request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!request.url?.includes('v1/externalapi/ro-list') && request.method == 'POST') {
      return next.handle(request)
    }

    let customKey = ''
    if (request.method !== 'GET') {
      if (typeof request.body === 'string') {
        customKey = request.body
      } else {
        customKey = JSON.stringify(request.body)
      }
    } else {
      customKey = request.url
    }

    const key: StateKey<string> = makeStateKey<string>(customKey)
    if (isPlatformServer(this.platformId)) {
      return next.handle(request).pipe(tap((event) => {
        this.transferState.set(key, (<HttpResponse<any>>event).body)
      }))
    } else {
      const storedResponse = this.transferState.get<any>(key, null)
      if (storedResponse) {
        const response = new HttpResponse({ body: storedResponse, status: 200 })
        this.transferState.remove(key)
        return of(response)
      } else {
        return next.handle(request)
      }
    }
  }
}
