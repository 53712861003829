export class Iframe {
  public iFrameURL: string
  public height: number
  public tabletHeight: number
  public mobileHeight: number
  public name: string
  public desktopContainer: any
  public desktopIframe: string
  public mobileContainer: string
  public mobileIframe: string

  constructor (
    iFrameURL: string,
    height: number,
    tabletHeight: number,
    mobileHeight: number,
    name: string,
    desktopContainer: any,
    desktopIframe: string,
    mobileContainer: string,
    mobileIframe: string
  ) {
    this.iFrameURL = iFrameURL || ''
    this.height = height
    this.tabletHeight = tabletHeight
    this.mobileHeight = mobileHeight
    this.name = name || ''
    this.desktopContainer = desktopContainer
    this.desktopIframe = desktopIframe || ''
    this.mobileContainer = mobileContainer || ''
    this.mobileIframe = mobileIframe || ''
  }
}
