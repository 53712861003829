import { Injectable, Inject, PLATFORM_ID } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { DatePipe, isPlatformBrowser } from '@angular/common'
import { UTILITIES } from '../common/utilities/ielts-util'
import { UserTrack } from '../common/models/clickStream-info'
import { Observable } from 'rxjs/internal/Observable'
import { ContentfulService } from './contentful.service'
import { Router } from '@angular/router'
import { CookieService } from 'ngx-cookie'
import { CookieBackendService } from './cookie-backend.service'

@Injectable({
  providedIn: 'root'
})

export class ClickStreamService {
  public countryName
  public deviceDetectorService
  constructor (
    private readonly UTILITIES: UTILITIES,
    private readonly http: HttpClient,
    @Inject(PLATFORM_ID) private readonly platformId: Object,
    private _CookieServiceService: CookieBackendService,
    private readonly contentfulService: ContentfulService,
    private readonly router: Router,
    private readonly datePipe: DatePipe
  ) {
    if (isPlatformBrowser(this.platformId)) {
      this.countryName = this._CookieServiceService?.get('country') !== 'global' ? ' ' + this._CookieServiceService?.get('country') : ''
    }
    if (this.contentfulService.checkIndiawebsite()) {
      this.countryName = ' india'
    }
  }

  async clickstreamLogging (eventDetails) {
    if (this.contentfulService.platformCheck()) {
      let clickStreamPayLoad = {}
      if (!this.deviceDetectorService) {
        const deviceDetectorPackage = await import('ngx-device-detector')
        this.deviceDetectorService = new deviceDetectorPackage.DeviceDetectorService(this.platformId)
      }
      const userAndDeviceData = new UserTrack(
        'IDP IELTS' + (this.countryName ? this.countryName?.toUpperCase() : ''),
        this.contentfulService.siteLanguage !== '' ? this.contentfulService.getLanguage(this.contentfulService.siteLanguage) : '',
        this.contentfulService.domainName + this.router.url,
        window.innerWidth,
        this.setOrGetSessionCookie() || '',
        this.deviceDetectorService.isMobile(this.deviceDetectorService.userAgent) ? 'Y' : 'N',
        window.navigator.userAgent,
        this._CookieServiceService.get('clientIp') ? this._CookieServiceService.get('clientIp') : '',
        this.deviceDetectorService.isMobile() ? this.deviceDetectorService.getDeviceInfo().device : 'NA',
        this.deviceDetectorService.isMobile() ? this.deviceDetectorService.getDeviceInfo().os : 'NA',
        sessionStorage.getItem('cognito_uuid') ? 'Y' : 'N',
        this.datePipe.transform(new Date(), 'dd-MM-yyyy HH:mm:ss.SS'),
        'na'
      )
      clickStreamPayLoad = { ...userAndDeviceData, ...eventDetails }
      clickStreamPayLoad = this.removeEmptyOrNull(clickStreamPayLoad)
      this.clickstreamDetailsApi(clickStreamPayLoad).subscribe()
   }
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  clickstreamDetailsApi (ielts_json): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-api-key': this.UTILITIES.clickstreamApiKey
    })
    const query = {
      DeliveryStreamName: 'ielts_analytics_clickstream_firehose_stream',
      Record: {
        Data: {
          eventMessage: {
            ielts_json,
            systemname: 'IELTS_Desktop_Sites'
          }
        }
      }
    }
    return this.http.post<any>(this.UTILITIES.clickstreamUrl, JSON.stringify(query), { headers })
  }

  removeEmptyOrNull (obj): any {
    const keys: any = Object.keys(obj)
    keys.filter(v => {
      if (obj[v] === ' ' || obj[v] === undefined || obj[v].length === 0) {
        delete obj[v]
      }
    })
    return obj
  }

  setOrGetSessionCookie (): any {
    let sessionID
    if (isPlatformBrowser(this.platformId)) {
      if (this._CookieServiceService.get('sessionID')) {
        sessionID = this._CookieServiceService.get('sessionID')
      } else {
        sessionID = this.contentfulService.uuidv4(16)
        const dateNow = new Date()
        dateNow.setDate(dateNow.getDate() + 30)
        this._CookieServiceService.setWithExpiry('sessionID', sessionID, dateNow)
      }
    }
    return sessionID
  }
}
