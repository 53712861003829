import { ErrorHandler, NgModule } from '@angular/core'
import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { BrowserModule, provideClientHydration } from '@angular/platform-browser'
import { CommonModule, DatePipe } from '@angular/common'
import { NotFoundComponent } from './components/not-found/not-found.component'
import { HeaderComponent } from './components/header/header.component'
import { SearchPopupComponent } from './components/search-popup/search-popup.component'
import { FooterComponent } from './components/footer/footer.component'
import { TickerTapeComponent } from './components/ticker-tape/ticker-tape.component'
import { SeoService } from './services/seo.service.component'
import 'zone.js'
import { HeaderMegamenuComponent } from './components/header-megamenu/header-megamenu.component'
import { TransferHttpInterceptorService } from './services/transfer-http-interceptor-service'
import { HTTP_INTERCEPTORS } from '@angular/common/http'
import { LoaderComponent } from './components/loader/loader.component'
import { AppGlobalHttpInterceptor } from './services/app-global-http-interceptor.service'
import { DynamicUrlDirective } from './common/directives/dynamic-url.directive'
import { PrepseoService } from './prep/providers/prep-seo.service.component'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { CookieModule, CookieService } from 'ngx-cookie'
import { DefaultUrlSerializer, UrlSerializer, UrlTree } from '@angular/router'
import { CookieBackendService } from './services/cookie-backend.service'
import { LazyLoadImageModule } from 'ng-lazyload-image'
import { PrepHeaderComponent } from './prepare/components/prep-header/prep-header.component'

export class LowerCaseUrlSerializer extends DefaultUrlSerializer {
  parse (url: string): UrlTree {
    return super.parse(url.toLowerCase())
  }
}
@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    HeaderMegamenuComponent,
    SearchPopupComponent,
    FooterComponent,
    TickerTapeComponent
  ],
  imports: [
    BrowserModule,
    CommonModule,
    LazyLoadImageModule,
    AppRoutingModule,
    NotFoundComponent,
    PrepHeaderComponent,
    LoaderComponent,
    DynamicUrlDirective,
    BrowserAnimationsModule,
    CookieModule.withOptions()
  ],
  providers: [
    provideClientHydration(),
    SeoService,
    PrepseoService,
    DatePipe,
    CookieService,
    CookieBackendService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TransferHttpInterceptorService,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AppGlobalHttpInterceptor,
      multi: true
    },
    {
      provide: UrlSerializer,
      useClass: LowerCaseUrlSerializer
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
