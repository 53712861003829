export class HeaderDetails {
  public bookatest: string
  public bookatestURL: string
  public bookatestTarget: string
  public countryName: string
  public headerMenu: object[]
  public headerSocialNav: object[]

  constructor (
    bookatest: string,
    bookatestURL: string,
    bookatestTarget: string,
    countryName: string = '',
    headerMenu: object[] = [],
    headerSocialNav: object[] = []
  ) {
    this.bookatest = bookatest
    this.bookatestURL = bookatestURL
    this.bookatestTarget = bookatestTarget
    this.countryName = countryName
    this.headerMenu = headerMenu
    this.headerSocialNav = headerSocialNav
  }
}

export class IndiaLoginDetails {
  public name: string
  public loginType: string
  public redirectUrl: string
  public logoutUrl: string
  public nameFirstLetter: string
  constructor (
    name: string,
    loginType: string,
    redirectUrl: string,
    logoutUrl: string,
    nameFirstLetter: string
  ) {
    this.name = name
    this.loginType = loginType
    this.redirectUrl = redirectUrl
    this.logoutUrl = logoutUrl
    this.nameFirstLetter = nameFirstLetter
  }
}
