import { DOCUMENT, isPlatformBrowser, Location } from '@angular/common'
import { Inject, Injectable, PLATFORM_ID, Optional, Renderer2, RendererFactory2 } from '@angular/core'
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router'
import { RESPONSE } from '@nguniversal/express-engine/tokens'
import { Response } from 'express'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { UTILITIES } from '../common/utilities/ielts-util'
import { Observable } from 'rxjs/internal/Observable'
import { LoaderService } from '../services/loader.service'
import { CookieBackendService } from '../services/cookie-backend.service'
import { ContentfulService } from '../services/contentful.service'
import { RoutingInitService } from '../services/routing-init.service'
@Injectable({
  providedIn: 'root'
})
export class UserLocationGuard {
  private readonly response: Response
  private renderer: Renderer2
  private params
  constructor (
    private cookieService: CookieBackendService,
    private readonly http: HttpClient,
    private readonly UTILITIES: UTILITIES,
    private rendererFactory: RendererFactory2,
    @Optional() @Inject(RESPONSE) response: any,
    @Inject(DOCUMENT) private readonly document: Document,
    @Inject(PLATFORM_ID) public platformId: Object,
    private readonly loaderService: LoaderService, private routingInitService: RoutingInitService,
    private readonly location: Location, private contentFulService: ContentfulService) {
      this.response = response
      this.renderer = rendererFactory.createRenderer(null, null)
  }

  canActivate (next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean | UrlTree {
    return new Promise((resolve, reject) => {
      const referrerUrl = isPlatformBrowser(this.platformId) ? this.document.referrer : ''
      const headers = new HttpHeaders({ 'x-api-key': this.UTILITIES.xApiKey })
      const clientIp = this.cookieService.get('clientIp')
      const currentWebsiteCountryCode = this.cookieService.getClientCountryCode()
      const currentWebsite = this.routingInitService.websiteCountryCode[currentWebsiteCountryCode]
      this.params = this.location.path().split('?')[1]

      if (this.redirectByClientCountry(currentWebsite, referrerUrl)) {
        this.performRedirect(currentWebsite.website, this.params)
        return
      }

      if (this.redirectByClientIp(referrerUrl, clientIp)) {
        this.getCountryDetails(clientIp, headers).then((country: any) => {
          if (country !== 'global') {
            this.performRedirect(country, this.params)
          } else {
            this.finalizeResolve(resolve)
          }
        }).catch(() => this.finalizeResolve(resolve))
        return
      }
      this.finalizeResolve(resolve)
    })
  }

  private redirectByClientCountry (currentWebsite: any, referrerUrl: string): boolean {
    return (currentWebsite?.enableCloudfrontGeoRedirection && currentWebsite?.live === 'Yes' && referrerUrl === '') ||
     (currentWebsite?.enableOrganicGeoRedirection && currentWebsite?.live === 'Yes')
  }

  private redirectByClientIp (referrerUrl: string, clientIp: string): boolean {
    return referrerUrl === '' && clientIp && !this.contentFulService.checkIndiawebsite()
  }

  private performRedirect (url: string, params: string): void {
    if (!isPlatformBrowser(this.platformId)) {
      this.response.redirect(301, `/${url}`)
    } else {
      const redirectUrl = params ? `/${url}?${params}` : `/${url}`
      window.location.href = redirectUrl
    }
  }

  private async getCountryDetails (clientIp: string, headers: HttpHeaders): Promise<string> {
    const options = { headers }
    const response = await this.http.get(this.UTILITIES.userIp_URL + '?userIp=' + clientIp, options).toPromise()
    return response['user']?.country?.toLowerCase() || ''
  }

  private finalizeResolve (resolve: Function): void {
    this.removeClass()
    resolve(true)
  }

  removeClass () {
    if (isPlatformBrowser(this.platformId)) {
      this.loaderService.hide()
      this.renderer.removeClass(this.document.body, 'showSpinner')
    }
  }
}
