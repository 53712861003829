export class UserTrack {
  public siteName: string
  public siteLanguage: string
  public refererURL: string
  public screenResolution: number
  public sessionTrackId: any
  public isMobileUser: string
  public userAgent: string
  public userIPAddress: string
  public mobileDeviceModel: string
  public mobileDeviceOS: string
  public userloggedIn: string
  public eventTime: string
  public userId: string
  constructor (
    siteName: string,
    siteLanguage: string,
    refererURL: string,
    screenResolution: number,
    sessionTrackId: any,
    isMobileUser: string,
    userAgent: string,
    userIPAddress: string,
    mobileDeviceModel: string,
    mobileDeviceOS: string,
    userloggedIn: string,
    eventTime: string,
    userId: string
  ) {
    this.siteName = siteName
    this.siteLanguage = siteLanguage
    this.refererURL = refererURL
    this.screenResolution = screenResolution
    this.sessionTrackId = sessionTrackId
    this.isMobileUser = isMobileUser
    this.userAgent = userAgent
    this.userIPAddress = userIPAddress
    this.mobileDeviceModel = mobileDeviceModel
    this.mobileDeviceOS = mobileDeviceOS
    this.userloggedIn = userloggedIn
    this.eventTime = eventTime
    this.userId = userId
  }
}
export class ClickStreamEventData {
  pageName: string
  requestURL: string
  eventType: string
  actionType: string
  sectionName: string
  functionalityName: string
  ctaTitle: string
  sourcePageName: string
  constructor (
    pageName: string,
    requestURL: string,
    eventType: string,
    actionType: string,
    sectionName: string,
    functionalityName: string,
    ctaTitle: string,
    sourcePageName: string
  ) {
    this.pageName = pageName
    this.requestURL = requestURL
    this.eventType = eventType
    this.actionType = actionType
    this.sectionName = sectionName
    this.functionalityName = functionalityName
    this.ctaTitle = ctaTitle
    this.sourcePageName = sourcePageName
  }
}

export class PageData {
  pageName: string
  requestURL: string
  refererURL: string
  constructor (
    pageName: string = '',
    requestURL: string = '', // Url that we are going to hit
    refererURL: string = '' // Url from where we are gonna hit
  ) {
    this.pageName = pageName
    this.requestURL = requestURL
    this.refererURL = refererURL
  }
}
