// @ts-nocheck
import { CookieService } from 'ngx-cookie'
import { Inject, Injectable, PLATFORM_ID, Optional } from '@angular/core'
import { REQUEST, RESPONSE } from '@nguniversal/express-engine/tokens'
import { Response } from 'express'
import { isPlatformServer } from '@angular/common'
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject'
@Injectable({
  providedIn: 'root'
})
export class CookieBackendService {
  public response: Response
  constructor (
    @Optional() @Inject(REQUEST) public request: any,
    @Optional() @Inject(RESPONSE) public response: any,
    private cookieService: CookieService,
    @Inject(PLATFORM_ID) private readonly platformId: Object) {
    this.response = response
  }

  get (name): string {
    if (isPlatformServer(this.platformId)) {
      const existingCookie = this.getALL() || {}
       return name in existingCookie ? existingCookie[name] : ''
    } else {
      return this.cookieService.get(name)
    }
  }

  getALL (): string {
    if (isPlatformServer(this.platformId)) {
      const cookies = this.request.headers.cookie
      if (cookies) {
        return this.parseCookieString(cookies) || {}
     }
    } else {
      return this.cookieService.getAll()
    }
 }

  protected set cookieString (val: string) {
    if (isPlatformServer(this.platformId)) {
      this.request.cookie = val
      this.response.cookie = val
    }
  }

  delete (name, path?) {
    if (isPlatformServer(this.platformId)) {
       this.remove(name)
    } else {
      this.cookieService.remove(name)
    }
  }

  parseCookieString (cookieString) {
    const cookies = {}
    const cookieParts = cookieString.split(';')
    for (const part of cookieParts) {
      const [key, value] = part.trim().split('=')
      const decodedKey = decodeURIComponent(key)
      const decodedValue = decodeURIComponent(value || '')
      cookies[decodedKey] = decodedValue
    }
    return cookies
  }

  set (key: string, value: string): void {
    if (isPlatformServer(this.platformId)) {
      let findKey = false
      const existingCookie = this.getALL() || {}
      let newCookie = Object.keys(existingCookie)
        // tslint:disable-next-line: no-shadowed-variable
        .map((keyItem) => {
          if (keyItem === key) {
            findKey = true
            return `${key}=${value}`
          }
          return `${keyItem}=${this.get(keyItem)}`
        })
        .join('; ')
      if (!findKey) {
        newCookie += `; ${key}=${value};`
      }
      this.request.headers.cookie = this.request.headers.cookie + newCookie
      this.cookieString = newCookie
    } else {
      this.cookieService.put(key, value)
    }
  }

  setWithExpiry (key, value, expiry) {
    this.cookieService.put(key, value, { expires: expiry })
  }

  remove (key: string): void {
    if (isPlatformServer(this.platformId)) {
      const existingCookie = this.getALL() || {}
      const newCookie = Object.keys(existingCookie)
        // tslint:disable-next-line: no-shadowed-variable
        .map((keyItem) => {
          if (keyItem === key) {
            return ''
          }
          return `${keyItem}=${this.get(keyItem)}`
        })
        .join('; ')
      this.request.headers.cookie = newCookie
      // not sure
      this.cookieString = newCookie
    }
  }

  getClientCountryCode (): any {
    if (isPlatformServer(this.platformId)) {
      return this.request.headers['cloudfront-viewer-country'] || ''
    } else {
      return this.get('Client_Country_Code') || ''
    }
  }
}
