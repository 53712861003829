import { Component, Input, OnInit } from '@angular/core'
import { ImageConstant } from '../../../common/utilities/constants'
import { CommonModule } from '@angular/common'
import { RouterModule } from '@angular/router'
import { ContentfulService } from '../../../services/contentful.service'

@Component({
  standalone: true,
  imports: [CommonModule, RouterModule],
  selector: 'app-prep-header',
  templateUrl: './prep-header.component.html',
  styleUrls: ['./prep-header.component.css']
})
export class PrepHeaderComponent implements OnInit {
  assetPath = ImageConstant + '/IELTS_Website_Rebrand'
  bookNowDisable: boolean
  prepBookNow = ''
  @Input() headerData
  constructor (private contentFulService: ContentfulService) {}
  ngOnInit (): void {
    this.prepBookNow = this.contentFulService.getBookingUrl(this.headerData?.bookatestURL)
  }
}
