/* eslint-disable @typescript-eslint/no-unused-vars */
import { DOCUMENT, Location, isPlatformBrowser } from '@angular/common'
import { Inject, Injectable, Optional, PLATFORM_ID, Renderer2, RendererFactory2 } from '@angular/core'
import { ImageInfo } from '../common/models/image-info'
import { CTAction } from '../common/models/call-to-action'
import { RoutingInitService } from './routing-init.service'
import { SeoService } from './seo.service.component'
import { HttpServiceService } from './http-service.service'
import { HrefLang } from '../common/models/hreflang-Info'
import { UTILITIES } from '../common/utilities/ielts-util'
import { FeaturedImage } from '../common/models/featured-image'
import { Iframe } from '../common/models/iframe'
import { REQUEST, RESPONSE } from '@nguniversal/express-engine/tokens'
import { Response } from 'express'
import { Router } from '@angular/router'
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject'
import { DomSanitizer } from '@angular/platform-browser'
import { TeaserContent } from '../common/models/teaser-content'
import { RelatedArticle } from '../common/models/relatedArticle-info'
import { VideoInfo } from '../common/models/video-info'
import { LoaderService } from './loader.service'
import { ParagraphInfo } from '../common/models/paragraph-info'
import { CookieBackendService } from './cookie-backend.service'
import formatISODuration from 'date-fns/formatISODuration'
import { indiaDomain } from '../common/utilities/constants'
import { take } from 'rxjs/internal/operators/take'
declare global {
  interface Window { dataLayer: any[] }
}
@Injectable({
  providedIn: 'root'
})
export class ContentfulService {
  public domainName
  public roCountryList
  public response: Response
  public siteLanguage = ''
  public languageList = new BehaviorSubject([])
  public currentLang = new BehaviorSubject([String, String])
  public pageNotFound = new BehaviorSubject(false)
  public quizClose = new BehaviorSubject(false)
  public searchKeyValue = new BehaviorSubject('')
  public localArticle = new BehaviorSubject([])
  public sessionMaintenance = new BehaviorSubject(false)
  public translatedData: any
  public selectToogle
  public prevPageName
  public customDimensionValue = {}
  private readonly renderer: Renderer2
  public cssStyles = { ar: { align: 'right' }, 'th-TH': { align: 'left' }, 'zh-Hant': { align: 'left' }, 'zh-Hans': { align: 'left' }, 'tr-TR': { align: 'left' }, 'fa-IR': { align: 'right' }, 'zh-Hant-TW': { align: 'left' }, 'vi-VN': { align: 'left' }, ja: { align: 'left' }, ko: { align: 'left' }, 'ur-PK': { align: 'right' }, ms: { align: 'left' }, km: { align: 'left' }, uz: { align: 'left' }, ru: { align: 'left' } }
  searchKey = ''
  specialChar: boolean
  headerlanguage = 'en-GB'
  public formalCountryname = new BehaviorSubject('')
  public articleTag = new BehaviorSubject({})
  public disableBooknow = new BehaviorSubject(true)
  smartBannerDetails = new BehaviorSubject({})
  public filterSelectedData = new BehaviorSubject({})
  public customDimensionStatus = new BehaviorSubject(false)
  public metaTagsAdded
  constructor (@Inject(PLATFORM_ID) public platformId: Object,
    private readonly routingInitService: RoutingInitService,
    private readonly location: Location,
    private _CookieServiceService: CookieBackendService,
    private readonly _SeoService: SeoService,
    private readonly httpService: HttpServiceService,
    private readonly UTILITIES: UTILITIES,
    @Optional() @Inject(RESPONSE) response: any,
    @Optional() @Inject(REQUEST) public request: any,
    @Inject(DOCUMENT) private readonly document: any,
    private readonly rendererFactory: RendererFactory2,
    public readonly router: Router,
    public sanitizer: DomSanitizer,
    private readonly loaderService: LoaderService) {
    this.response = response
    this.renderer = this.rendererFactory.createRenderer(null, null)
  }
  setImgType () {
    let imageType = '?&fm=avif'
    if (isPlatformBrowser(this.platformId)) {
      const uA = navigator.userAgent
      const vendor = navigator.vendor
      const isMobile = /iPhone|iPad/i.test(navigator.userAgent)
      const isSafari = /Safari/i.test(uA) && vendor?.includes('Apple Computer') && !/Mobi|Android/i.test(uA)
      switch (!isSafari || !isMobile) {
        case /edg/i.test(uA): imageType = '?&fm=webp'; break
        case /chrome/i.test(uA): imageType = '?&fm=avif'; break
        case /firefox/i.test(uA): imageType = '?&fm=avif'; break
        default: imageType = '?'; break
      }
      return imageType
    } else {
      return imageType
    }
  }

  setCookie (name, value) {
    this._CookieServiceService.set(name, value)
  }

  getImageDetails (featuredImage, altText?, callToAction?): any {
      return new ImageInfo(
        '',
        altText,
        featuredImage?.media ? featuredImage?.media?.file?.url : featuredImage?.file ? featuredImage?.file?.url : featuredImage?.url,
        featuredImage?.media ? featuredImage?.media?.file?.details?.image?.width : featuredImage?.file ? featuredImage?.file?.details?.image?.width : featuredImage?.width,
        featuredImage?.media ? featuredImage?.media?.file?.details?.image?.height : featuredImage?.file ? featuredImage?.file?.details?.image?.height : featuredImage?.height,
        callToAction
      )
  }

  getPrepImageDetails (featuredImage, altText?): any {
    return new ImageInfo(
      '',
      altText || featuredImage?.title,
      featuredImage?.url ? featuredImage?.url : featuredImage?.media?.url || '',
      featuredImage?.width ? featuredImage?.width : '',
      featuredImage?.height ? featuredImage?.height : '',
      []
    )
}

  getVideoDetails (videoData): any {
    return new VideoInfo(
      videoData?.thumbnail?.title,
      videoData?.thumbnail?.file?.url,
      videoData?.thumbnail?.file?.details?.image?.width,
      videoData?.thumbnail?.file?.details?.image?.height,
      videoData?.media?.file?.url || videoData?.media?.url || '',
      videoData?.youtubeUrl,
      videoData?.duration
    )
  }

  videoSchema (detail) {
    if (!isPlatformBrowser(this.platformId)) {
      let videoduration = detail?.duration
      // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
      if (detail?.duration !== undefined && detail?.duration?.includes('mins')) {
        videoduration = formatISODuration({ minutes: videoduration.replace('mins', '') })
        // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
      } else if (detail?.duration !== undefined && detail?.duration?.includes('hr+')) {
        videoduration = formatISODuration({ hours: videoduration.replace('hr+', '') })
        // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
      } else if (detail?.duration !== undefined && detail?.duration?.includes('s')) {
        videoduration = formatISODuration({ seconds: videoduration.replace('s', '') })
      }
      const scripts = this.document.createElement('script')
      scripts.type = 'application/ld+json'
      scripts.innerHTML = `{
      "@context": "https://schema.org",
      "@type": "VideoObject",
      "name": "${detail?.title}",
      "description": "${detail?.description || ''}",
      "thumbnailUrl": "${detail?.thumbnail?.file?.url}",
      "uploadDate": "${detail?.updatedAt || ''}",
      "duration": "${videoduration || ''}",
      "embedUrl": "${detail?.youtubeUrl ? detail?.youtubeUrl : detail?.media?.file?.url}"
     }`
      this.document.head.appendChild(scripts)
    }
  }

  faqSchema (faqArray) {
    if (!isPlatformBrowser(this.platformId)) {
      const script = this.document.createElement('script')
      script.type = 'application/ld+json'
      script.innerHTML = `{
      "@context": "https://schema.org",
      "@type": "FAQPage",
      "mainEntity": [
        ${faqArray?.map(data => `{
            "@type": "Question",
            "name": "${data?.title}",
            "acceptedAnswer": {
                "@type": "Answer",
                "text": "${data?.longDescription?.replace(/(\r\n|\n|\r)/gm, '')?.replace(/"/g, '\\"')}"
              }
          }`)}
        ]
      }`
      this.document.head.appendChild(script)
  }
}

 calltoActionDetails (callToAction) {
      if (callToAction && Object.keys(callToAction).length) {
        return new CTAction(
          callToAction?.primaryCtaLabel || '',
          this.formUtmUrl(callToAction?.primaryCtaUrl) || '',
          (callToAction?.primaryCtaTarget === 'Open in new tab') ? '_blank' : '_self',
          callToAction?.secondaryCtaLabel || '',
          this.formUtmUrl(callToAction?.secondaryCtaUrl) || '',
          (callToAction?.secondaryCtaTarget === 'Open in new tab') ? '_blank' : '_self',
          callToAction?.gaId || '',
          callToAction?.secondaryGaId || ''
        )
      }
  }

  isObjectEmpty = function (obj): any {
    return obj && !(Object.keys(obj)?.length)
  }

  getCurrentLangCode (countryName): any {
    let langCode = 'en-GB'
    const websiteInfo = this.routingInitService.getWebsiteInfo()
    const availableCountry = this.routingInitService.getcountry()

    let countryDetails = Object.keys(websiteInfo).length > 0 ? websiteInfo['global']?.defaultLanguage : []

    if (availableCountry?.indexOf(countryName) !== -1) {
      countryDetails = websiteInfo[countryName]?.defaultLanguage
      if (!this.isObjectEmpty(countryDetails)) {
        langCode = countryDetails?.code
      }
    }
    const cookieValue = 'currentLang'
    if (this._CookieServiceService.get(cookieValue) !== null && this._CookieServiceService.get(cookieValue) !== '' && this._CookieServiceService.get(cookieValue) !== 'null' && this._CookieServiceService.get(cookieValue) !== undefined) {
      langCode = this._CookieServiceService.get(cookieValue).replace(';', '')
    }
    const locationPath = this.location.path().split('?')[0].split('/').filter((val) => val !== '')
    if (this.isLanguageCode(locationPath)) {
      if (locationPath.length > 0) {
        let countryDetails = Object.keys(websiteInfo).length > 0 ? websiteInfo['global']?.LanguageList : []
        if (availableCountry.indexOf(locationPath[0]) !== -1) {
          countryDetails = (Object.keys(websiteInfo).length === 0 || Object.keys(websiteInfo).length > 0) ? websiteInfo[locationPath[0]]?.LanguageList : []
          countryDetails = (Object.keys(websiteInfo).length === 0 || Object.keys(websiteInfo).length > 0) ? websiteInfo[locationPath[0]]?.LanguageList : []
        }
        const segmentsLength = locationPath.length
        countryDetails.map((code) => {
          if (code.urlCode === locationPath[segmentsLength - 1]) {
            langCode = code.code
          }
        })
      }
    }
    this.siteLanguage = langCode
    return langCode
  }

  isLanguageCode (segments): any {
    const websiteInfo = this.routingInitService.getWebsiteInfo()
    const availableCountry = this.routingInitService.getcountry()
    if (segments?.length) {
      let countryDetails = Object.keys(websiteInfo).length > 0 ? websiteInfo['global']?.LanguageList : []
      if (availableCountry?.indexOf(segments[0]) !== -1) {
        countryDetails = websiteInfo ? websiteInfo[segments[0]]?.LanguageList : []
      }
      const countryCodeArray = countryDetails?.length ? countryDetails.map((code) => { return code.urlCode }) : []
      const codeIndex = countryCodeArray?.indexOf(segments[segments?.length - 1])
      if (codeIndex !== -1) {
        return true
      }
    }
  }

  platformCheck () {
    return isPlatformBrowser(this.platformId)
  }

  getContentDirection (countryName): any {
    const language = this.getCurrentLangCode(countryName)
    const codeIndex = Object.keys(this.cssStyles).indexOf(language)
    if (codeIndex !== -1) {
      return this.cssStyles[language].align
    } else {
      return 'left'
    }
  }

  getLanguageUrlCode (languageCode, countryName): any {
    const websiteInfo = this.routingInitService.getWebsiteInfo()
    const availableCountry = this.routingInitService.getcountry()
    if (languageCode) {
      let countryDetails = Object.keys(websiteInfo).length > 0 ? websiteInfo['global']?.LanguageList : []
      if (availableCountry.indexOf(countryName) !== -1) {
        if (Object.keys(websiteInfo).length === 0 || Object.keys(websiteInfo).length > 0) {
          countryDetails = countryDetails.concat(websiteInfo[countryName]?.LanguageList)
        }
      }
      countryDetails.shift()
      const countryCodeArray = countryDetails.filter((code) => { return code.code === languageCode })
      if (countryCodeArray.length > 0) {
        return countryCodeArray[0].urlCode
      } else {
        return ''
      }
    } else {
      return ''
    }
  }

  getHreflangDetails (contentType, urlSlug, staticHref?): any {
    try {
          let splitLinkTags
          const hferTagDetails = contentType?.map(href => {
            const hrefRobots = href?.robots
            if (href.website.contentType === 'website') {
              return this.gethrefDetails(href.website, hrefRobots)
            }
          })
          let countryCount = 0
          hferTagDetails.map(hrefCount => {
              countryCount++
              if (hrefCount?.secondaryLangauges?.length > 0) {
                countryCount++
              }
          })
          let showLink = true

          if (staticHref === undefined && staticHref?.trim() === '' && hferTagDetails.length < 2) {
            showLink = false
          }

          if (showLink) {
            if (staticHref?.trim() !== '') {
              splitLinkTags = staticHref?.split(',')
              splitLinkTags?.forEach(element => {
                if (element.length > 0) {
                  element = element.toString().replace('<p>', '')
                  element = element.toString().replace('</p>', '')
                  element = element.toString().replace('/n ', '').trim()
                  element = element.toString().replace(' /n', '').trim()
                  element = element.toString().replace(',/n ', '').trim()
                  element = element.toString().replace('<', '')
                  element = element.toString().replace('link', '')
                  element = element.toString().replace('/>', '')
                  element = element.toString().replace('>', '').trim()

                  const linkArray = element.split(/[\s=]+/)
                  if (linkArray.length % 2 !== 0) {
                    linkArray.pop()
                  }
                  const linkRel = {}
                  linkArray.map((key, value) => {
                    if (value % 2 === 0) {
                      linkRel[key] = linkArray[value + 1].replace(/['"]/g, '')
                    }
                  })
                  // if (countryCount > 1 || isMlp) {
                    this._SeoService.addHrefLang(linkRel)
                  // }
                }
              })
            }
            hferTagDetails.map(hreftagData => {
              const linkTagdata = hreftagData
              let replaceGlobalString
              if (linkTagdata.website === 'global' || linkTagdata.website === undefined) {
                replaceGlobalString = linkTagdata.website.toString().replace('global', '')
              } else {
                replaceGlobalString = '/' + linkTagdata.website
              }
              let isoCodename
              if (linkTagdata.isoCode === undefined || linkTagdata.isoCode === '' || linkTagdata.isoCode === null) {
                isoCodename = linkTagdata.langcode
              } else {
                isoCodename = linkTagdata.isoCode
              }

              isoCodename = this.hrefLangCountryCapitalization(isoCodename)
              let replaceURL
              if (urlSlug === '/home') {
                replaceURL = urlSlug.toString().replace('/home', '')
              } else {
                replaceURL = urlSlug
              }
              if (linkTagdata.domain !== undefined) {
                if ((!linkTagdata?.robots?.robots?.includes('noindex')) && countryCount > 1) {
                  this._SeoService.addHrefLang({ rel: 'alternate', href: 'https://' + hreftagData.domain + replaceGlobalString + replaceURL, hreflang: isoCodename })
                }
              } else if ((linkTagdata.domain === '' || linkTagdata.domain === undefined)) {
                if ((!linkTagdata?.robots?.robots?.includes('noindex')) && countryCount > 1) {
                  this._SeoService.addHrefLang({ rel: 'alternate', href: this.UTILITIES.domainUrl + replaceGlobalString + replaceURL, hreflang: isoCodename })
                }
              }

              if (linkTagdata.secondaryLangauges.length > 0) {
                linkTagdata.secondaryLangauges.map(hreftagsecondaryData => {
                  let replaceGlobalSecondaryString
                  if (hreftagsecondaryData.website === 'global' || hreftagsecondaryData.website === undefined) {
                    replaceGlobalSecondaryString = hreftagsecondaryData.website.toString().replace('global', '')
                  } else {
                    replaceGlobalSecondaryString = '/' + hreftagsecondaryData.website
                  }
                  let isoCodenameSecondary
                  if (hreftagsecondaryData.isoCode === undefined || hreftagsecondaryData.isoCode === '' || hreftagsecondaryData.isoCode === null) {
                    isoCodenameSecondary = hreftagsecondaryData.langcode
                  } else {
                    isoCodenameSecondary = hreftagsecondaryData.isoCode
                  }

                  isoCodenameSecondary = this.hrefLangCountryCapitalization(isoCodenameSecondary)

                  let replaceURLSecondary
                  if (urlSlug === '/home') {
                    replaceURLSecondary = urlSlug.toString().replace('/home', '')
                  } else {
                    replaceURLSecondary = urlSlug
                  }
                  if (hreftagsecondaryData.domain !== undefined) {
                    if ((!hreftagsecondaryData?.robots?.robots?.includes('noindex')) && hreftagsecondaryData.isLive === 'Yes' && countryCount > 1) {
                      this._SeoService.addHrefLang({ rel: 'alternate', href: 'https://' + hreftagsecondaryData.domain + replaceGlobalSecondaryString + replaceURLSecondary + '/' + hreftagsecondaryData.langcode, hreflang: isoCodenameSecondary })
                    }
                  } else if ((hreftagsecondaryData.domain === '' || hreftagsecondaryData.domain === undefined)) {
                    if ((!hreftagsecondaryData?.robots?.robots?.includes('noindex')) && hreftagsecondaryData.isLive === 'Yes' && countryCount > 1) {
                      this._SeoService.addHrefLang({ rel: 'alternate', href: this.UTILITIES.domainUrl + replaceGlobalSecondaryString + replaceURLSecondary + '/' + hreftagsecondaryData.langcode, hreflang: isoCodenameSecondary })
                    }
                  }
                })
              }
            })
          }
        } catch (error) {
          console.log(' — Error is handled gracefully: ', error)
        }
  }

  hrefLangDetails (contentType, URLParam) {
    const query = {
      query: '{fields.urlSlug=' + URLParam + ', content_type=' + contentType + ', select=fields.website,fields.urlSlug,fields.robots, fields.website.sys.contentType.sys.id=website, fields.website.fields.live=Yes, include=4}'
    }
   return this.httpService.post(this.UTILITIES.AWS_ENDPOINT_URL, query)
  }

  getLanguage (langCode): any {
    let tag
    switch (langCode) {
      case 'en-GB':
        tag = 'English'
        break
      case 'ar':
        tag = 'Arabic'
        break
      case 'tr-TR':
        tag = 'Turkish'
        break
      case 'fa-IR':
        tag = 'Farsi'
        break
      case 'th-TH':
        tag = 'Thai'
        break
      case 'km':
        tag = 'Khmer'
        break
      case 'zh-Hans':
        tag = 'Chinese'
        break
      case 'zh-Hant':
        tag = 'Traditional Chinese'
        break
      case 'zh-Hant-TW':
        tag = 'Chinese-Taiwan'
        break
      case 'vi-VN':
        tag = 'Vietnamese'
        break
      case 'id':
        tag = 'Indonesian'
        break
      case 'es-419':
        tag = 'Spanish'
        break
      case 'ms':
        tag = 'Malay'
        break
      case 'ko':
        tag = 'Korean'
        break
      case 'ja':
        tag = 'Japanese'
        break
      case 'ur-PK':
        tag = 'Urudu'
        break
      case 'uz':
        tag = 'Uzbek'
        break
      case 'ru':
        tag = 'Russian'
        break
    }
    return tag
  }

  formatData (data): any {
    const fieldsDetails = {}
    data.map(function (details) {
      fieldsDetails[details.sys.id] = details
    })
    return fieldsDetails
  }

  hrefLangCountryCapitalization (input): any {
    const hrefArray = input ? input.split('-') : []
    if (hrefArray[hrefArray.length - 1] !== 'default') {
      hrefArray.map((data, index) => {
        if (hrefArray.length - 1 !== index) {
          hrefArray[index] = hrefArray[index].toLowerCase()
        } else {
          hrefArray[index] = hrefArray[index]?.toUpperCase()
        }
      })
      return hrefArray.join('-')
    } else {
      hrefArray.map((data, index) => {
        hrefArray[index] = hrefArray[index].toLowerCase()
      })
      return hrefArray.join('-')
    }
  }

  gethrefDetails (website, hrefrobots): any {
    const hrefRobots = hrefrobots
    const hrefWebsite = website?.website
    const isLive = website?.live
    const hrefDomainName = website?.domain
    const defaultlangCode = website?.defaultLanguage?.code
    const hrefIsoCode = website?.defaultLanguage?.isoCode
    let secondayHrefDetails = []
    if ('enableSecondaryLanguages' in website && 'languages' in website) {
      const languageDetails = website.languages.map(language => {
        return this.getLanguageDetail(language)
      })
      secondayHrefDetails = languageDetails.map(language => {
        return new HrefLang(
          hrefWebsite,
          language.enableLanguage,
          language.urlCode,
          language.isoCode,
          hrefRobots,
          hrefDomainName
        )
      })
    }

    return new HrefLang(
      hrefWebsite,
      isLive,
      defaultlangCode,
      hrefIsoCode,
      hrefRobots,
      hrefDomainName,
      secondayHrefDetails
    )
  }

  getLanguageDetails (languageId, entryDetails): any {
    const languageDetails: any = {}
    if (languageId in entryDetails) {
      languageDetails.name = ('name' in entryDetails[languageId].fields) ? entryDetails[languageId].fields.name : ''
      languageDetails.title = ('title' in entryDetails[languageId].fields) ? entryDetails[languageId].fields.title : ''
      languageDetails.code = ('code' in entryDetails[languageId].fields) ? entryDetails[languageId].fields.code : ''
      languageDetails.urlCode = ('urlCode' in entryDetails[languageId].fields) ? entryDetails[languageId].fields.urlCode : ''
      languageDetails.isoCode = ('isoCode' in entryDetails[languageId].fields) ? entryDetails[languageId].fields.isoCode : ''
      languageDetails.enableLanguage = ('enableLanguage' in entryDetails[languageId].fields) ? entryDetails[languageId].fields.enableLanguage : 'No'
      languageDetails.dropdownCode = ('dropdownCode' in entryDetails[languageId].fields) ? entryDetails[languageId].fields.dropdownCode : ''
      languageDetails.displayTitle = ('displayTitle' in entryDetails[languageId].fields) ? entryDetails[languageId].fields.displayTitle : ''
    }
    return languageDetails
  }

  getLanguageDetail (langDetail): any {
    const languageDetails: any = {}
    if (langDetail) {
      languageDetails.name = ('name' in langDetail) ? langDetail.name : ''
      languageDetails.title = ('title' in langDetail) ? langDetail.title : ''
      languageDetails.code = ('code' in langDetail) ? langDetail.code : ''
      languageDetails.urlCode = ('urlCode' in langDetail) ? langDetail.urlCode : ''
      languageDetails.isoCode = ('isoCode' in langDetail) ? langDetail.isoCode : ''
      languageDetails.enableLanguage = ('enableLanguage' in langDetail) ? langDetail.enableLanguage : 'No'
      languageDetails.dropdownCode = ('dropdownCode' in langDetail) ? langDetail.dropdownCode : ''
      languageDetails.displayTitle = ('displayTitle' in langDetail) ? langDetail.displayTitle : ''
    }
    return languageDetails
  }

  getUspData (usp) {
    if (!this.isObjectEmpty(usp) && usp?.componentStyle === 'USP card container' && usp?.contentType === 'multipleCardContainer') {
      const mediaCardData = []
      if (usp?.mediaCards?.length) {
        usp?.mediaCards.forEach(mediaCard => {
        mediaCardData.push({ title: mediaCard.uspTitle, subTitle: mediaCard.uspSubtitle, image: mediaCard.uspLogo })
        })
      }
      return { title: usp?.title, uspCardDetails: mediaCardData }
    }
  }

  isCssFileExists (languageCode): any {
    const codeIndex = Object.keys(this.cssStyles).indexOf(languageCode)
    if (codeIndex !== -1) {
      return languageCode
    } else {
      return ''
    }
  }

  bannerDetails (banner, shortDescription, pageTitle, callToAction?, countryName?, ogDetails?, isSocialSharing?: boolean) {
    try {
      const bannerImage = this.getImageDetails(banner?.media, banner?.altText, callToAction?.imageCallToAction ? this.calltoActionDetails(callToAction?.imageCallToAction) : {})
      const country = (countryName === 'global' ? '' : ' ' + countryName?.charAt(0).toUpperCase() + countryName?.slice(1))
      if (!isSocialSharing && !this.metaTagsAdded) {
        this._SeoService.addMetaTags(ogDetails?.ogTitle + country, ogDetails?.ogDescription, bannerImage?.imageUrl, this.domainName)
        this.metaTagsAdded = true
      }
      return new FeaturedImage(
        '',
        pageTitle,
        shortDescription,
        bannerImage,
        callToAction ? this.calltoActionDetails(callToAction?.callToAction) : {}
      )
    } catch {
      console.log('Error in Banner details')
   }
  }
  getLanguageinfo (languageCode, countryName): any {
    const websiteInfo = this.routingInitService.getWebsiteInfo()
    const availableCountry = this.routingInitService.getcountry()
    let countryCodeArray = []

    if (languageCode !== '') {
      let countryDetails = Object.keys(websiteInfo).length > 0 ? websiteInfo['global']?.LanguageList : []
      if (availableCountry?.indexOf(countryName) !== -1) {
        if (Object.keys(websiteInfo).length > 0) {
          try {
            countryDetails = countryDetails.concat(websiteInfo[countryName]?.LanguageList)
            if (websiteInfo[countryName]?.LanguageList?.length === 0) {
              countryDetails.push(websiteInfo[countryName]?.defaultLanguage)
            }
          } catch (e) {
          }
        }
      }
      countryCodeArray = countryDetails.filter((code) => { return code.code === languageCode })
      return countryCodeArray
    } else {
      return countryCodeArray
    }
  }

  pageTrack (eventCategory, eventAction, eventLabel, websitename?, pagename?, language?, position?, keyword?, ecommerce?, asset_id?): any {
    websitename = websitename.toLowerCase()
    const indiaWebsite = this.checkIndiawebsite()
    if (indiaWebsite) {
      websitename = indiaDomain
      language = 'en'
    }
    if (isPlatformBrowser(this.platformId)) {
      window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
          event: 'events_ga4',
          'event category': eventCategory,
          'event action': eventAction,
          'event label': eventLabel,
          websitename: websitename || '',
          pagename: pagename || '',
          language: language || '',
          position: position || '',
          keyword: keyword || 'na',
          ecommerce: ecommerce !== undefined ? ecommerce : 'na',
          client_id: this.setOrGetClientId(),
          OnetrustActiveGroups: this.getConsentGroupsMatch(),
          asset_id: asset_id || 'na'
        })
    }
  }

  getEventLabel (url) {
    let eventLabel: any
    const isValidURL = url?.includes('://')
    switch (url !== undefined) {
      case url?.includes('/test-dates/'):
        eventLabel = 'city_landing'
        break
      case url?.includes('/test-dates'):
        eventLabel = 'test_date'
        break
      case url?.includes('/test-centre'):
        eventLabel = 'test_centre'
        break
      case isValidURL && new URL(url)?.hostname?.includes('book'):
        eventLabel = 'bx'
        break
      case isValidURL && new URL(url)?.hostname?.includes('bxsearch'):
        eventLabel = 'bxs'
        break
      case isValidURL && new URL(url)?.hostname?.includes('bx'):
        eventLabel = 'bx'
        break
      case isValidURL && new URL(url)?.hostname?.includes('ieltsidpindia'):
        eventLabel = 'icapps'
        break
      case isValidURL && new URL(url)?.hostname?.includes('ielts.idp.com'):
        eventLabel = 'ors'
        break
      default:
        eventLabel = 'na'
    }
    return eventLabel
  }

  customDimensionData (countryName, pageName, keyword?, assetId?): any {
    if (sessionStorage.getItem('customDimensionValues')) {
      const customDimensionValues = JSON.parse(sessionStorage.getItem('customDimensionValues'))
      const candidateNumber = customDimensionValues.candidateNumber
      const centreNumber = customDimensionValues.centreNumber
      this.customDimensionValue = { websiteName: countryName, pageName, 'Candidate Number': candidateNumber, 'Centre Number': centreNumber, assetId }
    } else {
      this.customDimensionValue = { websiteName: countryName, pageName, assetId }
    }
    this.customDimensionStatus.next(true)
  }

  uuidv4 (len): any {
    const charset = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    let result = ''
    const values = new Uint32Array(len)
    crypto.getRandomValues(values)
    for (let i = 0; i < len; i++) {
      result += charset[values[i] % charset.length]
    }
    return result
  }
  setOrGetClientId (): any {
      if (isPlatformBrowser(this.platformId) && !this._CookieServiceService.get('client_id')) {
        const dates = new Date()
        dates.setTime(dates.getTime() + (30 * 60 * 1000))
        const uuid = this.uuidv4(16)
        this._CookieServiceService.setWithExpiry('client_id', uuid, dates)
    }
    return this._CookieServiceService.get('client_id')
  }

  setOrGetSessionCookie (): any {
    let sessionID
    if (isPlatformBrowser(this.platformId)) {
      if (this._CookieServiceService.get('sessionID')) {
        sessionID = this._CookieServiceService.get('sessionID')
      } else {
        sessionID = this.uuidv4(16)
        const myDate: Date = new Date()
        myDate.setMinutes(myDate.getMinutes() + 30)
        this._CookieServiceService.setWithExpiry('sessionID', sessionID, { expires: myDate, path: '/', sameSite: 'Strict' })
      }
    }
    return sessionID
  }

  getStaticHref (staticHref): any {
    try {
      let splitLinkTags

         if (staticHref.trim() !== '') {
          splitLinkTags = staticHref.split(',')
          splitLinkTags.forEach(element => {
            if (element.length > 0) {
              element = element.toString().replace('<p>', '')
              element = element.toString().replace('</p>', '')
              element = element.toString().replace('/n ', '').trim()
              element = element.toString().replace(' /n', '').trim()
              element = element.toString().replace(',/n ', '').trim()
              element = element.toString().replace('<', '')
              element = element.toString().replace('link', '')
              element = element.toString().replace('/>', '')
              element = element.toString().replace('>', '').trim()
              const linkArray = element.split(/[\s=]+/)
              if (linkArray.length % 2 !== 0) {
                linkArray.pop()
              }
              const linkRel = {}
              linkArray.map((key, value) => {
                if (value % 2 === 0) {
                  linkRel[key] = linkArray[value + 1].replace(/['"]/g, '')
                }
              })

                this._SeoService.addHrefLang(linkRel)
            }
          })
        }
    } catch (error) {
      console.log('HREFLang Error Handled', error)
    }
  }
  getIframe (formEntryDetails): any {
    return new Iframe(
      formEntryDetails?.iframeUrl,
      formEntryDetails?.height,
      formEntryDetails?.tabletFromHeight || formEntryDetails?.height,
      formEntryDetails?.mobileFormHeight || formEntryDetails?.height,
      formEntryDetails?.formTitle,
      formEntryDetails?.dcontainerclass,
      formEntryDetails?.dIframeClass,
      formEntryDetails?.mContainerClass,
      formEntryDetails?.mIframeClass
    )
  }

  getLanguageTitle (languageCode, countryName): any {
    const websiteInfo = this.routingInitService.getWebsiteInfo()
    const availableCountry = this.routingInitService.getcountry()

    if (languageCode !== '') {
      let countryDetails = Object.keys(websiteInfo).length > 0 ? websiteInfo['global']?.LanguageList : []
      if (availableCountry.indexOf(countryName) !== -1) {
        countryDetails = (Object.keys(websiteInfo).length === 0 || Object.keys(websiteInfo).length > 0) ? websiteInfo[countryName]?.LanguageList : []
      }
      const countryCodeArray = countryDetails.filter((code) => { return code.code === languageCode })
      if (countryCodeArray.length > 0) {
        return countryCodeArray
      } else {
        return []
      }
    } else {
      return []
    }
  }
  pageRedirection (countryName, param, language, preview?, router?): any {
    const redirectionQuery = {
      query: { url: '/' + param, content_type: 'redirectionComponent', other_params: ['fields.website=' + countryName, 'locale=' + language], include: 4 }
    }
    this.httpService.post('AWS_ENDPOINT_URL', redirectionQuery).subscribe({
      next: (data) => {
        this.loaderService.hide()
        const pageData = data.items
        if (pageData.length > 0) {
          const newUrl = pageData[0].fields.newUrl
          const countryNAame = (countryName !== 'global' && countryName !== 'india') ? '/' + countryName : ''
          const routerString = router.replace('#', '?').split('?')[0]
          const routerArray = routerString.split('/').filter((val) => val !== '')
          let languageCode = ''
          if (this.isLanguageCode(routerArray)) {
            languageCode = '/' + routerArray[routerArray.length - 1]
          }
          // this.loaderservice.show()
          if (countryName == 'india') {
            if (!isPlatformBrowser(this.platformId)) {
              this.response.redirect(301, 'https://' + indiaDomain + newUrl)
            } else {
              window.location.href = 'https://' + indiaDomain + newUrl
            }
          } else {
            if (!isPlatformBrowser(this.platformId)) {
              this.response.redirect(301, this.domainName + countryNAame + newUrl + languageCode)
            } else {
              window.location.href = this.domainName + countryNAame + newUrl + languageCode
            }
          }
        } else {
          this.setPageNotFound(countryName)
        }
      },
      error: () => {
        this.loaderService.hide()
        // Taiwan Simplified chinese removal logic
        if (countryName === 'taiwan') {
          if (!isPlatformBrowser(this.platformId)) {
            this.response.redirect(301, this.domainName + '/' + countryName)
          } else {
            this.router.navigate(['/' + countryName], {
              replaceUrl: true
            })
          }
        } else {
          this.setPageNotFound(countryName)
        }
      }
    })
  }

  setPageNotFound (countryName): any {
    this.customDimensionData(countryName, 'Pagenotfound Page', '')
    this.document.body.classList.add('error-page')
    this.pageNotFound.next(true)
    if (!isPlatformBrowser(this.platformId)) {
      this.response.status(404)
    }
    const nonFoundPageData = {
      metaTitle: '404 - Page Not Found',
      robots: 'noindex,follow'
    }
    this._SeoService.setSeoTitle(nonFoundPageData)
    this._SeoService.setSeoRobots(nonFoundPageData)
  }

  englishOrLocalOnlyArticle (url, englishArticle, localArticle, translatedLangDetails, countryName, lang?) {
    const language = lang || this.getCurrentLangCode(countryName)
    const currentlangaugeTitle = this.getLanguageTitle(language, countryName)
    if (englishArticle) {
      if (language !== 'en-GB') {
        if (translatedLangDetails?.indexOf(currentlangaugeTitle[0].title) === -1) {
          const urlArray = url.split('/').filter((val) => val !== '')
          if (this.isLanguageCode(urlArray)) {
            urlArray.splice(urlArray.length - 1, 1)
          }
          const defaultlangaugeTitle = this.getLanguageTitle('en-GB', countryName)
          let defaultCode = ''
          if (defaultlangaugeTitle.length > 0) {
            defaultCode = '/' + defaultlangaugeTitle[0].urlCode
          }
          const redirectionUrl = urlArray.join('/')
          if (!isPlatformBrowser(this.platformId)) {
            this.response.redirect(307, '/' + redirectionUrl + defaultCode + '#show-message')
          } else {
            // window.location.href = redirectionUrl + defaultCode + '#show-message'
            this.renderer.removeClass(this.document.body, language)
            this.router.navigate([redirectionUrl + defaultCode], { fragment: 'show-message' })
          }
        } else {
          this.router.navigate([url])
        }
      } else {
        this.router.navigate([url])
      }
    } else if (localArticle) {
      const websiteInfo = this.routingInitService.getWebsiteInfo()
      const defaultLanguage = websiteInfo[countryName]?.defaultLanguage
      const primaryLanguage = { code: defaultLanguage?.code, title: defaultLanguage?.title }
      const secondaryLanguageList = websiteInfo[countryName]?.LanguageList
      const secondaryLanguage = secondaryLanguageList.map((lang) => {
        return { code: lang.code, title: lang.title }
      })
      let index = 0
      secondaryLanguage.forEach(data => {
        translatedLangDetails?.forEach(value => {
          if (value === data.title) {
            index++
          }
        })
      })
      if (language === primaryLanguage.code) {
        if (translatedLangDetails?.length > 0 && translatedLangDetails?.indexOf(primaryLanguage.title) !== -1) {
          this.router.navigate([url])
        } else {
          if (index > 0) {
            secondaryLanguage.map(data => {
              this.renderer.removeClass(this.document.body, language)
              if (translatedLangDetails?.length > 0 && translatedLangDetails?.indexOf(data.title) !== -1) {
                if (!isPlatformBrowser(this.platformId)) {
                  this.response.redirect(307, '/' + url + '/' + data.code + '#show-local-article-message')
                } else {
                  // window.location.href = url + '/' + data.code + '#show-local-article-message'
                  this.renderer.addClass(this.document.body, data.code)
                  this.router.navigate([url + '/' + data.code.toLowerCase()], { fragment: 'show-local-article-message' })
                }
              }
            })
          } else {
            this.router.navigate([url])
          }
        }
      } else {
        secondaryLanguage.map(data => {
          if (language === data.code) {
            if (translatedLangDetails?.length > 0 && translatedLangDetails?.indexOf(data.title) !== -1) {
              this.router.navigate([url])
            } else {
              if (index > 0) {
                this.renderer.removeClass(this.document.body, language)
                secondaryLanguage.map(value => {
                  if (translatedLangDetails?.length > 0 && translatedLangDetails?.indexOf(value.title) !== -1) {
                    if (!isPlatformBrowser(this.platformId)) {
                      this.response.redirect(307, '/' + url + '/' + value.code + '#show-local-article-message')
                    } else {
                      // window.location.href = url + '/' + value.code + '#show-local-article-message'
                      this.renderer.addClass(this.document.body, value.code)
                      this.router.navigate([url + '/' + value.code.toLowerCase()], { fragment: 'show-local-article-message' })
                    }
                  }
                })
              } else {
                this.router.navigate([url])
              }
            }
          }
        })
      }
    }
  }
  getSafeHTML (value) {
    const json = value
      ? JSON.stringify(value, null, 2)
      : ''
    const html = `<script type="application/ld+json"> ${json}</script>`
    return this.sanitizer.bypassSecurityTrustHtml(html)
  }

  getParagraphDetails (paragraphEntryDetails): any {
    try {
      // const paragraphContentValue = this.getRichTextContentOption(paragraphEntryDetails?.paragraph, entryDetails, assetsDetails, countryName)
      const paragraphDetails = new ParagraphInfo(
        paragraphEntryDetails?.title,
        paragraphEntryDetails?.title ? this.articleParagraphUrlpattern(paragraphEntryDetails?.title) : '',
        paragraphEntryDetails?.paragraph,
        paragraphEntryDetails?.image ? this.getImageDetails(paragraphEntryDetails?.image, paragraphEntryDetails?.image?.altText) : {},
        paragraphEntryDetails?.video ? paragraphEntryDetails?.video : {}
      )
      return paragraphDetails
    } catch (error) {
      console.log(' — Error is handled gracefully: ', error)
    }
  }

  articleParagraphUrlpattern (title): any {
    title = title.trim()
    title = title.replace(/\s+/g, '-').toLowerCase()
    title = title.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '')
    return title
  }

  getTeaserCompDetails (teaserData) {
    if (teaserData && !this.isObjectEmpty(teaserData)) {
      const callToAction = teaserData?.callToActionCta ? this.calltoActionDetails(teaserData?.callToActionCta) : {}
      const imageDetails = teaserData?.media ? this.getImageDetails(teaserData.media) : {}
      return new TeaserContent(
        teaserData?.title,
        teaserData?.shortDescription,
        (teaserData?.longDescription),
        callToAction,
        teaserData?.componentStyle,
        teaserData?.backgroundFill,
        teaserData?.mediaCardPosition,
        imageDetails,
        teaserData?.gaId
      )
    }
  }
  getTeaserHomeCompDetails (teaserData, entryDetails?, assetsDetails?) {
    if (teaserData && !this.isObjectEmpty(teaserData)) {
      const callToAction = teaserData?.callToActionCta && entryDetails && !this.isObjectEmpty(entryDetails) ? this.calltoActionDetails(entryDetails[teaserData?.callToActionCta?.sys?.id]?.fields) : teaserData?.callToActionCta ? this.calltoActionDetails(teaserData?.callToActionCta) : {}
      const imageDetails = teaserData?.media && assetsDetails && !this.isObjectEmpty(assetsDetails) ? this.getImageDetails(assetsDetails[teaserData.media.sys.id]?.fields, assetsDetails[teaserData.media.sys.id]?.fields?.title) : teaserData?.media ? this.getImageDetails(teaserData.media) : {}
      return new TeaserContent(
        teaserData?.title,
        teaserData?.shortDescription,
        teaserData?.longDescription,
        callToAction,
        teaserData?.componentStyle,
        teaserData?.backgroundFill,
        teaserData?.mediaCardPosition,
        imageDetails,
        teaserData?.gaId,
        '',
        (Object.keys(teaserData?.cost).length) ? teaserData?.cost?.currency + ' ' + teaserData?.cost?.price : ''
      )
    }
  }

  getArticleDetails (data) {
    return new RelatedArticle(
      ('title' in data) ? data.title : '',
      ('shortDescription' in data) ? data.shortDescription : '',
      ('featuredImage' in data) ? this.getImageDetails(data?.featuredImage?.media, data?.featuredImage?.altText) : {},
      ('urlSlug' in data) ? data.urlSlug : '',
      ('gaId' in data) ? data.gaId : '',
      ('duration' in data) ? data.duration : '',
      data?.metaTagTopic?.length ? data?.metaTagTopic[0]?.title : data?.topic?.length ? data?.topic[0] : '',
      data?.translatedLanguages || data?.translatedLanguagesList || '',
      ('metaTags' in data) ? data.metaTags : '',
      data?.articleType ? data?.articleType : '',
      data?.modifiedDate ? data?.modifiedDate : ''
    )
  }

  scrolltoElement (ele) {
    this.document.getElementById(ele)?.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }

  selectTooglefu (id?) {
    const element = this.document.getElementById(id)
    if (id && !element.classList?.contains('active')) {
      element?.classList?.add('active')
      if (this.selectToogle === id) {
        this.selectToogle = ''
      }
    } else if (id && element.classList?.contains('active')) {
      element?.classList?.remove('active')
      this.selectToogle = ''
    }
    if (this.selectToogle) {
      this.outSideClick()
    }
    this.selectToogle = id
  }
  outSideClick () {
      const ele = this.document.getElementById(this.selectToogle)
      if (ele?.classList.contains('active')) {
        ele?.classList.remove('active')
    }
    this.selectToogle = null
  }

  manageCookieapi (countryName) {
    const query: any = {
      query: {
        locale: 'en-GB',
        content_type: 'iaManageCookies',
        include: '4',
        'fields.website[in]': countryName
      }
    }
    return this.httpService.post(
      'AWS_ENDPOINT_URL',
      query
    )
  }

  checkSmartBanner () {
    const smartBannerClosed = this._CookieServiceService.get('smartBanner')
    return smartBannerClosed !== 'closed'
  }

  firstPlayTrack (videoElement, countryName, eventLabel, asset_id?) {
    if (!videoElement.getAttribute('data-first-play')) {
      this.pageTrack('video_play', 'initialised', eventLabel, countryName, this.customDimensionValue['pageName'], this.siteLanguage, 'body_content', 'na', '', asset_id)
      videoElement.setAttribute('data-first-play', 'true')
    }
  }

  getBaseURL (): any {
    if (!isPlatformBrowser(this.platformId)) {
      return this.request.get('origin')
    } else {
      return this.document.location.hostname
    }
  }

  checkIndiawebsite () {
    const url = this.getBaseURL()
    if (url?.replace(/^www\./, '') === indiaDomain) {
      return true
    } else {
      return false
    }
  }

  formUtmUrl (url) {
    const utm_value = this._CookieServiceService.get('utm_value')
    const isBx = url ? this.getEventLabel(url) : ''
    if (utm_value && (isBx === 'bx' || isBx === 'bxs' || isBx === 'ors')) {
      if (url?.includes('://') && url.includes('?')) {
        return url + utm_value
      } else {
        return url + '?' + utm_value.substring(1)
      }
    } else {
      return url
    }
  }

setUtminSession () {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    const utm_url = this.formUtmparams(urlParams, 'u_id', 'utm_id') +
                    this.formUtmparams(urlParams, 'u_src', 'utm_source') +
                    this.formUtmparams(urlParams, 'u_mdm', 'utm_medium') +
                    this.formUtmparams(urlParams, 'u_trm', 'utm_term') +
                    this.formUtmparams(urlParams, 'u_cmp', 'utm_campaign') +
                    this.formUtmparams(urlParams, 'u_cnt', 'utm_content')
    if (utm_url) {
        this._CookieServiceService.set('utm_value', utm_url)
    }
  }

 formUtmparams (urlParams, encode_utm_value, utm_value) {
    const utm_paranm = decodeURIComponent(urlParams.get(encode_utm_value) || decodeURIComponent(urlParams.get(utm_value)))
    return (utm_paranm && utm_paranm != 'null') ? '&' + encode_utm_value + '=' + utm_paranm : ''
  }
  getConsentGroupsMatch () {
    const cookieConsent = this._CookieServiceService.get('OptanonConsent') || ''
    const consentGroupsMatch = cookieConsent.match(/groups=([^&]*)/)
    const finalValue = []
    if (consentGroupsMatch?.length) {
      consentGroupsMatch[1].split(',').forEach(data => {
        if (data?.includes(':1')) {
          finalValue.push(data?.split(':')[0])
        }
      })
    }
    return finalValue.length ? ',' + finalValue.join(',') + ',' : ',C0001,C0004,C0003,C0002,'
  }
  getBookingUrl (bookatestURL: any) {
    let bookNowUrl = bookatestURL
    if (this._CookieServiceService.getClientCountryCode() === 'IN') {
      bookNowUrl = 'https://www.ieltsidpindia.com/registration/registration'
    } else if (this.routingInitService.bookNowUrl) {
      bookNowUrl = this.routingInitService.bookNowUrl
    }
    return bookNowUrl
  }
  gtmVideoTrack (countryName) {
    const videos = this.document.querySelectorAll('video')
    videos?.forEach(video => {
    if (video && video.getElementsByTagName('source')?.length) {
      const eventLabel = video.getElementsByTagName('source')[0]?.getAttribute('event_label')?.split(' ')?.join('_')?.toLowerCase()
      const asset_id = video.getElementsByTagName('source')[0]?.getAttribute('asset_id')
      video.onplay = () => {
        this.firstPlayTrack(video, countryName, eventLabel, asset_id)
      }
      video.onended = () => {
        this.pageTrack('video_play', 'completed', eventLabel, countryName, this.customDimensionValue['pageName'], this.siteLanguage, 'body_content', 'na', '', asset_id)
      }
    }
    })
  }
  retainFilterData (filterData: any[], transactionalBannerFilter: any) {
    if (filterData && transactionalBannerFilter) {
      let filterCount = 0
      let enableTimePref = false
      filterData.forEach(data => {
        if (data.filterType === 'deliveryFormatFilter' && data && transactionalBannerFilter[data?.filterType]?.length) {
          filterCount += data.selectedValues.length
          if (transactionalBannerFilter[data?.filterType]?.length === 1 && transactionalBannerFilter[data?.filterType][0].value === 'Computer-delivered') {
            enableTimePref = true
          }
        } else if ((data && transactionalBannerFilter[data?.filterType]?.value) || (data.filterType === 'Calender' && data.selectedValues.length)) {
          if (data.filterType === 'Calender') {
            if (transactionalBannerFilter.startDate || transactionalBannerFilter.endDate) {
              data.selectedValues = []
              if (transactionalBannerFilter.startDate) {
                data.selectedValues.push(transactionalBannerFilter.startDate)
                data.displayValues.push(transactionalBannerFilter.startDate)
              }
              if (transactionalBannerFilter.endDate) {
                data.selectedValues.push(transactionalBannerFilter.endDate)
                data.displayValues.push(transactionalBannerFilter.endDate)
              }
            }
            filterCount++
          } else if (data.filterType === 'testTypeFilter' || data.filterType === 'deliveryFormatFilter' || data.filterType === 'noOfDaysFilter') {
            filterCount += data.selectedValues.length
          }
        }
      })
      return { filterCount, enableTimePref }
    }
  }
}
