import { Injectable, Inject, RendererFactory2, ViewEncapsulation } from '@angular/core'
import { DOCUMENT } from '@angular/common'
import { Title, Meta } from '@angular/platform-browser'
import { RoutingInitService } from './routing-init.service'
import { ActivatedRoute, Router } from '@angular/router'

@Injectable()
export class SeoService {
  pageNoData: boolean = false
  static setSeoRobots (nonFoundPageData: { metaTitle: string, robots: string }) {
    throw new Error('Method not implemented.')
  }
  static setSeoTitle (nonFoundPageData: { metaTitle: string, robots: string }) {
    throw new Error('Method not implemented.')
  }
  public disableRobots
  resultData = require('../../assets/course/resultInfo.json')

  constructor (@Inject(DOCUMENT) private readonly doc,
    private readonly rendererFactory: RendererFactory2,
    private readonly title: Title,
    private readonly meta: Meta,
    public readonly router: Router,
    private readonly routingInitService: RoutingInitService,
    private readonly activatedRoute: ActivatedRoute
  ) {}

  setSeoTitle (pageData): any {
    this.routingInitService.websiteStatus.subscribe((data) => {
      if (data && 'metaTitle' in pageData) {
        this.title.setTitle(pageData.metaTitle)
      }
    })
  }

  setSiteNotFoundSeoTitle (pageData): any {
    if ('metaTitle' in pageData) {
      this.title.setTitle(pageData.metaTitle)
    }
  }

  prepResultsnotfound (seoFields): any {
    this.setSeoTitle(seoFields)
    this.setSeoRobots(seoFields)
    this.routingInitService.websiteStatus.subscribe((data) => {
      if (data && 'metaDescription' in seoFields[0]?.fields) {
        this.meta.addTag({ name: 'description', content: seoFields[0]?.fields?.metaDescription })
      }
    })
  }

  // Home page specific
  setSeoHomeTitle (pageData): any {
    this.routingInitService.websiteStatus.subscribe((data) => {
      if (data && pageData) {
        this.title.setTitle(pageData)
      }
    })
  }
  // Home page specific

  setSeoRobots (pageData): any {
    this.routingInitService.websiteStatus.subscribe((data) => {
      if (data && 'robots' in pageData) {
        this.meta.updateTag({ name: 'robots', content: pageData.robots })
      }
    })
  }

  setSiteNotFoundSeoRobots (pageData): any {
    if ('robots' in pageData) {
      this.meta.updateTag({ name: 'robots', content: pageData.robots })
    }
  }

  removeLink (linkName: string): any {
    try {
      const renderer = this.rendererFactory.createRenderer(this.doc, {
        id: '-1',
        encapsulation: ViewEncapsulation.None,
        styles: [],
        data: {}
      })
      const canonical = this.doc.querySelector("link[rel='" + linkName + "']")
      const head = this.doc.head
      if (head === null) {
        throw new Error('<head> not found within DOCUMENT.')
      }
      if (canonical) {
        renderer.removeChild(head, canonical)
      }
    } catch (e) {
      console.error('Error within linkService : ', e)
    }
  }

  addSeoTags (pageData, preview): any {
    this.routingInitService.websiteStatus.subscribe((data) => {
      if (data) {
        if ('metaDescription' in pageData) {
          this.meta.updateTag({ name: 'description', content: pageData.metaDescription })
        }
        if ('metaKeywords' in pageData) {
          this.meta.updateTag({ name: 'keywords', content: pageData.metaKeywords })
        }
        if ('robots' in pageData && !preview && pageData.robots !== '') {
          this.meta.updateTag({ name: 'robots', content: pageData.robots })
        }
        if ('robots' in pageData && preview) {
          this.meta.updateTag({ name: 'robots', content: 'noindex,nofollow' })
        }
        if (this.disableRobots === 'Yes') {
          this.meta.updateTag({ name: 'robots', content: 'noindex,nofollow' })
        }
      }
    })
  }

  addHomeSeoTags (pageData, preview): any {
    this.routingInitService.websiteStatus.subscribe((data) => {
      if (data) {
        if ('metaDescription' in pageData) {
          this.meta.updateTag({ name: 'description', content: pageData?.metaDescription })
        }
        if ('metaKeywords' in pageData) {
          this.meta.updateTag({ name: 'keywords', content: pageData?.metaKeywords })
        }
        if ('robots' in pageData && !preview && pageData?.robots !== '') {
          this.meta.updateTag({ name: 'robots', content: pageData?.robots })
        }
        if ('robots' in pageData && preview) {
          this.meta.updateTag({ name: 'robots', content: 'noindex,nofollow' })
        }
        if (this.disableRobots === 'Yes') {
          this.meta.updateTag({ name: 'robots', content: 'noindex,nofollow' })
        }
      }
    })
  }

  setHomeSeoCanonical (pageData, domainURL): any {
    this.routingInitService.websiteStatus.subscribe((data) => {
      if (data && pageData?.canonical) {
        const link: HTMLLinkElement = this.doc.createElement('link')
        link.setAttribute('rel', 'canonical')
        this.doc.head.appendChild(link)
       link.setAttribute('href', domainURL + pageData?.canonical)
      }
    })
  }

  setSeoCanonical (pageData, preview, domainurl): any {
    this.routingInitService.websiteStatus.subscribe((data) => {
      if (data) {
        this.activatedRoute.queryParamMap.subscribe(
          (queryParams) => {
            if (queryParams.get('page') != null) {
              this.pageNoData = true
            }
          }
        )
        if (pageData?.canonical && !preview) {
            if (!this.pageNoData) {
            const link: HTMLLinkElement = this.doc.createElement('link')
            link.setAttribute('rel', 'canonical')
            this.doc.head.appendChild(link)
            link.setAttribute('href', domainurl + pageData.canonical)
            }
        }
      }
    })
  }

  isLanguageCode (segments): any {
    let websiteInfo: any = {}
    websiteInfo = this.routingInitService.getWebsiteInfo()
    const availableCountry = this.routingInitService.getcountry()

    if (segments.length > 0) {
      let countryDetails = websiteInfo.length > 0 ? websiteInfo['global']?.LanguageList : []
      if (availableCountry.indexOf(segments[0]) !== -1) {
        countryDetails = (websiteInfo.length === 0 || websiteInfo.length > 0) ? websiteInfo[segments[0]]?.LanguageList : []
      }
      const segmentsLength = segments?.length
      const countryCodeArray = countryDetails?.length ? countryDetails.map((code) => { return code.urlCode }) : []
      const codeIndex = countryCodeArray.indexOf(segments[segmentsLength - 1])
      if (codeIndex !== -1) {
        return true
      } else {
        return false
      }
    } else {
      return false
    }
  }

  setSeoUrl (winHref, preview, urlCode = '', domainURL): any {
    this.routingInitService.websiteStatus.subscribe((data) => {
      if (data) {
        this.activatedRoute.queryParamMap.subscribe(
          (queryParams) => {
            if (queryParams.get('page') != null) {
              this.pageNoData = true
            }
          }
        )
        const winHrefArray = winHref.split('/').filter((val) => val !== '')
        if (this.isLanguageCode(winHrefArray)) {
          winHrefArray.splice(winHrefArray.length - 1, 1)
        }
        winHref = '/' + winHrefArray.join('/')
        if (urlCode !== '') {
          urlCode = '/' + urlCode
        }
        if (!this.pageNoData) {
          const link: HTMLLinkElement = this.doc.createElement('link')
          link.setAttribute('rel', 'canonical')
          this.doc.head.appendChild(link)
          link.setAttribute('href', domainURL + winHref + urlCode)
        }
      }
    })
  }

  removeTag (attrSelector: string): any {
    if (attrSelector.length > 0) {
      try {
        const renderer = this.rendererFactory.createRenderer(this.doc, {
          id: '-1',
          encapsulation: ViewEncapsulation.None,
          styles: [],
          data: {}
        })
        const head = this.doc.head
        if (head === null) {
          throw new Error('<head> not found within DOCUMENT.')
        }
        const linkTags = this.doc.querySelectorAll('link[' + attrSelector + ']')
        for (const link of linkTags) {
          renderer.removeChild(head, link)
        }
      } catch (e) {
      }
    }
  }

  addMetaTags (title, shortDes, imageUrl, domainURL) {
    this.meta.addTags([{ property: 'og:title', content: title }])
    this.meta.addTags([{ property: 'og:description', content: shortDes }])
    if (imageUrl) {
      this.meta.addTags([{ property: 'og:image', content: imageUrl }])
    }
    this.meta.addTags([{ property: 'og:url', content: domainURL + this.router.url }])
    this.meta.addTags([{ property: 'og:type', content: 'website' }])
  }

  addTwitterMetaTags (title, description, imageUrl, domainURL) {
    this.meta.addTags([{ name: 'twitter:card', content: 'summary' }])
    this.meta.addTags([{ name: 'twitter:site', content: 'IDP IELTS India' }])
    this.meta.addTags([{ name: 'twitter:title', content: title }])
    this.meta.addTags([{ name: 'twitter:description', content: description }])
    this.meta.addTags([{ name: 'twitter:url', content: domainURL + this.router.url }])
    if (imageUrl) {
      this.meta.addTags([{ name: 'twitter:image', content: imageUrl }])
    }
  }

  addHrefLang (tag: LinkDefinition, forceCreation?: boolean): any {
    try {
      const renderer = this.rendererFactory.createRenderer(this.doc, {
        id: '-1',
        encapsulation: ViewEncapsulation.None,
        styles: [],
        data: {}
      })
      const link = renderer.createElement('link')
      const head = this.doc.head
      if (head === null) {
        throw new Error('<head> not found within DOCUMENT.')
      }
      let removeLink = ''
      Object.keys(tag).forEach((prop: string) => {
        removeLink = "hreflang='" + tag.hreflang + "'][ href" + "='" + tag.href + "'"
        return renderer.setAttribute(link, prop, tag[prop])
      })

      // [TODO]: get them to update the existing one (if it exists) ?
      this.removeTag(removeLink)
      renderer.appendChild(head, link)
    } catch (e) {
      console.error('Error within linkService : ', e)
    }
  }
}

export declare type LinkDefinition = {
  charset?: string
  crossorigin?: string
  href?: string
  hreflang?: string
  media?: string
  rel?: string
  rev?: string
  sizes?: string
  target?: string
  type?: string
} & {
  [prop: string]: string
}
