import { DOCUMENT, isPlatformBrowser } from '@angular/common'
import { Component, Inject, Input, ElementRef, OnInit, Renderer2, PLATFORM_ID, HostListener } from '@angular/core'
import { ClickStreamEventData } from '../../common/models/clickStream-info'
import { ImageConstant, indiaDomain } from '../../common/utilities/constants'
import { UTILITIES } from '../../common/utilities/ielts-util'
import { ClickStreamService } from '../../services/click-stream.service'
import { ContentfulService } from '../../services/contentful.service'
import { DomSanitizer } from '@angular/platform-browser'
import { CookieBackendService } from '../../services/cookie-backend.service'
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  @Input() footerMenu
  @Input() otherDetails
  @Input() isResultPage
  mobNavPosition = '1'
  footerBottomNavDetails = []
  public countrynames = []
  public particularcountry = 'Global'
  assetPath = ImageConstant + '/IELTS_Website_Rebrand'
  defaultImage = ImageConstant + '/assets/img_px.gif'
  domainUrl
  countryDropDownOpen = false
  subscribePopUpShow = false
  subscriptionURL: any
  titles: any
  indiaHomepage: string
  @HostListener('document:click', ['$event'])
    onDocumentClick (event: MouseEvent): void {
      if (!this.document.getElementById('countryDropDown').contains(event.target)) {
        if (this.countryDropDownOpen) {
          this.outSideClick()
        }
      }
    }
  constructor (
    @Inject(PLATFORM_ID) private readonly platformId: any,
    @Inject(DOCUMENT) private readonly document: any,
    private readonly elementRef: ElementRef,
    private readonly UTILITIES: UTILITIES, private readonly contentfulService: ContentfulService,
    private readonly renderer: Renderer2,
    public readonly clickStream: ClickStreamService,
    private readonly sanitizer: DomSanitizer,
    private readonly cookieService: CookieBackendService
    ) { }
  handleClickLink (event) {
    const idAttr = event.target.dataset.id
    if (idAttr !== undefined) {
      this.mobNavPosition = idAttr.slice(-1)
      const allItems = this.document.getElementById(idAttr)
      allItems.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' })
    }
  }
  // footer nav ends
  ngAfterViewInit () {
    const snapTargets = this.elementRef.nativeElement.querySelectorAll('.snap-target')
    const options = { root: null, rootMargin: '0px', threshold: 0.5 }
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          this.mobNavPosition = entry.target.id.slice(-1)
          this.renderer.addClass(entry.target, 'snap-visible')
        } else {
          this.renderer.removeClass(entry.target, 'snap-visible')
        }
      })
    }, options)
    snapTargets.forEach(target => {
      observer.observe(target)
    })
  }
  ngOnInit () {
    if (this.cookieService.get('Client_Country_Code') === 'IN' && this.otherDetails['countryUrl'] === '') {
      this.indiaHomepage = 'https://' + indiaDomain
    }
    this.titles = this.contentfulService.translatedData.commonList
    // footer bottom navigation logic
    if (this.footerMenu?.subscriptionDetails && isPlatformBrowser(this.platformId)) {
      this.subscriptionURL = this.sanitizer.bypassSecurityTrustResourceUrl(this.footerMenu.subscriptionDetails.primaryCtaUrl)
    }
    if (this.footerMenu?.footerBottomNavigation && this.footerMenu.footerBottomNavigation.length > 0) {
      this.footerMenu.footerBottomNavigation.map(data => {
        let fragMentURL = ''
        let navigationUrl = ''
        if (data?.navigationUrl && data.navigationUrl?.includes('#')) {
          fragMentURL = data?.navigationUrl.split('#')[1]
          navigationUrl = data?.navigationUrl.split('#')[0]
        } else {
          fragMentURL = ''
          navigationUrl = data?.navigationUrl
        }
        this.footerBottomNavDetails.push(
          {
            navigationName: data?.navigationLabel ? data?.navigationLabel?.replace('{}', this.footerMenu.currentYear) : '',
            navigationUrl,
            fragMentURL,
            target: data?.target ? data.target : '',
            gaID: data?.gaID ? data?.gaID : ''
          }

        )
      })
      if (!isPlatformBrowser(this.platformId)) {
        this.countrynames = this.countrynames.concat(this.footerMenu.footerCountryDropdown)
      }
      this.selectCountryDropdown()
    }
    // footer bottom navigation logic end
  }
  openCountryDrpDown () {
    this.countryDropDownOpen = !this.countryDropDownOpen
  }
  outSideClick () {
    this.countryDropDownOpen = false
  }
  footerGaTrack (eventCategory, eventAction, clickStreamLabel?) {
    this.contentfulService.pageTrack(eventCategory, eventAction?.toLowerCase(), 'na', this.otherDetails?.countryName, this.contentfulService.customDimensionValue['pageName'], this.contentfulService.siteLanguage, 'footer', 'na')
    if (eventCategory === 'newsletter_subscribe') {
      this.subscribePopUp(true)
      const prevPageName = this.contentfulService.prevPageName ? this.contentfulService.prevPageName : this.contentfulService.customDimensionValue['pageName']

      const event = new ClickStreamEventData(this.contentfulService.customDimensionValue['pageName'], 'na', 'SubscribeClicked', 'Interaction', 'Footer', 'Subscription to newsletter', clickStreamLabel, prevPageName)
      this.clickStream.clickstreamLogging(event)
    } else {
      const pageData: any = {}
      const prevPageName = this.contentfulService.prevPageName ? this.contentfulService.prevPageName : this.contentfulService.customDimensionValue['pageName']
      const event = new ClickStreamEventData(this.contentfulService.customDimensionValue['pageName'], 'na', 'PageViewed', 'Interaction', 'Footer', clickStreamLabel, 'NA', prevPageName)
      this.clickStream.clickstreamLogging({ ...event, ...pageData })
    }
  }
  subscribePopUp (value) {
    this.subscribePopUpShow = value
  }
  stop (e: any) {
    e.stopPropagation()
  }
  selectCountryDropdown (): any {
    this.domainUrl = this.UTILITIES.domainUrl
    let index = -1
    this.countrynames.forEach((val, key) => {
      const countryURLCheck = val.navigationUrl?.replace(/^.*\/\/[^/]+/, '').split('/').filter((val) => val !== '')
      if (this.otherDetails?.countryName !== '' && val.navigationUrl.indexOf(this.otherDetails?.countryName) >= 0 && countryURLCheck.length === 1 && countryURLCheck[0] === this.otherDetails?.countryName) {
        index = key
        this.particularcountry = val.navigationLabel
      } else if (this.otherDetails?.countryName === 'india' && val.navigationLabel === 'India') {
        index = key
        this.particularcountry = val.navigationLabel
      }
    })
    if (index > -1) {
      this.countrynames.splice(index, 1)
    }

    this.countrynames = this.countrynames.filter(cntry => {
      if (this.otherDetails?.countryName.charAt(0)?.toUpperCase() + this.otherDetails?.countryName.slice(1) === 'Global') {
        if (cntry.navigationLabel !== 'Global') {
          return this.countrynames
        }
      } else {
        return this.countrynames
      }
    })
  }

  @HostListener('window:scroll', ['$event'])
  onScroll (e): any {
    if (this.countrynames.length === 0) {
      this.countrynames = this.countrynames.concat(this.footerMenu.footerCountryDropdown)
      this.selectCountryDropdown()
    }
  }
  returnHtmlFromRichText (richText: any): any {
    return richText?.replace(/\r?\n/g, '<br/>')
  }
  eventTrack (ctaTitle, url) {
    const prevPageName = this.contentfulService.prevPageName ? this.contentfulService.prevPageName : this.contentfulService.customDimensionValue['pageName']

    const event = new ClickStreamEventData(this.contentfulService.customDimensionValue['pageName'], url, 'ButtonClicked', 'Interaction', 'Footer', 'Clicks on social icons', ctaTitle, prevPageName)
    this.clickStream.clickstreamLogging(event)
    this.footerGaTrack('social_share_footer', ctaTitle?.toLowerCase())
  }
}
