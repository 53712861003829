import { DOCUMENT, isPlatformBrowser } from '@angular/common'
import { Component, EventEmitter, Inject, Input, Output, PLATFORM_ID } from '@angular/core'
import { ImageConstant, indiaDomain } from '../../common/utilities/constants'
import { ContentfulService } from '../../services/contentful.service'
import { Router } from '@angular/router'
import { ClickStreamEventData } from '../../common/models/clickStream-info'
import { ClickStreamService } from '../../services/click-stream.service'
import { UTILITIES } from '../../common/utilities/ielts-util'

@Component({
  selector: 'app-header-megamenu',
  templateUrl: './header-megamenu.component.html',
  styleUrls: ['./header-megamenu.component.css']
})
export class HeaderMegamenuComponent {
  @Input() otherHeaderDetails
  @Input() childMenu
  @Input() countryUrl
  @Input() languageSelector
  @Output() langChanged = new EventEmitter()
  @Output() gaDetails = new EventEmitter()
  prepHeaderData
  imageType
  headerConstText
  assetPath = ImageConstant + '/IELTS_Website_Rebrand'
  defaultImage = ImageConstant + '/assets/img_px.gif'
  testmonialTooltip: string = ''
  resultsUrl: string = ''
  constructor (
    @Inject(DOCUMENT) private readonly document: any, private readonly router: Router,
    @Inject(PLATFORM_ID) private readonly platformId: any, private UTILITIES: UTILITIES,
    private readonly clickStream: ClickStreamService,
    private readonly contentfulService: ContentfulService) { }

    ngOnInit () {
      this.resultsUrl = this.UTILITIES.domainUrl + '/results/check-your-result'
      if (this.contentfulService.checkIndiawebsite()) {
        this.resultsUrl = 'https://' + indiaDomain + '/access/candidatelogin'
      }
      this.headerConstText = this.contentfulService?.translatedData?.header
      this.imageType = this.contentfulService.setImgType()
    }
  ngAfterViewInit () {
    if (this.otherHeaderDetails && this.otherHeaderDetails['hubpageType'] === 'Prepare') {
      const divList = []
      for (let i = 0; i < this.childMenu.length; i++) {
        divList.push('masonary-item' + this.otherHeaderDetails['parentIndex'] + (i + 1))
      }
      divList.forEach(data => {
        if (data) {
          this.resizeGridItem(data)
          }
        })
    }
    const testmonial = this.document.querySelector('#testimonial')
    this.testmonialTooltip = testmonial?.getElementsByTagName('P')[0]?.innerHTML
  }

  preparationToolStarted (quiz) {
    sessionStorage.setItem('preparationToolStarted', this.router.url)
    this.contentfulService.pageTrack('prepare_header', quiz[0].gaId, 'English diagnostic tool', 'Global', this.contentfulService.customDimensionValue['pageName'], this.contentfulService.siteLanguage, 'header', 'na')
    const prevPageName = this.contentfulService.prevPageName ? this.contentfulService.prevPageName : this.contentfulService.customDimensionValue['pageName']
    const event = new ClickStreamEventData(this.contentfulService.customDimensionValue['pageName'], this.contentfulService.domainName + this.router.url, 'ButtonClicked', 'Interaction', 'Prepare', 'Launches test', quiz[0].title, prevPageName)
    this.clickStream.clickstreamLogging(event)
  }

  switchlang (lang) {
    this.langChanged.emit(lang)
  }
  gaTrackDetails (eventCategory, eventAction, eventLabel, parentIndex, urlSlug?) {
    this.gaDetails.emit({ eventCategory, eventAction, eventLabel, parentIndex, urlSlug })
  }
  resizeGridItem (data) {
    if (isPlatformBrowser(this.platformId)) {
      const grid = this.document.getElementsByClassName('masonary-container')[0]
      const allItems = this.document.getElementById(data)
      if (grid && allItems) {
        const rowHeight = parseInt(window.getComputedStyle(grid).getPropertyValue('grid-auto-rows'))
        const rowGap = parseInt(window.getComputedStyle(grid).getPropertyValue('grid-row-gap'))
        const rowSpan = Math.ceil((allItems.querySelector('.menu-items').getBoundingClientRect().height + rowGap) / (rowHeight + rowGap))
        allItems.style.gridRowEnd = 'span ' + rowSpan
      }
    }
  }
}
