<div class="spinner-overlay d-flex justify-content-center align-items-center" *ngIf="isLoading | async">
    <div class="spinner-wrapper d-flex flex-column justify-content-center align-items-center">
        <div class="spinner">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <p class="paragraph clr-black text-center font-opensans">Loading...</p>
    </div>
</div>
<div class="spinner-overlay spinner-quiz-bg d-flex justify-content-center align-items-center" *ngIf="loaderType === 'quiz-results'">
  <div class="spinner-wrapper d-flex flex-column gap48 gap-24-900 justify-content-center align-items-center">
      <div class="spinner">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <p class="quiz-result-loader text-center">Please wait while we process your results</p>
  </div>
</div>
<div *ngIf="loaderType === 'bespokeForm' && isContentLoading" class="overlay-loader w-100 h-100 position-absolute"></div>
<div class="spinner-wrapper content-loader d-flex flex-column justify-content-center align-items-center comp-loader" *ngIf="isContentLoading">
  <div class="spinner">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
  <p class="paragraph clr-black text-center font-opensans">Loading...</p>
</div>

<div  [ngClass]="loaderType === 'infiniteScroll' ? '' : 'spinner-wrapper spinner-small comp-loader'" class="d-flex flex-column justify-content-center align-items-center" *ngIf="isdropdownLoading || loaderType === 'infiniteScroll'">
  <div class="spinner ">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
</div>
  