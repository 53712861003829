import { Inject, Injectable, Injector } from '@angular/core'
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http'
import { catchError, tap } from 'rxjs/operators'
import { DOCUMENT, LocationStrategy, PathLocationStrategy } from '@angular/common'
import { Router } from '@angular/router'
import { Observable } from 'rxjs/internal/Observable'
import { from } from 'rxjs/internal/observable/from'
import { throwError } from 'rxjs/internal/observable/throwError'
import { of } from 'rxjs/internal/observable/of'

@Injectable()
export class AppGlobalHttpInterceptor {
  token: string = ''

  constructor (
    private readonly injector: Injector,
    @Inject(DOCUMENT) private readonly document: any,
    private readonly router: Router) { }

  intercept (req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // convert promise to observable using 'from' operator
    return from(this.handle(req, next))
  }

  // Clone JWT token in the http header
  async handle (req: HttpRequest<any>, next: HttpHandler) {
    return await next.handle(req).pipe(tap(evt => {
      if (evt instanceof HttpResponse) {
        // eslint-disable-next-line no-empty
        if (evt.body && evt.body.status == 200) {
        }
      }
    }), catchError((error) => {
      const location = this.injector.get(LocationStrategy)
      const message = error.message ? error.message : error.toString()
      const url = location instanceof PathLocationStrategy ? location.path() : ''
      let handled = false
      if (error instanceof HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) { /* empty */ } else {
          switch (error.status) {
            case 401:
              handled = false
              break
            case 403:
              handled = false
              break
          }
        }
      }
      if (handled) {
        return of(error)
      } else {
        const customDimensionScript = this.document.createElement('script')
        customDimensionScript.innerHTML = `window.dataLayer = window.dataLayer || [];window.dataLayer.push({"event": "gtm.pageError","gtm.errorMessage": "${message}","gtm.errorUrl":"${url}","gtm.uniqueEventId": ${url.length + 1}});`
        this.document.head.appendChild(customDimensionScript)
        return throwError(error)
      }
    })
    ).toPromise()
  }
}
