export class VideoInfo {
    imageAltText: string
    imageUrl: string
    imageWidth: string
    imageHeight: string
    videoUrl: string
    youtubeUrl: string
    duration: string

    constructor (
      imageAltText: string,
      imageUrl: string,
      imageWidth: string,
      imageHeight: string,
      videoUrl: string,
      youtubeUrl: string,
      duration?: string
    ) {
      this.imageAltText = imageAltText || ''
      this.imageUrl = imageUrl || ''
      this.imageWidth = imageWidth || ''
      this.imageHeight = imageHeight || ''
      this.videoUrl = videoUrl || ''
      this.youtubeUrl = youtubeUrl || ''
      this.duration = duration || ''
    }
  }
