export class WebsiteInfo {
  public type = 'website'
  public sysId: string
  public websiteName: string
  public status: string

  public constructor (
    sysId: string,
    websiteName: string,
    status: string
  ) {
    this.sysId = sysId
    this.websiteName = websiteName
    this.status = status
  }
}
