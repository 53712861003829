import { BrowserModule } from '@angular/platform-browser'
import { NgModule } from '@angular/core'
import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { HttpClientModule } from '@angular/common/http'
import { AppModule } from './app.module'
import { UTILITIES } from './common/utilities/ielts-util'
@NgModule({
  imports: [BrowserModule, AppRoutingModule, HttpClientModule, AppModule],
  providers: [UTILITIES],
  bootstrap: [AppComponent]
})
export class AppBrowserModule {}
