<app-loader></app-loader>
<div *ngIf="isPrepPage" [ngClass]="isNewPrepPage ? 'hide' : ''" class="org-shape"></div>
<app-ticker-tape *ngIf="isTickerVisible && tickerTypeData && headerType != 'diagnostic_header'"
[tickerTypeData]="tickerTypeData" [tickerTapeGaId]="tickerTapeGaId"></app-ticker-tape>
<div class="header-fixed" #myelement *ngIf="!isBxSearchPage">
    <ng-container *ngIf="headerData && (headerData | json) != '{}'">
        <app-header [ngClass]="isNewPrepPage ? 'hide' : ''" [otherDetails]='otherDetails' [headerData]='headerData' [prepHubpage]='isPrepHubPage' [headerType]="headerType"
            [prepPage]='isPrepPage' [mlpPage]='isMlppage' [isResultPage]="isResultPage" [skipLink]="skipLink"
            [isSearchResultPage]="isSearchResultPage"></app-header>
        <app-prep-header *ngIf="isNewPrepPage" [headerData]="headerData" ></app-prep-header>
    </ng-container>
</div>
<div class="body-content" [ngClass]="diagnostic_tool ? 'body-height-auto' : ''" >
    <router-outlet *ngIf="pageAvailable"></router-outlet>
</div>
<div class="back-to-top-wrapper d-flex justify-content-end" *ngIf="!diagnostic_tool">
    <span *ngIf="isShow && pageAvailable" class="back-to-top d-flex align-items-center justify-content-center rounded"
        (click)="gotoTop()"> <span class="chevron-arrow up arrow-md bg-white-force"></span></span>
</div>
<ng-container *ngIf="(footerData | json) != '{}' && !isPrepHubPage && !isBxSearchPage && !diagnostic_tool">
    <app-footer class="app-footer" [hidden]="!isFooterShow" [footerMenu]="footerData"
        [otherDetails]="otherDetails" [isResultPage]="isResultPage"></app-footer>
</ng-container>
<div class="line-chat"
    *ngIf=" (chatValue | json) != '{}' && !isResultPage && !isPrepPage && !isPrepHubPage">
    <a [ngClass]="chatValue['url'].includes('//line.me') ? 'line-chat-container' : 'secondary-chat'" href="{{chatValue['url']}}" target="_blank" rel="noopener">
        <img class="w-100" src="{{chatValue['imageUrl']}}" alt="" width="35" height="35">
        <span *ngIf="chatValue['url'].includes('//line.me')" class="line-chat-cont">Chat with us</span>
    </a>
</div>
