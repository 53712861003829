<div class="footer p-48" id="footersection">
  <div class="footer-mob-nav hide show-900 pb-24-900">
      <ul class="heading6 align-center">
        <!-- attr.data-id -->
        <li *ngFor="let mobNav of footerMenu?.footerNavigation; let i = index" class="link" ngClass="{{i+1 == mobNavPosition ? 'active' : ''}}" 
        attr.data-id="footerLink{{i+1}}" (click)="handleClickLink($event)">{{mobNav.navigationLabel}}</li>
      </ul>
  </div>
  <div class="footer-wrapper">
      <div *ngIf="footerMenu?.footerNavigation && footerMenu?.footerNavigation?.length > 0" class="footer-cont d-flex gap64 justify-content-between">
          <div *ngFor="let footerNav of footerMenu?.footerNavigation; let i = index" class="footer-list snap-target" id="footerLink{{i+1}}">
              <label *ngIf="footerNav?.navigationLabel" class="footer-title heading4 d-flex gap16 flex-column hide-900">{{footerNav.navigationLabel}}</label>
              <ul *ngIf="footerNav?.childNavigation && footerNav?.childNavigation?.length > 0" class="d-flex flex-column gap12">
                  <li *ngFor="let childNav of footerNav?.childNavigation">
                    <a *ngIf="!childNav?.navigationUrl?.includes('://')" [routerLink]="otherDetails['countryUrl'] + childNav?.navigationUrl" 
                    title="{{childNav?.navigationLabel}}" (click)="footerGaTrack(footerNav?.gaID.toLowerCase().split(' ').join('_') + '_footer', childNav?.gaID)"
                    [target]="childNav?.target == 'Open in same tab' ? '_self' : '_blank'">{{childNav?.navigationLabel}}</a>
                    <a *ngIf="childNav?.navigationUrl?.includes('://')" [href]="childNav?.navigationUrl" 
                    title="{{childNav?.navigationLabel}}" (click)="footerGaTrack(footerNav?.gaID.toLowerCase().split(' ').join('_') + '_footer', childNav?.gaID)"
                    [target]="childNav?.target == 'Open in same tab' ? '_self' : '_blank'">{{childNav?.navigationLabel}}</a>
                  </li>
              </ul>
          </div>
      </div>
  </div>
  <div class="footer-secondary d-flex gap64 justify-content-between flex-column-900 gap0-900 pt-64-900">
      <div class="social-list" *ngIf="footerMenu?.footerSocialNavigation && footerMenu?.footerSocialNavigation.length > 0 ">
          <label *ngIf="footerMenu?.footerSocialNavigation[0].navigationLabel" class="footer-title social-title heading4 pb-24-900">{{footerMenu?.footerSocialNavigation[0].navigationLabel}}</label>
          <ul *ngIf="footerMenu?.footerSocialNavigation[0]?.childNavigation && footerMenu.footerSocialNavigation[0].childNavigation.length > 0" class="social-list-wrap d-flex gap24 flex-wrap">
            <ng-container *ngFor="let socialIcon of footerMenu.footerSocialNavigation[0].childNavigation">
              <li *ngIf="socialIcon?.navigationUrl" class="social-list-item">
                <a href="{{socialIcon?.navigationUrl}}" target="_blank" rel="noopener" (click)="eventTrack(socialIcon?.socialId?.toLowerCase(), socialIcon?.navigationUrl)">
                  <img width="32" height="33" [defaultImage]="defaultImage" lazyLoad="{{assetPath}}/assets/home/icons/{{socialIcon?.socialId?.toLowerCase()}}.svg" alt="{{socialIcon?.socialId?.toLowerCase()}}">
                </a>
              </li>
            </ng-container>
          </ul>
      </div>
      <div class="social-list pt-48-900" *ngIf="footerMenu?.subscriptionDetails && (footerMenu.subscriptionDetails | json) != '{}'">
          <label class="footer-title social-title heading4 pb-12-900">{{footerMenu.subscriptionDetails?.ctaCallout}}</label>
          <button class="subscribe button button-primary rounded-full" (click)="footerGaTrack('newsletter_subscribe', 'na', footerMenu.subscriptionDetails?.gaId)">
            {{footerMenu.subscriptionDetails?.primaryCtaLabel}}</button>
      </div>
      <div class="social-list web-country-item pt-48-900">
          <label class="footer-title social-title heading4 pb-12-900">{{titles.website}}</label>
          <div id="countryDropDown" class="footer-lang position-relative" [ngClass]="countryDropDownOpen ? 'active' : ''">
              <div class="d-flex gap8 align-items-center position-relative" (click)="openCountryDrpDown()">
                  <img lazyLoad="{{assetPath}}/assets/footer/footer-web-icon.svg" [defaultImage]="defaultImage" width="18" height="18" alt="">
                  <span class="heading5">{{particularcountry}}</span><span class="chevron-arrow arrow-sm top bg-white-force arrow-icon"></span>
              </div>
                <div class="footer-lang-dropdown position-absolute rounded w-100" [ngClass]="countryDropDownOpen ? 'show' : 'hide'">
                    <ul class="footer-lang-list d-flex flex-column gap24 font-opensans custom-scroll">
                      <li *ngFor="let name of countrynames">
                        <a href="{{ ( name.navigationUrl.indexOf('://') >= 0 ) ? name.navigationUrl : domainUrl + name.navigationUrl }}" (click)="footerGaTrack('website_switch', name.gaID)" target="_blank" rel="noopener">
                          {{name.navigationLabel}}
                        </a>
                      </li>
                    </ul>
                </div>
          </div>
      </div>
  </div>
</div>
<div class="footer-bottom results-footer-bottom p-24-900" *ngIf="isResultPage">
  <div class="d-flex flex-row justify-content-between  flex-column-900 gap12 align-items-start">
         <div class="ielts-partners-img">
          <img lazyLoad="{{assetPath}}/assets/home/ielts-partners.svg" [defaultImage]="defaultImage" height="43" alt="partners">
         </div>
         <div class="ielts-results-partners clr-white font-opensans">
          IELTS is jointly owned by the British Council; IDP IELTS; and Cambridge University Press & Assessment
         </div>
  </div>
</div>
<div class="footer-bottom p-24-900" [ngClass]="isResultPage ? 'results' : ''">
  <div class="d-flex show-900 gap-30 flex-row justify-content-between align-items-center">
      <div class="pb-24-900" *ngIf="footerMenu?.footerLogoNavigation && (footerMenu?.footerLogoNavigation | json) != '{}'">
        <a appDynamicUrl ="{{indiaHomepage}}"
        [routerLink] ="indiaHomepage?.includes('://') ? null : '/' + otherDetails['countryUrl']" title="{{footerMenu?.footerLogoNavigation?.imageAltText}}">
        <img lazyLoad="{{footerMenu?.footerLogoNavigation.imageUrl}}" [defaultImage]="defaultImage" width="157" height="28" title="{{footerMenu?.footerLogoNavigation?.imageAltText}}" alt="{{footerMenu?.footerLogoNavigation?.imageAltText}}" class="footer-bottom-logo">
        </a>
      </div>
      <ul *ngIf="footerBottomNavDetails && footerBottomNavDetails?.length > 0" class="links font-medium d-flex gap32 flex-column-900 gap16-900">
        <ng-container *ngFor="let bottomNav of footerBottomNavDetails">
          <li *ngIf="bottomNav?.navigationUrl">
            <a *ngIf="bottomNav?.navigationUrl?.includes('://'); else noHref" href="{{bottomNav?.fragMentURL ? bottomNav?.navigationUrl + '#' + bottomNav?.fragMentURL : bottomNav?.navigationUrl}}"  title="{{bottomNav?.navigationName}}">{{bottomNav?.navigationName}}</a>
            <ng-template #noHref>
              <a *ngIf="bottomNav?.fragMentURL == ''" [routerLink]="otherDetails['countryUrl'] + bottomNav?.navigationUrl" title="{{bottomNav?.navigationName}}" 
              [target]="bottomNav?.target == 'Open in same tab' ? '_self' : '_blank'">{{bottomNav?.navigationName}}</a>
              <a *ngIf="bottomNav?.fragMentURL != ''" [href]="bottomNav?.navigationUrl + '#' + bottomNav?.fragMentURL " title="{{bottomNav?.navigationName}}" 
              [target]="bottomNav?.target == 'Open in same tab' ? '_self' : '_blank'">{{bottomNav?.navigationName}}</a>
            </ng-template>
          </li>
          <li *ngIf="!bottomNav?.navigationUrl">
            {{bottomNav?.navigationName}}
          </li>
        </ng-container>
      </ul>
  </div>
</div>
<div class="footer-address clr-white p-48 p-24-900" *ngIf="(footerMenu.addressComponentLeft |json) != '{}' || (footerMenu.addressComponentRight | json) != '{}'">
  <span class="brand-name heading4">{{footerMenu.addressComponentLeft?.title}}</span>
  <div class="address-sec d-flex flex-wrap gap24">
      <div class="address_box address-region" [innerHtml]="returnHtmlFromRichText(footerMenu.addressComponentLeft?.longDescription_ref)"></div>
      <div class="address_box" [innerHtml]="returnHtmlFromRichText(footerMenu.addressComponentRight?.longDescription_ref)"></div>
  </div>
</div>
<div *ngIf="subscribePopUpShow">
  <div class="popup_wrapper w-100 h-100">
    <div class="overlay-backdrop d-flex justify-content-center align-items-center" (click)="subscribePopUp(false)">
        <div class="global-overlay-wrapper" (click)="stop($event)">
          <div class="d-flex justify-content-end p-24 close-popup">
              <img width="36" height="36" [defaultImage]="defaultImage" lazyLoad="{{assetPath}}/assets/home/icons/close_black.svg" (click)="subscribePopUp(false)" alt="close button">
          </div>
            <div class="popup-content-wrapper p-24">
              <div class="iframe-container">
                <iframe title="{{footerMenu.subscriptionDetails?.internalName}}" id="desktopIframe" frameborder="0" marginheight="0" marginwidth="0" class="google_form" height="500" [src]="subscriptionURL">Loading…</iframe>
              </div>
            </div>
        </div>
    </div>
</div>
</div>