import { Injectable, Inject } from '@angular/core'
import { DOCUMENT } from '@angular/common'
import { Title, Meta } from '@angular/platform-browser'
import { ImageConstant } from '../../common/utilities/constants'
import { RoutingInitService } from '../../services/routing-init.service'
import { ActivatedRoute } from '@angular/router'
import { SeoService } from '../../services/seo.service.component'

@Injectable()
export class PrepseoService {
  dynamicImagePath = ImageConstant
  public disableRobots
  pageNoData: boolean = false
  constructor (@Inject(DOCUMENT) private readonly doc,
    private readonly title: Title,
    private readonly meta: Meta,
    private readonly routingInitService: RoutingInitService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly seoService: SeoService
  ) {}

  setSeoTitle (pageData): any {
    this.routingInitService.websiteStatus.subscribe((data) => {
      if (data && 'metaTitle' in pageData[0].fields) {
        this.title.setTitle(pageData[0].fields.metaTitle)
      }
    })
  }

  prepResultsnotfound (seoFields): any {
    this.setSeoTitle(seoFields)
    this.setSeoRobots(seoFields)
    this.routingInitService.websiteStatus.subscribe((data) => {
    if (data && 'metaDescription' in seoFields[0]?.fields) {
      this.meta.addTag({ name: 'description', content: seoFields[0]?.fields?.metaDescription })
      }
    })
  }

  setSeoRobots (pageData): any {
    this.routingInitService.websiteStatus.subscribe((data) => {
    if (data && 'robots' in pageData[0].fields) {
      this.meta.updateTag({ name: 'robots', content: pageData[0].fields.robots })
      }
    })
  }

  removeLink (linkName: string): any {
    this.seoService.removeLink(linkName)
  }

  addSeoTags (pageData, preview): any {
    this.routingInitService.websiteStatus.subscribe((data) => {
    if (data) {
    if ('metaDescription' in pageData[0].fields) {
      this.meta.updateTag({ name: 'description', content: pageData[0].fields.metaDescription })
    }
    if ('metaKeywords' in pageData[0].fields) {
      this.meta.updateTag({ name: 'keywords', content: pageData[0].fields.metaKeywords })
    }
    if ('robots' in pageData[0].fields && !preview && pageData[0].fields.robots !== '') {
      this.meta.updateTag({ name: 'robots', content: pageData[0].fields.robots })
    }
    if ('robots' in pageData[0].fields && preview) {
      this.meta.updateTag({ name: 'robots', content: 'noindex,nofollow' })
    }
    if (this.disableRobots === 'Yes') {
      this.meta.updateTag({ name: 'robots', content: 'noindex,nofollow' })
      }
    }
  })
  }

  setSeoCanonical (pageData, preview, domainurl): any {
    this.routingInitService.websiteStatus.subscribe((data) => {
      if (data) {
        this.activatedRoute.queryParamMap.subscribe(
          (queryParams) => {
            if (queryParams.get('page') != null) {
              this.pageNoData = true
            }
          }
        )
        if ('canonical' in pageData[0].fields && !preview) {
          const link: HTMLLinkElement = this.doc.createElement('link')
          link.setAttribute('rel', 'canonical')
          this.doc.head.appendChild(link)
          link.setAttribute('href', domainurl + pageData[0].fields.canonical)
        }
      }
    })
  }

  isLanguageCode (segments): any {
   this.seoService.isLanguageCode(segments)
  }

  setSeoUrl (winHref, preview, urlCode = '', domainURL): any {
    this.seoService.setSeoUrl(winHref, preview, urlCode, domainURL)
  }

  removeTag (attrSelector: string): any {
   this.seoService.removeTag(attrSelector)
  }

  addHrefLang (tag: LinkDefinition, forceCreation?: boolean): any {
    this.seoService.addHrefLang(tag, forceCreation)
  }
}

export declare type LinkDefinition = {
  charset?: string
  crossorigin?: string
  href?: string
  hreflang?: string
  media?: string
  rel?: string
  rev?: string
  sizes?: string
  target?: string
  type?: string
} & {
  [prop: string]: string
}
