<header id="header" class="w-100 header-wrapper position-relative d-flex align-items-center px-48"
  *ngIf="!prepHubpage && !prepPage && !mlpPage && mlpPage != undefined && !isResultPage && isResultPage != undefined &&  headerType !== 'diagnostic_header'">
  <div class="logo" [ngClass]="headerMenuToogle != '' ? 'hide' : 'show'">
    <a class="d-block" appDynamicUrl ="{{indiaHomepage}}"
    [routerLink] ="indiaHomepage?.includes('://') ? null : '/' + otherDetails['countryUrl']" title="IDP IELTS">
      <img width="160" height="28"  src="{{assetPath}}/assets/home/idp_ielts_logo.svg"
        alt="IDP IELTS Logo" />
    </a>
  </div>
  <div *ngIf="innerWidth <= 1024" class="d-flex gap24 align-items-center"
    [ngClass]="headerMenuToogle != '' ? 'show' : 'hide'">
    <span (click)='skipPopup()' class="chevron-arrow left arrow-md"></span>
    <span *ngIf="mobViewParentNavName != ''" class="menu-head heading5">{{mobViewParentNavName}}</span>
  </div>
  <div class="d-flex justify-content-between gap48 w-100 h-100 align-items-center gap24-lg">
    <!--Desktop header left-->
    <ng-container *ngIf="innerWidth > 1024">
      <ul *ngIf="headerData?.headerMenu && headerData?.headerMenu.length > 0"
        class="nav-menu-wrapper d-flex align-items-center gap8 h-100 nav-menu-desktop hide-1024">
        <!-- mega menu 1  -->
        <ng-container *ngFor="let headerInfo of headerMenuValue; let i=index">
          <li (mouseenter)="showHeaderMenu(i+1, 'mouseEnter')" (focusin)="showHeaderMenu(i+1, 'focusIn')"
            class="menu-dropdown child-menu{{i+1}} d-flex align-items-center h-100">
            <a *ngIf="!headerInfo?.headerMenu?.navigationUrl" class="d-flex align-items-center justify-content-center"
              title="{{headerInfo?.headerMenu?.navigationTitle}}">{{headerInfo?.headerMenu?.navigationTitle}}
              <span class="chevron-arrow dropdown-arrow"></span>
            </a>
            <a *ngIf="headerInfo?.headerMenu?.navigationUrl" class="d-flex align-items-center justify-content-center"
              appDynamicUrl="{{headerInfo?.headerMenu?.navigationUrl}}"
              [routerLink]="headerInfo?.headerMenu?.navigationUrl?.includes('://') ? null : otherDetails['countryUrl'] + headerInfo?.headerMenu?.navigationUrl" (click)="headerClicked(i)"
              [target]="headerInfo?.headerMenu?.target == 'Open in same tab' ? '_self' : '_blank'"
              title="{{headerInfo?.headerMenu?.navigationTitle}}">{{headerInfo?.headerMenu?.navigationTitle}}
              <span class="chevron-arrow dropdown-arrow"></span>
            </a>
            <ng-container  *ngIf="headerInfo?.hubpageType === 'IELTS tests'">
              <div class="menu-dropdown-list position-absolute w-100 d-flex custom-scroll menu-tab-wrapper" id="scroll{{i+1}}" (scroll)="onScroll($event,i+1)">
                <div class="menu-dropdown-box heading5">
                  <div class="menu-dropdown-tabs d-flex flex-column gap20">
                    <button *ngFor="let childHeader of headerInfo['nonQuickLink']; let ii = index "
                      (click)="headerL1Clicked(i + 1, '' ,ii)" class="menu-tab clr-grey p-24 px-48"
                      ngClass="{{headerIndex == 'headerL' + (i + 1)  + ii ? 'tab-active' : ''}}">
                      <span> {{childHeader?.navigationLabel}}</span>
                    </button>
                  </div>
                </div>
                <ng-container *ngFor="let subChild1 of headerInfo['nonQuickLink'];  let j = index">
                  <div *ngIf="!subChild1?.quickLinksSection" ngClass="{{headerIndex == 'headerL' +(i + 1)   + j ? 'show' : 'hide'}}"
                    class="menu-dropdown-box heading5 flex2">
                    <app-header-megamenu [countryUrl]="otherDetails['countryUrl']" [childMenu]="subChild1"
                      (gaDetails)="childGaTrack($event)"
                      [otherHeaderDetails]="{'menucount': 'first', 'hubpageType': 'IELTS tests', 'parentTitle': headerInfo?.headerMenu?.gaID, 'parentIndex': i+1 }"></app-header-megamenu>
                  </div>
                </ng-container>
                <div *ngIf="headerInfo['quickLink'].length > 0"
                  class="menu-dropdown-box heading5 quick-link">  
                  <app-header-megamenu [countryUrl]="otherDetails['countryUrl']" [childMenu]=" headerInfo['quickLink'][0]"
                    (gaDetails)="childGaTrack($event)"
                    [otherHeaderDetails]="{'menucount': 'greybox', 'hubpageType': 'IELTS tests', 'parentTitle': headerInfo?.headerMenu?.gaID, 'parentIndex': i+1}"></app-header-megamenu>
                </div>
              </div>
            </ng-container>

            <ng-container *ngIf="headerInfo?.hubpageType === 'Prepare'">
              <div *ngIf="headerInfo?.quickLink || headerInfo.masonryData.length > 0"
                class="menu-dropdown-list position-absolute w-100 d-flex custom-scroll" id="scroll{{i+1}}" (scroll)="onScroll($event,i+1)">
                <div
                  *ngIf="headerInfo['masonryData'].length > 0"
                  class="menu-dropdown-box heading5 flex3">
                  <app-header-megamenu [countryUrl]="otherDetails['countryUrl']" (gaDetails)="childGaTrack($event)"
                    [childMenu]="headerInfo['masonryData']"
                    [otherHeaderDetails]="{'menucount': 'second', 'hubpageType': 'Prepare', 'parentTitle': headerInfo?.headerMenu?.gaID, 'parentIndex': i+1, 'promoCard': headerInfo['promoCard'] }"></app-header-megamenu>
                </div>
                <div
                  *ngIf="headerInfo['quickLink'].length > 0"
                  class="menu-dropdown-box heading5 quick-link">
                  <app-header-megamenu [countryUrl]="otherDetails['countryUrl']" (gaDetails)="childGaTrack($event)"
                    [childMenu]="headerInfo['quickLink'][0]"
                    [otherHeaderDetails]="{'menucount': 'greybox', 'hubpageType': 'Prepare', 'parentTitle': headerInfo?.headerMenu?.gaID, 'parentIndex': i+1}"></app-header-megamenu>
                </div>
              </div>
            </ng-container>

            <ng-container   *ngIf="headerInfo?.hubpageType === 'Results' || headerInfo?.hubpageType === 'IELTS community'">
              <div class="menu-dropdown-list position-absolute w-100 d-flex custom-scroll" id="scroll{{i+1}}" (scroll)="onScroll($event,i+1)">
                <div
                  *ngIf="headerInfo?.hubpageType === 'Results' ||  (headerData?.headerSocialNav) || headerInfo?.hubpageType === 'IELTS community'"
                  class="menu-dropdown-box heading5 flex3">
                  <app-header-megamenu [countryUrl]="otherDetails['countryUrl']" (gaDetails)="childGaTrack($event)"
                    [childMenu]="headerInfo['nonQuickLink']"
                    [otherHeaderDetails]="{'menucount': 'third', 'hubpageType': headerInfo?.hubpageType, 'headerSocialNav': headerData?.headerSocialNav, 'socialShareHeading': headerInfo?.communityMenuSocialShareTitle, 'parentTitle': headerInfo?.headerMenu?.gaID, 'parentIndex': i+1}"></app-header-megamenu>
                </div>
                <div
                  *ngIf="headerInfo['quickLink'].length > 0"
                  class="menu-dropdown-box heading5 quick-link">
                  <app-header-megamenu [countryUrl]="otherDetails['countryUrl']" (gaDetails)="childGaTrack($event)"
                    [childMenu]=" headerInfo['quickLink'][0]"
                    [otherHeaderDetails]="{'menucount': 'greybox', 'hubpageType': headerInfo?.hubpageType, 'parentTitle': headerInfo?.headerMenu?.gaID, 'parentIndex': i+1}"></app-header-megamenu>
                </div>
              </div>
            </ng-container>

            <ng-container  *ngIf="headerInfo?.hubpageType === 'About'">
              <div class="menu-dropdown-list position-absolute w-100 d-flex custom-scroll" id="scroll{{i+1}}" (scroll)="onScroll($event,i+1)">
                <div *ngIf="headerInfo['nonQuickLink'].length > 0"
                  class="menu-dropdown-box heading5 flex3">
                  <app-header-megamenu [countryUrl]="otherDetails['countryUrl']" (gaDetails)="childGaTrack($event)"
                    [childMenu]="headerInfo['nonQuickLink']"
                    [otherHeaderDetails]="{'menucount': 'fourth', 'hubpageType': headerInfo?.hubpageType, 'parentTitle': headerInfo?.headerMenu?.gaID, 'parentIndex': i+1}"></app-header-megamenu>
                </div>
                <div *ngIf="headerInfo['quickLink'].length > 0"
                  class="menu-dropdown-box heading5 grey-box quick-link">
                  <app-header-megamenu [countryUrl]="otherDetails['countryUrl']" (gaDetails)="childGaTrack($event)"
                    [childMenu]="headerInfo['quickLink'][0]"
                    [otherHeaderDetails]="{'menucount': 'greybox', 'hubpageType': headerInfo?.hubpageType, 'parentTitle': headerInfo?.headerMenu?.gaID, 'parentIndex': i+1}"></app-header-megamenu>
  
                </div>
              </div>
            </ng-container>
          </li>
        </ng-container>
      </ul>
    </ng-container>
    <!--Desktop header left ends-->
    <!-- header right  -->
    <div
      class="header-right d-flex align-items-center justify-content-end gap16 h-100 m-full-width-1024 position-relative"
      [ngClass]="headerToggle ? 'header-open':''">
      <!--language dropdown-->
      <div id="langDropdown" *ngIf="showLanguageDropdown && !isSearchResultPage" (click)="langDropDown()"
        class="right-sec change-lang d-flex align-items-center position-relative h-100 hide-500"
        [ngClass]="languagedropdown ? 'active' : '' ">
        <button class="right-item d-flex align-items-center">
          <img class="right-item-text" width="24" height="24" loading="lazy" 
          src="{{assetPath}}/assets/home/icons/language.svg"
            alt="language"><span>{{languageSelectorDisplay}}</span><span class="chevron-arrow bg-primary-force"></span>
        </button>
        <div class="language-popup position-absolute" [ngClass]="languagedropdown ? 'show' : 'hide' ">
          <ul class="lang-list d-flex flex-column gap24 font-opensans">
            <li *ngFor="let lang of languageArray" [ngClass]="{'lang-active':lang.code==languageSelector}"
              (click)="switchLanguage(lang)">
              <a title="{{lang.displayTitle}}">{{lang.displayTitle}}</a>
            </li>
          </ul>
        </div>
      </div>
      <!--language dropdown ends-->
      <button *ngIf="isSearchEnabled" title="search" class="right-sec right-item search d-flex align-items-center"
        (click)="openPopup()">
        <img width="24" height="24" loading="lazy"  src="{{assetPath}}/assets/home/icons/search.svg" alt="search-icon"><span
          class="right-item-text">{{titles?.search}}</span>
      </button>
      <!-- <button title="signin" class="right-sec right-item d-flex align-items-center hide-500">
          <img width="24" height="24" src="{{assetPath}}/assets/home/icons/profile.svg" alt="profile">
          <span class="right-item-text">Sign in</span>
        </button> -->
      <div class="signin-wrapper position-relative h-100 user-signin" *ngIf="indiaWebsite">
        <button id="signindropdown" [title]="loginDetails ? 'My Account' : 'Sign In'" class="right-sec right-item d-flex align-items-center" (click)="signInDropdown()">
          <ng-container *ngIf="loginDetails">
            <a [href]="loginDetails?.redirectUrl" class="d-flex align-items-center justify-content-center login-avatar rounded-full heading6">{{loginDetails?.nameFirstLetter}}</a>
            <span class="chevron-arrow bg-primary-force hide-500"></span>
          </ng-container>
          <ng-container *ngIf="!loginDetails && isClient">
            <div class="d-flex align-items-center gap8">
              <img (click)="innerWidth <= 1024 ? directClick() : ''" width="24" height="24" src="{{assetPath}}/assets/home/icons/profile.svg" alt="profile">
              <span class="right-item-text">Sign In</span>
              <span class="chevron-arrow dropdown-arrow right-item-text"></span>
            </div>
          
          </ng-container>
        </button>
        <div class="language-popup position-absolute signin-popup hide-1024 hide">
          <ul class="lang-list d-flex flex-column gap16 font-opensans">
            <ng-container *ngIf = "!loginDetails; else loggedIn">
              <li *ngFor="let login of loginTypes" >
                <a [href]="login.navigationUrl" class="d-flex gap8 align-items-center" title="{{login.displayValue}}"><img width="24"
                    height="24" src="{{assetPath}}/assets/icons/{{login.imageUrl}}"
                    alt="profile"><span>{{login.displayValue}}</span></a>
              </li>
            </ng-container>
            <ng-template #loggedIn>
              <li>
                <a [href]="loginDetails?.redirectUrl" class="d-flex gap8 align-items-center" title="My account"><img width="24"
                    height="24" src="{{assetPath}}/assets/icons/profile.svg"
                    alt="profile"><span>My account</span></a>
              </li>
              <li>
                <a [href]="loginDetails?.logoutUrl" class="d-flex gap8 align-items-center" title="Logout"><img width="24"
                    height="24" src="{{assetPath}}/assets/icons/exit-outline.svg"
                    alt="profile"><span>Logout</span></a>
              </li>
            </ng-template>
          </ul>
        </div>
      </div>
      <!--Book now button-->
      <a *ngIf="!skipLink" class="right-sec book-btn button button-primary rounded-full" href="{{bookingURL}}" target="_blank"
      rel="noopener" (click)="gaTrackHeader(bookingURL, 'book_now')">
      {{headerData.bookatest}} </a>
    <a class="right-sec book-btn button button-primary rounded-full" *ngIf="skipLink" (click)="booknowScroll()">
      {{headerData.bookatest}} </a>
      <!--Book now button ends-->
      <!-- hamburger with menu  -->
      <div *ngIf="innerWidth <= 1024" class="right-sec mobile-menu">
        <div class="hamburger-icon d-flex align-items-center">
          <label class="menulabel" for="menuicon">mnu</label>
          <input type="checkbox" id="menuicon" [checked]="ishamburgerVisible" (click)="headerTooglee()">
          <a title="open menu" id="burgerMenu" class="toggle-menu">
            <span></span>
            <span></span>
            <span></span>
          </a>
          <!-- mob nav wrapper  -->
          <nav class="mob-nav-menu" [ngClass]="showMobNav ? 'show' : 'hide'"> <!-- -->
            <section class="d-flex flex-column justify-content-between h-100"
              [ngClass]="headerMenuToogle != '' ? 'hide' : 'show'">
              <div class="mob-top-menu d-flex flex-column px-20-1024 py-24-1024 px-48-901-1024">
                <ul class="nav-menu-list d-flex flex-column gap32 heading5">
                  <ng-container *ngIf="headerMenuValue.length">
                    <li class="nav-list-item d-flex gap8 align-items-center justify-content-between"
                      *ngFor="let headerInfo of headerMenuValue; let i=index"
                      (click)="!headerInfo?.headerMenu?.navigationUrl ? menuItem(headerInfo, i) : ''">
                      <ng-container *ngIf="headerInfo?.headerMenu?.navigationUrl">
                        <span><a *ngIf="headerInfo?.headerMenu?.navigationUrl" appDynamicUrl="{{headerInfo?.headerMenu?.navigationUrl}}"
                          [routerLink]="headerInfo?.headerMenu?.navigationUrl?.includes('://') ? null : otherDetails['countryUrl'] + headerInfo?.headerMenu?.navigationUrl" [target]="headerInfo?.headerMenu?.target == 'Open in same tab' ? '_self' : '_blank'"
                          title="{{headerInfo?.headerMenu?.navigationTitle}}"
                          (click)="showMobNav ? menuItem(headerInfo, i) : ''">{{headerInfo?.headerMenu?.navigationTitle}}</a></span>
                        <span class="chevron-arrow right" (click)="menuItem(headerInfo, i)"></span>
                      </ng-container>
                      <ng-container *ngIf="!headerInfo?.headerMenu?.navigationUrl">
                        <span>{{headerInfo?.headerMenu?.navigationTitle}}</span>
                        <span class="chevron-arrow right"></span>
                      </ng-container>
                    </li>
                  </ng-container>
                  <li *ngIf="languageArray && languageArray.length > 0"
                    class="nav-list-item d-flex gap8 align-items-center justify-content-between hide flex-500"
                    (click)="langItem()">
                    <span class="d-flex gap8 align-items-center">
                      <span>Language</span>
                    </span>
                    <span class="chevron-arrow right"></span>
                  </li>
                </ul>
              </div>
              <div class="mob-bottom-menu d-flex flex-column-500 px-20-1024 py-24-1024 px-48-901-1024 flex-wrap">
                <h5  class="heading5 clr-black user-title" *ngIf="indiaWebsite && loginDetails?.name">Welcome {{loginDetails?.name}},</h5>
                <a title="headerData.bookatest" class="book-btn button button-primary rounded-full" href="{{bookingURL}}"
                  target="_blank" rel="noopener" (click)="gaTrackHeader(bookingURL, 'book_now')">
                  {{headerData.bookatest}} </a>
                <a *ngIf="indiaWebsite" (click)="signIn()" [title]="loginDetails ? 'Sign out' : 'Sign In'" class="book-btn button button-outline-grey rounded-full">{{loginDetails ? 'Sign out' : 'Sign In'}}</a>
              </div>
            </section>

            <ng-container *ngFor="let headerInfo of headerMenuValue; let i=index">
              <section *ngIf="headerInfo?.hubpageType === 'IELTS tests'"
                class="mob-menu-1 d-flex flex-column justify-content-between h-100"
                [ngClass]="(headerMenuToogle === headerInfo?.hubpageType + i) ? 'show' : 'hide'">
                <div class="mob-menus">
                  <div id="{{headerInfo?.hubpageType}}{{i}}" class="menu-dropdown px-20-1024 py-16-1024 px-48-901-1024">
                    <div class="dropdown-item position-relative" [ngClass]="showDropdown ? 'active' : ''">
                      <button class="dropdown-option heading6 d-flex rounded align-items-center justify-content-between gap8 w-100"
                        (click)="showDropdown=!showDropdown">
                        <span class="dropdown-text">{{testMenuchildmobView}}</span>
                        <span class="chevron-arrow position-relative"></span>
                      </button>
                      <ul class="dropdown-list position-absolute w-100 rounded overflow-hidden heading6" *ngIf="showDropdown">
                        <li *ngFor="let value of headerInfo['nonQuickLink']; let j = index "
                          (click)="headerL1Clicked(j, value?.navigationLabel)"
                          class="drpdwn-lst-itm d-flex align-items-center gap16 font-medium">
                          <span class="custom-label">{{value?.navigationLabel}}</span>
                        </li>
            
                      </ul>
                    </div>
                  </div>
                  <ng-container *ngIf="headerInfo['nonQuickLink']">
                    <div *ngFor="let headerList of headerInfo['nonQuickLink'];  let j = index"
                      ngClass="{{headerIndex == 'headerL1' + j ? 'show' : 'hide'}}" class="menu-dropdown-box heading5">
                      <app-header-megamenu [countryUrl]="otherDetails['countryUrl']" [childMenu]="headerList"
                        (gaDetails)="childGaTrack($event)"
                        [otherHeaderDetails]="{'menucount': 'first', 'hubpageType': 'IELTS tests', 'parentTitle': mobViewParentNameGaID}"></app-header-megamenu>
                    </div>
                    <div *ngIf="headerInfo['quickLink']" class="menu-dropdown-box heading5 quick-link">
                      <app-header-megamenu [countryUrl]="otherDetails['countryUrl']" [childMenu]="headerInfo['quickLink'][0]"
                        (gaDetails)="childGaTrack($event)"
                        [otherHeaderDetails]="{'menucount': 'greybox', 'hubpageType': 'IELTS tests', 'parentTitle': mobViewParentNameGaID}"></app-header-megamenu>
                    </div>
                  </ng-container>
                </div>
              </section>
              <section *ngIf="headerInfo?.hubpageType === 'Prepare'"
                class="mob-menu-2 d-flex flex-column justify-content-between h-100"
                [ngClass]="(headerMenuToogle === headerInfo?.hubpageType + i) ? 'show' : 'hide'">
                <div class="mob-menus">
                  <div *ngIf="headerInfo['masonryData'].length" class="menu-dropdown-box heading5">
                    <app-header-megamenu [countryUrl]="otherDetails['countryUrl']" [childMenu]="headerInfo['masonryData']"
                      (gaDetails)="childGaTrack($event)"
                      [otherHeaderDetails]="{'menucount': 'second', 'hubpageType': 'Prepare', 'parentTitle': mobViewParentNameGaID, 'promoCard': headerInfo['promoCard']}"></app-header-megamenu>
                  </div>
                  <div *ngIf="headerInfo['quickLink'].length" class="menu-dropdown-box heading5 quick-link">
                    <app-header-megamenu [countryUrl]="otherDetails['countryUrl']" [childMenu]="headerInfo['quickLink'][0]"
                      (gaDetails)="childGaTrack($event)"
                      [otherHeaderDetails]="{'menucount': 'greybox', 'hubpageType': 'Prepare', 'parentTitle': mobViewParentNameGaID}"></app-header-megamenu>
                  </div>
                </div>
              </section>
              <section *ngIf="(headerInfo?.hubpageType === 'Results')"
                class="mob-menu-3 d-flex flex-column justify-content-between h-100"
                [ngClass]="(headerMenuToogle === headerInfo?.hubpageType + i) ? 'show' : 'hide'">
                <div class="mob-menus">
                  <div *ngIf="headerInfo['nonQuickLink'].length" class="menu-dropdown-box heading5">
                    <app-header-megamenu [countryUrl]="otherDetails['countryUrl']" (gaDetails)="childGaTrack($event)"
                      [childMenu]="headerInfo['nonQuickLink']"
                      [otherHeaderDetails]="{'menucount': 'third', 'hubpageType': 'Results', 'headerSocialNav': headerData?.headerSocialNav, 'socialShareHeading': headerInfo?.communityMenuSocialShareTitle, 'parentTitle': mobViewParentNameGaID}"></app-header-megamenu>
                  </div>
                  <div *ngIf="headerInfo['quickLink'].length" class="menu-dropdown-box heading5 quick-link">
                    <app-header-megamenu [countryUrl]="otherDetails['countryUrl']" (gaDetails)="childGaTrack($event)"
                      [childMenu]="headerInfo['quickLink'][0]"
                      [otherHeaderDetails]="{'menucount': 'greybox', 'hubpageType': 'Results', 'parentTitle': mobViewParentNameGaID}"></app-header-megamenu>
                  </div>
                </div>
              </section>
              <section *ngIf="(headerInfo?.hubpageType === 'IELTS community')"
                class="mob-menu-3 d-flex flex-column justify-content-between h-100"
                [ngClass]="(headerMenuToogle === headerInfo?.hubpageType + i) ? 'show' : 'hide'" >
                <div class="mob-menus">
                  <div *ngIf="headerInfo['nonQuickLink'].length" class="menu-dropdown-box heading5">
                    <app-header-megamenu [countryUrl]="otherDetails['countryUrl']" (gaDetails)="childGaTrack($event)"
                      [childMenu]="headerInfo['nonQuickLink']"
                      [otherHeaderDetails]="{'menucount': 'third', 'hubpageType': 'IELTS community', 'headerSocialNav': headerData?.headerSocialNav, 'socialShareHeading': headerInfo?.communityMenuSocialShareTitle, 'parentTitle': mobViewParentNameGaID}"></app-header-megamenu>
                  </div>
                  <div *ngIf="headerInfo['quickLink'].length" class="menu-dropdown-box heading5 quick-link">
                    <app-header-megamenu [countryUrl]="otherDetails['countryUrl']" (gaDetails)="childGaTrack($event)"
                      [childMenu]="headerInfo['quickLink'][0]"
                      [otherHeaderDetails]="{'menucount': 'greybox', 'hubpageType': 'IELTS community', 'parentTitle': mobViewParentNameGaID}"></app-header-megamenu>
                  </div>
                </div>
              </section>
            
              <section *ngIf="(headerInfo?.hubpageType === 'About')"
                class="mob-menu-4 d-flex flex-column justify-content-between h-100"
                [ngClass]="(headerMenuToogle === headerInfo?.hubpageType + i) ? 'show' : 'hide'">
                <div class="mob-menus">
                  <div *ngIf="headerInfo['nonQuickLink'].length" class="menu-dropdown-box heading5">
                    <app-header-megamenu [countryUrl]="otherDetails['countryUrl']" [childMenu]="headerInfo['nonQuickLink']"
                      (gaDetails)="childGaTrack($event)"
                      [otherHeaderDetails]="{'menucount': 'fourth', 'hubpageType': 'About', 'parentTitle': mobViewParentNameGaID}"></app-header-megamenu>
                  </div>
                  <div *ngIf="headerInfo['quickLink']" class="menu-dropdown-box heading5 quick-link">
                    <app-header-megamenu [countryUrl]="otherDetails['countryUrl']" [childMenu]="headerInfo['quickLink'][0]"
                      (gaDetails)="childGaTrack($event)"
                      [otherHeaderDetails]="{'menucount': 'greybox', 'hubpageType': 'About', 'parentTitle': mobViewParentNameGaID}"></app-header-megamenu>
                  </div>
                </div>
              </section>
            </ng-container>
            
            <section class="mob-menu-6 d-flex flex-column justify-content-between h-100"
              [ngClass]="headerMenuToogle == 'lang_item' ? 'show' : 'hide'">
              <div class="mob-menus">
                <div *ngIf="languageArray && languageArray.length > 0" class="menu-language-box heading5">
                  <app-header-megamenu (langChanged)="mobViewLangSwitched($event)" (gaDetails)="childGaTrack($event)"
                    [childMenu]='languageArray' [languageSelector]='languageSelector'
                    [otherHeaderDetails]="{'menucount': 'languageList'}"></app-header-megamenu>
                </div>
              </div>
            </section>
            <section class="mob-menu-7 d-flex flex-column justify-content-between h-100"
              [ngClass]="headerMenuToogle == 'user_item' ? 'show' : 'hide'">
              <ul class="account-wrapper py-24-1024 px-20-1024 d-flex flex-column gap20 px-48-901-1024">
                <a href="{{login.navigationUrl}}" *ngFor="let login of loginTypes "
                  class="account-item p-16 d-flex align-items-center gap16">
                  <img width="24" height="24" src="{{assetPath}}/assets/icons/{{login.imageUrl}}" alt="profile">
                  <span class="flex1 font-opensans">{{login.displayValue}}</span>
                  <span class="chevron-arrow right bg-primary-force"></span>
                </a>
              </ul>
            </section>
          </nav>
          <!-- mob nav wrapper ends -->
        </div>
      </div>
      <!-- hamburger with menu ends -->
    </div>
    <!-- header right  ends-->

  </div>
</header>

<ng-container *ngIf="opensearch">
  <app-search-popup [otherDetails]="otherDetails" (closePopup)='closePopup()'></app-search-popup>

</ng-container>

<ng-container *ngIf="(!prepHubpage && !prepPage && mlpPage) || isResultPage || headerType === 'diagnostic_header'">
  <header class="mlp-header header-wrapper px-48 py-16 d-flex justify-content-center align-items-center w-100"
    [ngClass]="bookNowDisable ? '': 'book-now-enabled'">
    <div class="logo flex1 d-flex align-items-center justify-content-center" [ngClass]="headerType === 'diagnostic_header' ? 'diagnostic-logo': ''">
      <a class="hide enable-navigation" appDynamicUrl ="{{indiaHomepage}}" [routerLink] ="indiaHomepage?.includes('://') ? null :  otherDetails['preparationToolStarted'] ?  otherDetails['preparationToolStarted']  : '/' "  title="IDP IELTS">
        <img width="160" height="28" src="{{assetPath}}/assets/home/idp_ielts_logo.svg"
          alt="IDP IELTS Logo" />
      </a>
      <a  class="cursor-initial hide diable-navigation" title="IDP IELTS">
        <img width="160" height="28" src="{{assetPath}}/assets/home/idp_ielts_logo.svg"
          alt="IDP IELTS Logo" />
      </a>
    </div>
    <!-- mlp-right  -->
    <div class="d-flex align-items-center gap24">
      <!--language dropdown-->
      <div id="langDropdown" *ngIf="showLanguageDropdown && !isSearchResultPage" (click)="langDropDown()"
        class="right-sec change-lang d-flex align-items-center position-relative h-100"
        [ngClass]="languagedropdown ? 'active' : '' ">
        <button class="right-item d-flex align-items-center">
          <img class="right-item-text" width="24" height="24" loading="lazy" 
          src="{{assetPath}}/assets/home/icons/language.svg"
            alt="language"><span>{{languageSelectorDisplay}}</span><span class="chevron-arrow"></span>
        </button>
        <div class="language-popup position-absolute" [ngClass]="languagedropdown ? 'show' : 'hide' ">
          <ul class="lang-list d-flex flex-column gap24 font-opensans">
            <li *ngFor="let lang of languageArray" [ngClass]="{'lang-active':lang.code==languageSelector}"
              (click)="switchLanguage(lang)">
              <a title="{{lang.displayTitle}}">{{lang.displayTitle}}</a>
            </li>
          </ul>
        </div>
      </div>
      <!--language dropdown ends-->
      <!--Book now button-->
      <a class="right-sec book-btn button button-primary rounded-full" *ngIf="!bookNowDisable && !skipLink" href="{{bookingURL}}" target="_blank" rel="noopener" (click)="gaTrackHeader(bookingURL, 'book_now')">
        {{headerData.bookatest}} </a>
     <a class="right-sec book-btn button button-primary rounded-full" *ngIf="!bookNowDisable && skipLink" (click)="booknowScroll()">
        {{headerData.bookatest}} </a>
      <!--Book now button ends-->
    </div>
    <div class="align-items-center cursor-pointer quiz-close hide" (click)="closeQuiz()">
      <img width="32" height="32" lazyLoad="{{assetPath}}/assets/icons/close_black.svg" class="closeicon" alt="close button">
    </div>
  </header>
</ng-container>

<ng-container *ngIf="!prepHubpage && prepPage && !mlpPage && !isResultPage">
  <div class="header_prp">
    <div class="pr_container">
      <div class="logo_prp">
        <a routerLink="/prepare"  (click)="clearLocalStorage()" no-target="">
          <img width="253" height="35" src="https://image.ielts.idp.com/assets/prep-hub-img/prp_logo.svg" alt="logo" /></a>
        <div class="asd_hdr">
          <a class="bktohm_pg" href="{{indiaHomepage || otherDetails['countryUrl'] }}" title="IDP IELTS" (click)="clearLocalStorage()">Go to ielts.idp.com</a>
          <ul class="navicn">
            <li (click)="flterFunc()"><img width="16" height="15"  loading="lazy" src="https://image.ielts.idp.com/assets/prep-hub-img/fltr_mb.svg" alt="ic"></li>
          </ul>
          <div class="rgtsde_icn">
            <ng-container>
              <a class="bkats" href="{{prepBookNow}}" target="_blank" rel="noopener" (click)="gaTrackHeader(prepBookNow, 'book_now')">
                {{headerData.bookatest}} </a>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
    <a (click)="gaTrackHeader(prepBookNow, 'book_now', 'prepFloating')" href="{{prepBookNow}}" target="_blank" rel="noopener" class="fxd_bka_tst btn-drkmd ng-tns-c44-0 ng-star-inserted"> {{headerData.bookatest}}</a>
  </div>
  <div class="goielts_prp"  (click)="clearLocalStorage()">
    <a href="{{indiaHomepage ||  otherDetails['countryUrl'] }}"  title="IDP IELTS" (click)="clearLocalStorage()">Go to ielts.idp.com</a>
  </div>
</ng-container>