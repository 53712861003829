import { CommonModule, DOCUMENT } from '@angular/common'
import { Component, Inject, Input, Output, EventEmitter } from '@angular/core'
import { ActivatedRoute, Router, RouterModule } from '@angular/router'
import { ImageConstant, indiaDomain } from '../../common/utilities/constants'
import { ClickStreamEventData } from '../../common/models/clickStream-info'
import { ClickStreamService } from '../../services/click-stream.service'
import { ContentfulService } from '../../services/contentful.service'
import { LazyLoadImageModule } from 'ng-lazyload-image'
import { CookieBackendService } from '../../services/cookie-backend.service'
import { DynamicUrlDirective } from '../../common/directives/dynamic-url.directive'

@Component({
  standalone: true,
  imports: [CommonModule, RouterModule, LazyLoadImageModule, DynamicUrlDirective],
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.css']
})
export class NotFoundComponent {
  public dynamicImagePath = ImageConstant
  countryUrl: any
  @Input() otherDetails
  @Input() pageNotFound
  @Input() searchKey: any
  @Input() recordNotFound
  @Output() clearFilter: EventEmitter<any> = new EventEmitter()
  defaultImage = ImageConstant + '/assets/img_px.gif'
  sectionName: string
  functionalityName: string
  titles: any
  isIndiaWebsite: boolean
  indiaHomepage: string

  constructor (
    private readonly route: ActivatedRoute,
    @Inject(DOCUMENT) private readonly document: Document,
    public readonly clickStream: ClickStreamService,
    public readonly contentful: ContentfulService,
    private readonly router: Router,
    private readonly cookieService: CookieBackendService

  ) { }

  ngOnInit (): any {
    this.titles = this.contentful?.translatedData
    this.route.url.subscribe((element) => {
      this.countryUrl = this.route.snapshot.paramMap.get('country_name') != null ? this.route.snapshot.paramMap.get('country_name') : ''
    })
    if (this.otherDetails && this.cookieService?.get('Client_Country_Code') === 'IN' && this.otherDetails['countryUrl'] === '') {
      this.indiaHomepage = 'https://' + indiaDomain
    }
    this.isIndiaWebsite = this.contentful.checkIndiawebsite()
  }
  refineYourSearch () {
    this.clearFilter.emit()
    if (this.otherDetails?.['pageName'] == 'Events Listing Page') {
      this.sectionName = 'Events list'
      this.functionalityName = 'IELTS Events'
    }
    const prevPageName = this.contentful.prevPageName ? this.contentful.prevPageName : this.otherDetails?.pageName
    const event = new ClickStreamEventData(this.otherDetails?.['pageName'], this.contentful.domainName + this.router.url, 'ButtonClicked', 'Interaction', 'Events list', 'IELTS Events', 'Refine your search', prevPageName)
    this.clickStream.clickstreamLogging(event)
  }
  ngOnDestroy (): any {
    this.document.body.classList.remove('error-page')
  }
}
