<section class="error-page px-48 py-40 p-24-900 pb-32-900" [ngClass]="pageNotFound ? 'page-error' : ''">
    <div class="container d-flex flex-column">
        <div class="error-wrapper d-flex flex-column align-items-center justify-content-center gap32">
           <div class="error-image">
            <img width="163" height="200" [defaultImage]="defaultImage" lazyLoad="{{dynamicImagePath}}/assets/er_icn_srch.svg" alt="404">
           </div>
           <div class="error-content text-center" *ngIf="pageNotFound">
            <h1 class="heading1 clr-black">{{titles.pagenotfound.sorrymessage}}</h1>
            <p class="heading4">{{titles.pagenotfound.linkfollowed}}</p>
           </div>
           <div class="error-content text-center" *ngIf="recordNotFound">
            <h1 class="heading1 clr-black">Uh oh!</h1>
            <p class="heading4">We couldn't find any content with these filters. Please try a broader search.</p>
           </div>
           <div class="error-content text-center" *ngIf="searchKey?.length">
            <h1 class="heading1 clr-black">Your search {{searchKey}} returned no results</h1>
            <p class="heading4 ">{{titles.searchResults.trydifferentsearch}}</p>
            </div>
           <div class="d-flex justify-content-center">
            <a appDynamicUrl ="{{indiaHomepage}}"
            [routerLink] ="indiaHomepage?.includes('://') ? null : '/' + countryUrl" class="refine-btn button button-primary rounded-full" *ngIf="pageNotFound && !isIndiaWebsite">{{titles?.pagenotfound?.gotohomepage}}</a>
            <a href="/" class="refine-btn button button-primary rounded" *ngIf="pageNotFound && isIndiaWebsite">{{titles?.pagenotfound?.gotohomepage}}</a>
            <button (click)="refineYourSearch()" class="refine-btn button button-primary rounded-full" *ngIf="recordNotFound">{{titles?.pagenotfound?.refineyoursearch}}</button>
            </div>
        </div>
    </div>
</section>


