/**
   * @see Files that contans the shared variable that used acess the websites
   * @author Selvakumar balraj
   * @version 1.0
   */
import { environment } from '../../../environments/environment'
export class UTILITIES {
  ENVIRONMENT = 'stage'
  AWS_ENDPOINT_URL = environment.awsEndPointUrl
  GET_METHOD_AWS_ENDPOINT_URL = environment.getMethodAwsEndPointUrl
  HEADER_FOOTER_API = environment.HEADER_FOOTER_API
  ARTICLE_GET_URL = environment.ARTICLE_GET_URL
  RELATED_ARTICLE = environment.RELATED_ARTICLE
  DYNAMIC_PAGE_URL = environment.DYNAMIC_PAGE_URL
  LISTING_DATA = environment.LISTING_DATA
  imageDomainUrl = environment.imageDomain
  userIp_URL = environment.userIPUrl
  domainUrl = environment.domainUrl
  testCenterDetailUrl = environment.testCenterDetailUrl
  rolistingURL = environment.RO_LIST_URL
  rodetailURL = environment.RO_DETAIL_URL
  resultApiURL = environment.resultApiURL
  cityLandingURL = environment.city_landing_get_url
  sessionsListUrl = environment.session_List_Url_v1
  session_List_Url_v2 = environment.session_List_Url_v2
  MLP_PAGE = environment.MLP_PAGE
  homeContentType = 'homePage'
  hubContentType = 'iaHub'
  topicContentType = 'iaTopicPage'
  roContentType = 'iaRecommendedOrganizations'
  articleContentType = 'iaArticlePage'
  supportToolsPage = 'iaSupportTools'
  contactUspage = 'iaContactUs'
  articleLanding = 'iaArticleLanding'
  cityLandingPage = 'iaCityLandingLanding'
  testCenterDetailIa = 'iaTestCentreDetail'
  googleReview_URL = environment.googleReviewService
  languageVersion = environment.languageVersion
  xApiKey = environment.xApiKey
  leadgenURL = environment.leadgenURL
  prepUrl = environment.prepUrl
  HOME_GET_URL = environment.HOME_GET_URL
  HUB_TOPIC_URL = environment.HUB_TOPIC_URL
  preparePageDataUrl = environment.preparePageDataUrl
  prepareSearchApi = environment.prepareSearchApi
  search_get_Url = environment.search_get_Url
  clickstreamUrl = environment.clickstreamUrl
  clickstreamApiKey = environment.clickStreamApiKey
  bespokeFormUrl = environment.bespokeFormUrl
  resultsPageDataUrl = environment.resultsPageDataUrl
  getUserResultUrl = environment.getUserResultUrl
  indiaSiteBanner = environment.indiaSiteBanner
  prepareListingUrl = environment.prepareListingUrl
  bxSearchUrl = environment.bxSearchDomain
  environmentName = environment.environmentName
}
