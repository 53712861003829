import { Component, Input, OnInit, Inject, PLATFORM_ID, HostListener, Renderer2 } from '@angular/core'
import { DOCUMENT, isPlatformServer } from '@angular/common'
import { ActivatedRoute, NavigationStart, Router } from '@angular/router'
import { ContentfulService } from '../../services/contentful.service'
import { RoutingInitService } from '../../services/routing-init.service'
import { ClickStreamEventData } from '../../common/models/clickStream-info'
import { ClickStreamService } from '../../services/click-stream.service'
import { ImageConstant, indiaDomain } from '../../common/utilities/constants'
import { CookieBackendService } from '../../services/cookie-backend.service'
import { IndiaLoginDetails } from '../../common/models/header-details'
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  @Input() headerData
  @Input() prepPage
  @Input() prepHubpage
  @Input() otherDetails
  @Input() mlpPage
  @Input() isResultPage
  @Input() isSearchResultPage
  @Input() headerType
  prepHubTypeHeaderData = { masonryData: [], quickLink: [] }
  resultHubTypeHeaderData = { nonQuickLink: [], quickLink: [] }
  communityHubTypeData = { nonQuickLink: [], quickLink: [] }
  aboutHubTypeData = { nonQuickLink: [], quickLink: [] }
  ieltsTestHubTypeData
  headerToggle = false
  headerMenuToogle = ''
  opensearch = false
  languagedropdown = false
  signindropdown = false
  headerIndex: string = 'headerL10'
  searchCountryUrl: any
  showDropdown: boolean = false
  languageArray = []
  showLanguageDropdown = false
  languageSelector: any
  languageSelectorDisplay = ''
  matchedLang
  checkNonEnglish = false
  countryCode: any
  mobViewParentNavName = ''
  mobViewParentNameGaID = ''
  testMenuchildmobView
  innerWidth: any
  bookingURL: any
  showMobNav = false
  ishamburgerVisible = false
  communityMenuSocialShareTitle
  bookNowDisable: boolean
  isSearchEnabled = false
  assetPath = ImageConstant + '/IELTS_Website_Rebrand'
  // defaultImage = ImageConstant + '/assets/img_px.gif'
  loginTypes = [{ displayValue: 'Candidate Login', navigationUrl: '/access/candidatelogin', imageUrl: 'profile.svg' },
  { displayValue: 'Branch Login', navigationUrl: '/access/branchlogin', imageUrl: 'business.svg' },
  { displayValue: 'Node Login', navigationUrl: '/access/nodelogin', imageUrl: 'building-office.svg' }]
  isLoggedInUser: boolean
  loginDetails
  isClient: boolean
  loginTriggeredirectly: boolean
  indiaWebsite: boolean
  titles: any
  headerMenuValue = []
  @Input() skipLink
  indiaHomepage: any
  prepBookNow = ''
  @HostListener('window:resize', ['$event'])
    getScreenSize () {
          this.innerWidth = window.innerWidth
          if (this.innerWidth > 1024) {
            this.headerMenuToogle = ''
          }
  }
  constructor (
    private readonly router: Router,
    private readonly routingInitService: RoutingInitService,
    private readonly route: ActivatedRoute,
    @Inject(DOCUMENT) private readonly document: any,
    public readonly contentfulService: ContentfulService,
    private _CookieServiceService: CookieBackendService,
    private readonly clickStream: ClickStreamService,
    private readonly renderer: Renderer2,
    @Inject(PLATFORM_ID) private readonly platformId: Object) {
      if (!isPlatformServer(this.platformId)) {
        this.getScreenSize()
      } else {
        this.innerWidth = 1025
      }
  }
  @HostListener('document:click', ['$event'])
  onDocumentClick (event: MouseEvent): void {
    if (this.languagedropdown) {
      if (!this.document.getElementById('langDropdown').contains(event.target)) {
        this.outSideClick()
      }
    }
    if (this.signindropdown) {
      if (!this.document.getElementById('signindropdown').contains(event.target)) {
        this.outSideClick()
      }
    }
    if (this.showDropdown) {
      if (!this.document.getElementById(this.headerMenuToogle).contains(event.target)) {
        this.showDropdown = false
      }
    }
  }
  ngOnInit () {
    if (this._CookieServiceService.get('Client_Country_Code') === 'IN' && (this.otherDetails?.countryName === 'global' || this.otherDetails?.countryName === 'india')) {
      this.indiaHomepage = 'https://' + indiaDomain
    }
    this.titles = this.contentfulService?.translatedData?.commonList
    this.contentfulService.disableBooknow.subscribe(data => {
      this.bookNowDisable = data
    })
    this.countryCode = this._CookieServiceService.get('Client_Country_Code')
    let preview = false
    this.route.queryParamMap.subscribe(queryParams => {
      preview = Object.keys(queryParams['params']).length > 0 && queryParams['params']?.preview
    })
    if (this.otherDetails?.countryName === 'global') {
      this.searchCountryUrl = 'global'
    } else {
      this.searchCountryUrl = this.otherDetails?.countryUrl
    }
    this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationStart) {
        this.showMobNav = false
        this.mobViewParentNavName = ''
        this.mobViewParentNameGaID = ''
        this.headerMenuToogle = ''
        this.headerToggle = false
        this.ishamburgerVisible = false
        this.disableScroll()
      }
    })
    if (this.contentfulService.checkIndiawebsite()) {
      this.indiaWebsite = true
    }
    this.getHeaderMenuTypes()
    this.getBookingURL()
    this.getLangDetails(preview)
    this.quizLogoCheck()
    this.getUserLoginDetails()
    this.getPrepBookingUrl()
  }

getLangDetails (preview) {
    const websiteInfo = this.routingInitService.getWebsiteInfo()
    if (this.otherDetails && this.otherDetails.countryName && websiteInfo) {
      if (this.otherDetails?.countryName in websiteInfo) {
        this.languageArray = websiteInfo[this.otherDetails?.countryName].LanguageList.map((language) => {
          if (language.enableLanguage === 'Yes' || preview) {
            return language
          }
        }).filter(notUndefined => notUndefined !== undefined)
        if ((preview || websiteInfo[this.otherDetails?.countryName].enableSecondaryLanguages === 'Yes') && this.languageArray.length > 1) {
          this.showLanguageDropdown = true
        }
      }
      this.languageSelector = this.contentfulService.getCurrentLangCode(this.otherDetails?.countryName)
      if (websiteInfo[this.otherDetails?.countryName]?.searchEnabled === 'Yes' && this.languageSelector === 'en-GB') {
        this.isSearchEnabled = true
      } else {
        this.isSearchEnabled = false
      }
    }
    this.languageArray.forEach((language) => {
      if (this.languageSelector === language.code) {
        this.languageSelectorDisplay = language.dropdownCode
      }
    })
    this.contentfulService.languageList.subscribe(data => {
      this.matchedLang = data
    })
  }
  getBookingURL () {
    if (this.countryCode === 'IN' && this.otherDetails?.countryUrl === '') {
      this.bookingURL = 'https://www.ieltsidpindia.com/registration/registration'
    } else {
      this.bookingURL = this.headerData?.bookatestURL?.includes('://') ? this.headerData?.bookatestURL : this.otherDetails?.countryUrl + this.headerData?.bookatestURL
    }
  }

  getPrepBookingUrl () {
    const bookaTestUrl = this.headerData?.bookatestURL?.includes('://') ? this.headerData?.bookatestURL : this.otherDetails?.countryUrl + this.headerData?.bookatestURL
    this.prepBookNow = this.contentfulService.getBookingUrl(bookaTestUrl)
  }

  getHeaderMenuTypes () {
    const hubType = ['IELTS tests', 'About', 'Results', 'IELTS community']
    if (this.headerData?.headerMenu) {
      this.headerData?.headerMenu.map(headerInfo => {
        if (hubType?.includes(headerInfo?.hubpageType)) {
          const menuTitle = { navigationTitle: headerInfo?.navigationLabel, navigationUrl: headerInfo?.navigationUrl, gaID: headerInfo?.gaID, target: headerInfo?.target }
          const ieltsTest = { quickLink: [], nonQuickLink: [], hubpageType: headerInfo?.hubpageType, headerMenu: menuTitle, communityMenuSocialShareTitle: '' }
          headerInfo.childNavigation.forEach(child => {
            if (child?.quickLinksSection && child.quickLinksSection === 'Yes') {
              ieltsTest['quickLink'].push(child)
            } else {
              if (child?.gaID?.toUpperCase() === 'OUR SOCIAL PRESENCE') {
                ieltsTest['communityMenuSocialShareTitle'] = child.navigationLabel
              } else {
                ieltsTest['nonQuickLink'].push(child)
              }
            }
          })
          this.headerMenuValue.push(ieltsTest)
        } else if (headerInfo?.hubpageType === 'Prepare') {
          const menuTitle = { navigationTitle: headerInfo?.navigationLabel, navigationUrl: headerInfo?.navigationUrl, navigationGaid: headerInfo?.gaID, target: headerInfo?.target }
          const prepHubTypeHeaderData = { quickLink: [], masonryData: [], promoCard: [], hubpageType: headerInfo?.hubpageType, headerMenu: menuTitle }
           headerInfo.childNavigation.forEach(child => {
             if (child?.quickLinksSection && child.quickLinksSection === 'Yes') {
               prepHubTypeHeaderData['quickLink'].push(child)
             } else {
               prepHubTypeHeaderData['masonryData'].push(child)
             }
           })
           if (headerInfo?.promoCard) {
             prepHubTypeHeaderData['promoCard'] = headerInfo?.promoCard
          }
          this.headerMenuValue.push(prepHubTypeHeaderData)
        }
      })
    }
  }

  quizLogoCheck () {
    if (this.headerType === 'diagnostic_header' && sessionStorage.getItem('preparationToolStarted')) {
      const countryUrl = sessionStorage.getItem('preparationToolStarted').split('/')
      if (countryUrl.length >= 1 && this.routingInitService.getcountry()?.includes(countryUrl[1])) {
        this.otherDetails['preparationToolStarted'] = '/' + countryUrl[1]
      }
    }
  }

  ngAfterViewInit () {
    this.outSideClick()
  }
  outSideClick () {
    this.languagedropdown = false
    this.signindropdown = false
  }
  closeQuiz () {
      window.scroll({
        top: 0,
        left: 0
      })

     this.contentfulService.quizClose.next(true)

     const prevPageName = this.contentfulService.prevPageName ? this.contentfulService.prevPageName : this.contentfulService.customDimensionValue['pageName']
     const event = new ClickStreamEventData(this.contentfulService.customDimensionValue['pageName'], this.contentfulService.domainName + this.router.url, 'CloseClicked', 'Interaction', 'Prepare', 'Close current page', '\'x\' icon', prevPageName)
     this.clickStream.clickstreamLogging(event)
  }
  langDropDown () {
    this.languagedropdown = !this.languagedropdown
  }

  openPopup () {
    this.opensearch = !this.opensearch
    this.contentfulService.pageTrack('search_icon', 'na', 'na', this.otherDetails['countryName'], this.contentfulService.customDimensionValue['pageName'], this.contentfulService.siteLanguage, 'header', 'na')
  }
  closePopup () {
    this.opensearch = false
  }
  headerTooglee () {
    this.headerToggle = !this.headerToggle
    this.ishamburgerVisible = !this.ishamburgerVisible
    this.mobViewParentNavName = ''
    this.headerMenuToogle = ''
    this.mobViewParentNameGaID = ''
    this.showMobNav = !this.showMobNav
    this.disableScroll()
  }
  menuItem (selectedParent, i) {
    this.mobViewParentNavName = selectedParent?.headerMenu?.navigationTitle
    this.mobViewParentNameGaID = selectedParent?.headerMenu?.gaID
    this.headerMenuToogle = selectedParent.hubpageType + i
    if (this.headerMenuToogle === 'IELTS tests' + i) {
      this.testMenuchildmobView = selectedParent?.nonQuickLink?.length ? selectedParent.nonQuickLink[0].navigationLabel : ''
    }
    this.headerIndex = 'headerL10'
  }

  disableScroll () {
    if (this.headerToggle) {
      this.renderer.addClass(this.document.body, 'overflow-y-hide')
    } else {
      this.renderer.removeClass(this.document.body, 'overflow-y-hide')
    }
  }

  langItem () {
    this.headerMenuToogle = 'lang_item'
    this.mobViewParentNavName = 'Language'
  }

  mobViewLangSwitched (event) {
    this.switchLanguage(event)
  }
  childGaTrack (event) {
    if (event?.urlSlug) {
      event.eventCategory = this.contentfulService.getEventLabel(event.urlSlug) === 'na' ? event.eventCategory : 'book_now'
      event.eventLabel = this.contentfulService.getEventLabel(event.urlSlug) === 'na' ? 'nav_card' : this.contentfulService.getEventLabel(event.urlSlug)
    }
    this.contentfulService.pageTrack(event.eventCategory, event.eventAction?.split(' ').join('_').toLowerCase(), event.eventLabel?.split(' ').join('_').toLowerCase(), this.otherDetails['countryName'], this.contentfulService.customDimensionValue['pageName'], this.contentfulService.siteLanguage, event.eventCategory === 'book_now' ? 'header_ielts_tests' : 'header', 'na')
    if (event?.parentIndex) {
      this.headerClicked(event.parentIndex)
    }
  }
  gaTrackHeader (urlSlug, title, prepFloating?) {
    const eventLabel = this.contentfulService.getEventLabel(urlSlug)
    this.contentfulService.pageTrack(title, 'na', eventLabel?.split(' ').join('_').toLowerCase(), this.otherDetails['countryName'], this.contentfulService.customDimensionValue['pageName'], this.contentfulService.siteLanguage, prepFloating || 'header', 'na')
    const prevPageName = this.contentfulService.prevPageName ? this.contentfulService.prevPageName : this.contentfulService.customDimensionValue['pageName']
    const event = new ClickStreamEventData(this.contentfulService.customDimensionValue['pageName'], urlSlug, 'TestBookClicked', 'Interaction', prepFloating || 'Header', 'Test booking', 'Book now', prevPageName)
    this.clickStream.clickstreamLogging(event)
  }
  headerClicked (index) {
    this.document.querySelector('.child-menu' + index)?.classList?.remove('menu-toggle')
    this.document.querySelector('.child-menu' + index)?.classList?.remove('menu-focused')
  }

  headerL1Clicked (index, label?, child?) {
    if (label) {
      this.headerIndex = 'headerL1' + index
      this.testMenuchildmobView = label
      this.showDropdown = !this.showDropdown
    } else {
      this.headerIndex = 'headerL' + index + child
    }
  }
  showHeaderMenu (index, type) {
    if (this.headerIndex.split('headerL')[1].slice(0, 1) != index) {
     this.headerIndex = 'headerL' + index + '0'
    }
    for (let i = 1; i <= this.headerData?.headerMenu.length; i++) {
      if (this.document.querySelector('.child-menu' + i)?.classList.contains('menu-focused')) {
        this.document.querySelector('.child-menu' + i)?.classList?.remove('menu-focused')
      }
      if (this.document.querySelector('.child-menu' + i)?.classList.contains('menu-toggle')) {
        this.document.querySelector('.child-menu' + i)?.classList?.remove('menu-toggle')
      }
    }
    if (type === 'mouseEnter') {
      this.document.querySelector('.child-menu' + index)?.classList?.add('menu-toggle')
      this.document.getElementById('scroll' + index).scrollTop = 0
    }
    if (type === 'focusIn') this.document.querySelector('.child-menu' + index)?.classList?.add('menu-focused')
  }
  // Function that executed when language switched dropdown is changed.
  switchLanguage (lang): any {
    this.childGaTrack({ eventCategory: 'lang_switch', eventAction: lang.gaID, eventLabel: 'na' })
    const websiteInfo = this.routingInitService.getWebsiteInfo()
    let primaryLanguage = ''
    let secondaryLanguage = []
    let secondaryLanguageList = []
    let urlLang = ''
    // let gaId = ''
    try {
      if (this.otherDetails['countryName'] in websiteInfo) {
        const defaultLanguage = websiteInfo[this.otherDetails['countryName']].defaultLanguage
        primaryLanguage = defaultLanguage.code
        secondaryLanguageList = websiteInfo[this.otherDetails['countryName']].LanguageList
        secondaryLanguage = secondaryLanguageList.map((lang) => {
          return lang.urlCode
        })
      }
    } catch (e) { }
    const secondaryLanguageCode = secondaryLanguageList.map((lang) => {
      return lang.code
    })
    const codeIndex = secondaryLanguageCode.indexOf(lang.code)
    if (codeIndex !== -1) {
      urlLang = secondaryLanguageList[codeIndex].urlCode
      // gaId = secondaryLanguageList[codeIndex].gaID
    }
    this._CookieServiceService.set('currentLang', lang.code)
    this._CookieServiceService.set(this.otherDetails['countryName'] + '_SelectedLang', lang.code)

    const url = (location.pathname + location.search).substring(1)

    const urlArray = url.split('?')
    const urlwithoutQueryStingArray = urlArray[0].split('/')
    const urlwithoutQueryStingArrayLength = urlwithoutQueryStingArray.length
    if (secondaryLanguage?.includes(urlwithoutQueryStingArray[urlwithoutQueryStingArrayLength - 1])) {
      const index = urlwithoutQueryStingArrayLength - 1
      urlwithoutQueryStingArray.splice(index, 1)
    }
    let urlQueryString = ''
    if (urlArray.length > 1) {
      urlQueryString = '?' + urlArray[1]
    }
    const urlwithoutQuerySting = urlwithoutQueryStingArray.join('/')
    // added the below block for to check the article if not available in English, if its true we are showing message in article page stated this article not available in English
    let langTags = []
    this.contentfulService.localArticle.subscribe(data => {
      if (data.length > 0) {
        data.map(tags => {
          return langTags.push(tags.charAt(0).toUpperCase() + tags.slice(1))
        })
        if (!data?.includes('english')) {
          this.checkNonEnglish = true
        }
      } else {
        langTags = []
        this.checkNonEnglish = true
      }
    })
    // added the above block for to check the article if not available in English
    // Blow block is used to check local language article available in current selected language
    if (this.checkNonEnglish && this.matchedLang.length > 0) {
      this.contentfulService.englishOrLocalOnlyArticle(urlwithoutQuerySting, false, true, this.matchedLang, this.otherDetails['countryName'], lang.code)
      this.languagedropdown = true
    // Above block is used to check local language article available in current selected language
    } else if (primaryLanguage === lang.code) {
        window.location.href = urlwithoutQuerySting + urlQueryString
    } else {
        window.location.href = urlwithoutQuerySting + '/' + urlLang + urlQueryString
    }
  }
  // Prepare mobile filter
  flterFunc () {
    this.document.body.classList.add('here_fliter')
  }

  clearLocalStorage (): any {
      this._CookieServiceService.remove('prep_sec_filter')
  }
  onScroll (event: Event, i) {
   if (!this.document.getElementById('scroll' + i).classList.contains('menu-scrolled')) {
    this.document.getElementById('scroll' + i).classList.add('menu-scrolled')
   }
  }

  booknowScroll () {
      const scrollTo = this.document.getElementById('eventList') ? 'eventList' : this.document.getElementById('testCenterDetail') ? 'testCenterDetail' : ''
      this.contentfulService.scrolltoElement(scrollTo)
  }

  getUserLoginDetails () {
    if (this._CookieServiceService.get('LoginDetails')) {
      this.isLoggedInUser = true
      const loginData = this._CookieServiceService.get('LoginDetails') || ''
      let ChannelName = ''
      let loginType = ''
      let redirectionUrl = ''
      loginData.split('&').forEach(param => {
        if (param?.includes('ChannelName')) {
          ChannelName = param.split('=')[1]
        } else if (param?.includes('ChannelType')) {
          loginType = param.split('=')[1]
        }
      })
      if (loginType == 'N') {
        redirectionUrl = '/Access/NodeLoginHome'
      } else if (loginType == 'C') {
          redirectionUrl = '/Access/CandidateDashboard'
      } else if (loginType == 'B') {
          redirectionUrl = '/Access/BranchLoginHome'
      }
       this.loginDetails = new IndiaLoginDetails(
        ChannelName || '',
        loginType || '',
        redirectionUrl || '',
         '/Access/LogOut',
         (ChannelName && ChannelName?.includes('.')) ? ChannelName.split('.')[1].trim()?.charAt(0).toUpperCase() : ChannelName?.trim()?.charAt(0)?.toUpperCase() || ''
      )
    }
    if (!isPlatformServer(this.platformId)) {
      this.isClient = true
    }
  }

  signInDropdown () {
    this.signindropdown = !this.signindropdown
  }

  signIn () {
    if (this.loginDetails) {
      window.location.href = this.loginDetails.logoutUrl
    } else {
      this.loginTriggeredirectly = false
      this.skipPopup()
      this.headerMenuToogle = 'user_item'
      this.mobViewParentNavName = 'Choose your sign in options'
    }
  }

  skipPopup () {
    this.headerMenuToogle = ''
    if (this.loginTriggeredirectly) {
      this.headerTooglee()
    }
  }

  directClick () {
    if (!this.headerMenuToogle) {
      this.headerTooglee()
      this.headerMenuToogle = 'user_item'
      this.mobViewParentNavName = 'Choose your sign in options'
      this.loginTriggeredirectly = true
    }
  }
}
