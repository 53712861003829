export class TeaserContent {
  public title: string
  public shortDescription: string
  longDescription: string
  public callToAction: any
  public componentStyle: string
  public backgroundFill: string
  public mediaCardPosition: string
  public mediaData: any
  public gaId: string
  public featuresPoints?: any
  public productCost?: string
  constructor (
    title: string,
    shortDescription: string,
    longDescription: string,
    callToAction: any,
    componentStyle: string,
    backgroundFill: string,
    mediaCardPosition: string,
    mediaData: any,
    gaId: string,
    featuresPoints?: any,
    productCost?: string
  ) {
    this.title = title || ''
    this.shortDescription = shortDescription || ''
    this.longDescription = longDescription || ''
    this.callToAction = callToAction
    this.componentStyle = componentStyle || ''
    this.backgroundFill = backgroundFill || ''
    this.mediaCardPosition = mediaCardPosition || ''
    this.mediaData = mediaData
    this.gaId = gaId || ''
    this.featuresPoints = featuresPoints
    this.productCost = productCost
  }
}
